import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Slider,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import {MuiPaper} from "../styles/question";
import _ from "lodash";
import * as React from "react";
import {generateID} from "../../../../../../../../utils/utils";
import axios from "../../../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../../api/status.utils";
import {useLoading} from "../../../../../../../providers/LoadingProvider";
import {useSnackbar} from "notistack";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import {Topics} from "./Topics";

export const TopicExtraction = ({
                                  question,
                                  variables,
                                  parentCandidates,
                                  dataRegion,
                                  collection,
                                  weight,
                                  handleQuestionChange
                                }) => {

  const [maxTopics, setMaxTopics] = React.useState(5);
  const [maxClassificationTopics, setMaxClassificationTopics] = React.useState(1);
  const [parentPolicy, setParentPolicy] = React.useState(null);
  const [enableSegmentClassification, setEnableSegmentClassification] = React.useState(false);
  const [segmentationCandidates, setSegmentationCandidates] = React.useState([]);
  const [selectedClassificationSegment, setSelectedClassificationSegment] = React.useState(null);
  const [dataset, setDataset] = React.useState('');
  const {enqueueSnackbar} = useSnackbar();
  const {t, lng} = useTranslation();
  const {
    setIsLoading,
    setIsTopicInferenceLoading,
    setIsClassificationLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();

  const initialize = () => {
    if (question.topics.length === 0) {
      setMaxTopics(5)
      question.maxTopics = 5
    } else {
      setMaxTopics(question.topics.length)
    }
    setMaxClassificationTopics((question.maxClassificationTopics || 1));
    setDataset(collection);
    setSegmentationCandidates(variables.filter(d => d.category === 'categorical'));
    setSelectedClassificationSegment(question.selectedClassificationSegment);
    setEnableSegmentClassification(question.enabledSegmentClassification);
    setParentPolicy(question.parentPolicy || null);
  }

  React.useEffect(() => {
    initialize();
  }, [question]);

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const successToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'success'})
  }


  async function updateTopicIcons(topics) {
    let localTopics = [...topics];
    let iconRepresentationResp = await axios.post(apiPaths.topic_icons, {topics: localTopics}, status200);
    if (iconRepresentationResp && iconRepresentationResp.status && iconRepresentationResp.data && iconRepresentationResp.data.topics) {
      return iconRepresentationResp.data.topics.sort((a, b) => (a.representativity && b.representativity) ? b.representativity - a.representativity : a.topic.localeCompare(b.topic));
    }
    return topics;
  }

  const submitInferenceRequest = async (dataset, answerVar) => {
    const requestId = generateID();
    const payload = {
      collection_name: dataset,
      answers_column: answerVar,
      max_topics: maxTopics,
      weight: weight,
      target_lang: lng,
      requestId: requestId,
      region: dataRegion || "US"
    }
    try {
      await axios.post(apiPaths.infer_topics_async, payload, status200);
    } catch (error) {
      console.log(error)
      errorToast(`Error inferring topics for Question: ${question.name}`);
      return null;
    }
    return requestId;
  };

  const handleInferTopics = async (e) => {
    if (e.target.id === 'infer-topics-btn') {
      e.preventDefault()
    }
    if (!dataset || !question.answerVar) return
    setShowProgressBar(true);
    setIsTopicInferenceLoading(true);
    setTitle("Extracting the relevant topics in your data...");
    setProgress(0);
    const requestId = await submitInferenceRequest(dataset, question.answerVar)
    const topicsData = await pollTaskProgress(requestId);
    console.log('topicsData', topicsData);
    let inferredTopics = topicsData?.topics
    if (inferredTopics) {
      inferredTopics = inferredTopics.sort((a, b) => (a.representativity && b.representativity) ? b.representativity - a.representativity : a.topic.localeCompare(b.topic));
      let localQuestion = {...question}
      localQuestion.topics = inferredTopics;
      localQuestion.classified = false;
      handleQuestionChange(localQuestion);
      successToast("Main topics extracted...");
    }
    // await handleSaveScheme();
    setShowProgressBar(false);
    setProgressMessage('');
    setProgress(0);
    setIsTopicInferenceLoading(false);
  }


  const submitCustomClassification = async () => {
    const requestId = generateID();
    let sourceClassificationVar = !!question.parentPolicy ? (question.parentPolicy.answerVar + "_label") : null;
    if (enableSegmentClassification) {
      sourceClassificationVar = question.selectedClassificationSegment.propName;
    }
    const payload = {
      collection_name: collection,
      answers_column: question.answerVar,
      sourceClassificationVariable: sourceClassificationVar,
      parentTopics: !!question.parentPolicy ? question.parentPolicy.topics : null,
      topics: question.topics,
      model: "llm",
      lang: lng,
      requestId: requestId,
      saveClassification: true,
      classification_type: "topics",
      max_topics: maxClassificationTopics,
      region: dataRegion
    };

    try {
      await axios.post(!!sourceClassificationVar ? apiPaths.async_compute_partial_classification : apiPaths.compute_async_classification, payload, status200);
    } catch (error) {
      console.error(error);
      errorToast(`Error computing classification for Question: ${question.name}`);
    }
    return requestId
  };

  const handleInitializePartialClassification = async (policyClassify) => {
    let requestId = generateID();
    let sourceClassificationVar = policyClassify.parentPolicy?.answerVar + "_label";
    if (enableSegmentClassification) {
      sourceClassificationVar = policyClassify.selectedClassificationSegment.propName;
    }
    if (!sourceClassificationVar) {
      errorToast("Error initializing classification. Please select a segmentation variable or a parent policy.");
      return requestId;
    }
    const payload = {
      collection_name: collection,
      answers_column: policyClassify.answerVar,
      topics: policyClassify.parentPolicy?.topics || [],
      requestId: requestId,
      saveClassification: true,
      classification_type: "topics",
      sourceClassificationVariable: sourceClassificationVar,
      lang: lng,
      max_topics: maxClassificationTopics,
      region: dataRegion
    }

    try {
      const response = await axios.post(apiPaths.initialize_partial_classification, payload, status200);
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData) {
          policyClassify.topics = await updateTopicIcons(responseData.updatedTopics);
          handleQuestionChange(policyClassify);
        }
      }
    } catch (error) {
      console.error(error);
      errorToast(`Error computing classification for Question: ${question.name}`);
    }
    return requestId
  }

  const handleClassifyWithTopics = async () => {
    setShowProgressBar(true);
    setProgress(0);
    setIsClassificationLoading(true);
    setTitle("Categorizing your data with topics...");
    let requestID = await submitCustomClassification();
    if (requestID) {
      const representativityData = await pollTaskProgress(requestID)
      const updatedTopics = representativityData?.updatedTopics;
      if (updatedTopics) {
        handleQuestionChange({
          ...question,
          topics: updatedTopics,
          classified: true
        });
        successToast("Question successfully classified");
      } else {
        errorToast(`Failed to classify '${question.name}'`)
      }
    }
    setShowProgressBar(false);
    setProgressMessage('');
    setIsClassificationLoading(false);
    setProgress(0);
  }

  const pollTaskProgress = async (requestId) => {
    let countFails = 0
    while (countFails < 10) {
      try {
        const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
        const response_body = progress_response.data;

        if (!response_body || !response_body.data) {
          await new Promise((resolve) => setTimeout(resolve, 1200));
          continue;
        }

        const {message, status, progress, additionalData} = response_body.data;

        if (status === 'failed' || status === 'error') {
          return null;

        } else if (status === 'success' && additionalData) {
          return additionalData;
        }

        setProgressMessage(`${message}`);
        let norm_progress = Math.min(progress, 100)
        setProgress(norm_progress);

      } catch (e) {
        console.error(e);
        countFails++;
      }
      await new Promise((resolve) => setTimeout(resolve, 1200));
    }
    return null;
  };

  return (
    <Grid container spacing={2}
          style={{display: 'flex', flexDirection: 'column'}}
          id="container-topic-inference">  {/* Main Container */}
      <Grid item xs={12}>
        <MuiPaper>
          <Grid container spacing={1}>
            <Grid item style={{display: 'flex', alignItems: 'center'}}>
              <Typography
                style={{fontFamily: "Montserrat", marginRight: 8}}>
                Enable segments classification
              </Typography>
              <Switch
                checked={!!enableSegmentClassification}
                onChange={() => {
                  let updatedPolicy = _.clone(question);
                  updatedPolicy.enabledSegmentClassification = !enableSegmentClassification
                  setEnableSegmentClassification(prevState => {
                    return !prevState
                  });
                  updatedPolicy.parentPolicy = null;
                  updatedPolicy.selectedClassificationSegment = null;

                  updatedPolicy.topics = [];
                  setParentPolicy(null);
                  handleQuestionChange(updatedPolicy);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                {enableSegmentClassification ? (
                  <TextField
                    select
                    label={(t('classification_segment') || 'Classification Segment')}
                    value={selectedClassificationSegment?.propName || ""}
                    placeholder={(t('classification_segment') || 'Classification Segment')}
                    onChange={async (e) => {
                      const updatedPolicy = _.clone(question);
                      if (e.target.value) {
                        let selectedVariable = variables.find(v => v.propName === e.target.value);
                        updatedPolicy.selectedClassificationSegment = selectedVariable;
                        setSelectedClassificationSegment(selectedVariable);
                        updatedPolicy.parentPolicy = null;
                        updatedPolicy.topics = [];
                        setParentPolicy(null);
                        setIsLoading(true);
                        await handleInitializePartialClassification(updatedPolicy);
                        setIsLoading(false);
                      } else {
                        updatedPolicy.parentPolicy = null;
                        updatedPolicy.selectedClassificationSegment = null;
                        updatedPolicy.enabledSegmentClassification = false;
                        setEnableSegmentClassification(false);
                        setSelectedClassificationSegment(null);
                        updatedPolicy.topics = [];
                        setParentPolicy(null);
                        handleQuestionChange(updatedPolicy);
                      }
                    }}
                    variant="outlined"
                    id="parent-answer-selection">
                    <MenuItem key="none" value={""}>
                      None
                    </MenuItem>
                    {segmentationCandidates.map((segmentCandidate) => (
                      <MenuItem key={segmentCandidate.propName}
                                value={segmentCandidate.propName}>
                        {segmentCandidate.label || segmentCandidate.propName}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    select
                    label={(t('parent_question') || 'Parent question')}
                    value={parentPolicy?.name || ""}
                    placeholder={(t('parent_question') || 'Parent question')}
                    onChange={async (e) => {
                      if (e.target.value) {
                        const localParentPolicy = parentCandidates.find(p => p.name === e.target.value);
                        question.parentPolicy = _.cloneDeep(localParentPolicy);
                        setParentPolicy(localParentPolicy);
                        setIsLoading(true);
                        await handleInitializePartialClassification(question);

                        setIsLoading(false);
                      } else {
                        question.parentPolicy = null;
                        question.topics = [];
                        setParentPolicy(null);
                        handleQuestionChange(question);
                      }
                    }}
                    variant="outlined"
                    id="parent-answer-selection">
                    <MenuItem key="none" value={""}>
                      None
                    </MenuItem>
                    {parentCandidates.map((parentPolicy) => (
                      <MenuItem key={parentPolicy.id}
                                value={parentPolicy.name}>
                        {parentPolicy.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </MuiPaper>
      </Grid>
      <Grid item xs={12} id="container-topic-autocomplete"
            style={{width: "100%", flex: 1}}>
        <Topics
          question={question}
          handleQuestionChange={handleQuestionChange}
          rootIsConditioned={question.parentPolicy !== null || (!!question.selectedClassificationSegment&&!!question.selectedClassificationSegment?.propName)}
          dataRegion={dataRegion}
          collection={collection}
          weightVariable={weight}
        />
      </Grid>

      <Grid container item xs={12} spacing={3}
            id="container-topic-controls">
        {/* Textfield for Amount of Topics */}
        <Grid item xs={12} style={{marginTop: 10}}>
          <FormControl variant="outlined">
            <Typography>{t('amount_topics')}:</Typography>
            <Slider
              step={1}
              min={3}
              max={20}
              marks
              shiftStep={1}
              value={maxTopics}
              getAriaValueText={(value) => `${value}`}
              valueLabelDisplay="auto"
              aria-label={t('amount_topics')}
              onChange={(evt, value) => {
                setMaxTopics(value)
              }}
              color='primary'
              sx={{
                width: '245px',
                mt: '30px'
              }}
            />
          </FormControl>
        </Grid>

        {/* Buttons Grid */}
        <Grid container item xs={12} spacing={1}>
          {/* Infer Topics Button */}
          <Grid item xs={12}>
            <FormControl variant="outlined">
              <Button
                color='secondary'
                variant="contained"
                id="infer-topics-btn"
                onClick={(e) => handleInferTopics(e)}>
                {t('infer_topics')}
              </Button>
            </FormControl>
          </Grid>

          {/* Manage Topics Button (ModalCategoryEdit) */}
          {/*<Grid item xs={6}>*/}
          {/*  <div>*/}
          {/*    <Button onClick={handleTopicMngOpen} variant="contained"*/}
          {/*            style={{width: '100%'}}>{t('edit_topics')}</Button>*/}
          {/*    {(open) && (<ModalCategoryEdit*/}
          {/*      topics={topics}*/}
          {/*      isCategoriesCustom={false}*/}
          {/*      onCategoriesChange={(topics) => {*/}
          {/*        let localTopics = JSON.parse(JSON.stringify(topics));*/}
          {/*        question.topics = localTopics;*/}
          {/*        question.classified = false;*/}
          {/*        computeTopicCoverages(localTopics).then(() => {*/}
          {/*          console.log("Representativity computed")*/}
          {/*        });*/}
          {/*      }}*/}
          {/*      inferSubTopics={handleInferSubTopics}*/}
          {/*      handleClose={handleTopicMngClose}*/}
          {/*      policyName={question.name}*/}
          {/*    />)}*/}
          {/*  </div>*/}
          {/*</Grid>*/}
        </Grid>

        <Grid container item xs={12}>
          <MuiPaper sx={{padding: '10px', width: '100%'}}>
            <Typography>{t('answer_classification')}:</Typography>
            {(!parentPolicy) && (
              <Grid container xs={12} item flexDirection={"column"}
                    alignItems={'flex-start'} spacing={3}>
                <Grid item>
                  <Typography id='max-classification-topics'
                              variant="body1">
                    {t('Max. topics per sample') || "Max. topics per sample"}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Slider
                    value={maxClassificationTopics}
                    min={1}
                    max={5}
                    marks
                    step={1}
                    sx={{
                      width: '245px',
                    }}
                    onChange={(event, value) => {
                      setMaxClassificationTopics(value);
                      question.maxClassificationTopics = value;
                      handleQuestionChange(question);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="max-classification-topics"
                  />
                </Grid>

              </Grid>
            )}
            <Grid container item alignItems={'flex-start'}
                  sx={{mt: '10px'}}>
              <FormControl>
                <Button
                  color='secondary'
                  variant="contained"
                  onClick={async () => {
                    await handleClassifyWithTopics();
                  }}>
                  {t('classify')}
                </Button>
              </FormControl>
            </Grid>
          </MuiPaper>
        </Grid>
      </Grid>
    </Grid>
  )
}
