import {Button, Grid, Switch, Typography} from "@mui/material";
import CustomAISegment from "./CustomAISegment";
import * as React from "react";
import {useEffect} from "react";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import _ from "lodash";
import {generateID} from "../../../../../../../../utils/utils";
import axios from "../../../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../../api/status.utils";
import {useLoading} from "../../../../../../../providers/LoadingProvider";
import {useSnackbar} from "notistack";
import {generateRandomString} from "../../../../../../../../utils/text";

export const AISegments = ({
                             question,
                             collection,
                             handleQuestionChange,
                             dataRegion
                           }) => {

  const [customSegmentDefinitions, setCustomSegmentDefinitions] = React.useState([]);

  const [enableCustomSegmentations, setEnableCustomSegmentations] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();

  const {
    setIsClassificationLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();

  const {t, lng} = useTranslation();

  const initialize = () => {
    if (question.customSegmentations && question.customSegmentations?.enabled) {
      setEnableCustomSegmentations(true);
      setCustomSegmentDefinitions(question.customSegmentations.segmentations);
    } else {
      question.customSegmentations = {
        enabled: false,
        segmentations: []
      };
    }
  }

  const getPredefinedCustomCategories = () => {
    const intentCategories = {
      "english": [
        {
          "topic": "Feedback",
          "description": "This category encompasses all responses aimed at providing opinions or evaluations about a product, service, or experience. It includes both positive and negative feedback, offering insights into what respondents like or dislike."
        },
        {
          "topic": "Suggestions",
          "description": "Responses that propose improvements, new ideas, or constructive criticism fall under this category. These insights are valuable for innovation and addressing areas that need enhancement."
        },
        {
          "topic": "Inquiries",
          "description": "This includes questions or requests for more information about a product, service, policy, or procedure. Responses in this category often indicate areas where additional clarity or resources are needed."
        },
        {
          "topic": "Experiences",
          "description": "Captures personal stories or accounts related to the use of a product or service, or participation in an event. This category is essential for understanding the respondent's journey and emotional connection."
        },
        {
          "topic": "Problems or Issues",
          "description": "Identifies responses highlighting specific problems, issues, or challenges encountered with a product, service, or process. This category is crucial for immediate attention and rectification."
        },
        {
          "topic": "Praise",
          "description": "Consists of responses that specifically express satisfaction, commendation, or positive reactions to an aspect of a product or service. It’s important for recognizing strengths and areas of success."
        },
        {
          "topic": "Intent to Use",
          "description": "This category captures the respondent's intentions regarding the future use of a product or service, including potential purchase, continued use, or discontinuation. It provides insights into customer loyalty and potential sales."
        },
        {
          "topic": "Comparisons",
          "description": "Includes responses that compare a product, service, or experience with another, offering insights into competitive positioning and respondent preferences."
        },
        {
          "topic": "Requirements or Needs",
          "description": "Responses that articulate specific needs, desires, or expectations from a product or service. This category helps in understanding the gap between what is offered and what is expected."
        },
        {
          "topic": "Unsolicited Ideas",
          "description": "Captures innovative or creative ideas that do not necessarily fit into a direct suggestion for improvement but offer potential new directions or opportunities for exploration."
        },
        {
          "topic": "Other",
          "description": "This category captures responses that do not fall under any of the other categories."
        }
      ],
      "spanish": [
        {
          "topic": "Retroalimentación",
          "description": "Esta categoría abarca todas las respuestas destinadas a proporcionar opiniones o evaluaciones sobre un producto, servicio o experiencia. Incluye tanto retroalimentación positiva como negativa, ofreciendo perspectivas sobre lo que a los encuestados les gusta o disgusta."
        },
        {
          "topic": "Sugerencias",
          "description": "Las respuestas que proponen mejoras, nuevas ideas o críticas constructivas entran en esta categoría. Estos conocimientos son valiosos para la innovación y para abordar áreas que necesitan mejora."
        },
        {
          "topic": "Consultas",
          "description": "Incluye preguntas o solicitudes de más información sobre un producto, servicio, política o procedimiento. Las respuestas en esta categoría a menudo indican áreas donde se necesita más claridad o recursos."
        },
        {
          "topic": "Experiencias",
          "description": "Captura historias personales o relatos relacionados con el uso de un producto o servicio, o la participación en un evento. Esta categoría es esencial para comprender el viaje y la conexión emocional del encuestado."
        },
        {
          "topic": "Problemas o Incidencias",
          "description": "Identifica respuestas que resaltan problemas, incidencias o desafíos específicos encontrados con un producto, servicio o proceso. Esta categoría es crucial para la atención inmediata y la corrección."
        },
        {
          "topic": "Elogios",
          "description": "Consiste en respuestas que expresan específicamente satisfacción, reconocimiento o reacciones positivas hacia un aspecto de un producto o servicio. Es importante para reconocer fortalezas y áreas de éxito."
        },
        {
          "topic": "Intención de Uso",
          "description": "Esta categoría captura las intenciones del encuestado respecto al uso futuro de un producto o servicio, incluyendo la posible compra, uso continuado o discontinuación. Proporciona perspectivas sobre la lealtad del cliente y ventas potenciales."
        },
        {
          "topic": "Comparaciones",
          "description": "Incluye respuestas que comparan un producto, servicio o experiencia con otro, ofreciendo perspectivas sobre la posición competitiva y las preferencias del encuestado."
        },
        {
          "topic": "Requerimientos o Necesidades",
          "description": "Respuestas que articulan necesidades, deseos o expectativas específicas de un producto o servicio. Esta categoría ayuda a entender la brecha entre lo que se ofrece y lo que se espera."
        },
        {
          "topic": "Ideas No Solicitadas",
          "description": "Captura ideas innovadoras o creativas que no necesariamente encajan en una sugerencia directa para mejora pero ofrecen potenciales nuevas direcciones u oportunidades para exploración."
        },
        {
          "topic": "Otro",
          "description": "Esta categoría captura respuestas que no se encuentran en ninguna de las categorías anteriores."
        }
      ],
      "arabic": [
        {
          "topic": "التغذية الراجعة",
          "description": "تشمل هذه الفئة كل الردود الهادفة إلى تقديم آراء أو تقييمات حول منتج، خدمة، أو تجربة. تتضمن كلًا من التغذية الراجعة الإيجابية والسلبية، مقدمةً رؤى حول ما يعجب أو لا يعجب المستجيبين."
        },
        {
          "topic": "الاقتراحات",
          "description": "الردود التي تقترح تحسينات، أفكار جديدة، أو نقد بناء تقع ضمن هذه الفئة. هذه الرؤى ثمينة للابتكار ومعالجة المجالات التي تحتاج إلى تحسين."
        },
        {
          "topic": "الاستفسارات",
          "description": "يشمل ذلك الأسئلة أو طلبات للحصول على مزيد من المعلومات حول منتج، خدمة، سياسة، أو إجراء. الردود في هذه الفئة غالبًا ما تشير إلى المجالات التي تحتاج إلى مزيد من الوضوح أو الموارد."
        },
        {
          "topic": "التجارب",
          "description": "تلتقط القصص الشخصية أو الحسابات المتعلقة باستخدام منتج أو خدمة، أو المشاركة في حدث. هذه الفئة ضرورية لفهم رحلة المستجيب والتواصل العاطفي."
        },
        {
          "topic": "المشاكل أو القضايا",
          "description": "تحدد الردود التي تسلط الضوء على مشاكل محددة، قضايا، أو تحديات تواجه مع منتج، خدمة، أو عملية. هذه الفئة حاسمة للانتباه الفوري والتصحيح."
        },
        {
          "topic": "الثناء",
          "description": "تتألف من الردود التي تعبر عن الرضا، الإشادة، أو ردود الفعل الإيجابية تجاه جانب معين من منتج أو خدمة. هذا مهم للاعتراف بالنقاط القوية ومجالات النجاح."
        },
        {
          "topic": "نية الاستخدام",
          "description": "تلتقط هذه الفئة نوايا المستجيب بخصوص استخدام منتج أو خدمة في المستقبل، بما في ذلك الشراء المحتمل، الاستخدام المستمر، أو التوقف عن الاستخدام. تقدم رؤى حول ولاء العملاء والمبيعات المحتملة."
        },
        {
          "topic": "المقارنات",
          "description": "تتضمن الردود التي تقارن منتج، خدمة، أو تجربة بأخرى، مقدمةً رؤى حول الموقع التنافسي وتفضيلات المستجيب."
        },
        {
          "topic": "المتطلبات أو الاحتياجات",
          "description": "ردود تصف الاحتياجات، الرغبات، أو التوقعات الخاصة من منتج أو خدمة. تساعد هذه الفئة في فهم الفجوة بين ما يُقدم وما يُتوقع."
        },
        {
          "topic": "الأفكار غير المطلوبة",
          "description": "تلتقط الأفكار الابتكارية أو الإبداعية التي لا تندرج بالضرورة ضمن اقتراح مباشر للتحسين ولكنها تقدم اتجاهات جديدة محتملة أو فرص للاستكشاف."
        },
        {
          "topic": "آخر",
          "description": "هذا الفئة تلتقط الردود التي لا تندرج تحت أي من الفئات الأخرى"
        }
      ]

    }
    return intentCategories[lng]
  }

  const switchEnableCustomCategories = () => {
    const enabled = !enableCustomSegmentations;
    let predefinedCustomCategories = enabled ? getPredefinedCustomCategories() : [];
    let localPolicy = _.cloneDeep(question);
    localPolicy.customSegmentations = {
      enabled: enabled,
      segmentations: enabled ? [
        {
          categories: predefinedCustomCategories,
          label: "Intend of the answer",
          classificationVariable: `${question.answerVar}_custom_${generateRandomString(10)}_label`,
          classificationPerformed: false
        }
      ] : [],
    }
    setCustomSegmentDefinitions(predefinedCustomCategories);
    setEnableCustomSegmentations(enabled);
    handleQuestionChange(localPolicy);
  }


  const addCustomSegmentation = () => {
    let placeHolderCategories = [];
    for (let i = 0; i < 3; i++) {
      placeHolderCategories.push({
        topic: `Custom category ${i + 1}`,
        description: `Custom category ${i + 1} description`,
        representativity: 0
      })
    }
    question.customSegmentations.segmentations.push({
      categories: placeHolderCategories,
      label: "Custom segmentation",
      classificationVariable: `${question.answerVar}_custom_${generateRandomString(10)}_label`,
      classificationPerformed: false
    });
    setCustomSegmentDefinitions(question.customSegmentations.segmentations);
    handleQuestionChange(question);
  }

  const handleClassifyWithCustomCategories = async (classificationVariable) => {
    setShowProgressBar(true);
    setProgress(0);
    setIsClassificationLoading(true);
    setTitle("Segmenting your data with custom categories...");
    const segment = question.customSegmentations?.segmentations?.find(segment => segment.classificationVariable === classificationVariable);
    const categories = segment?.categories
    let requestID = await submitCustomCategoryClassification(categories, classificationVariable);
    if (requestID) {
      const representativityData = await pollTaskProgress(requestID)
      const updatedCategories = representativityData?.updatedTopics
      if (updatedCategories) {
        segment.categories = updatedCategories
        segment.classificationPerformed = true;
        setCustomSegmentDefinitions(question.customSegmentations.segmentations);
        successToast("Custom category successfully classified");
        handleQuestionChange(question);
        // await handleSaveScheme();
      } else {
        errorToast(`Failed to classify custom category'`)
      }

    }
    setShowProgressBar(false);
    setProgress(0);
    setProgressMessage('');
    setIsClassificationLoading(false);
  }


  const submitCustomCategoryClassification = async (categories, classification_variable) => {
    const requestId = generateID();
    const payload = {
      collection_name: collection,
      answers_column: question.answerVar,
      topics: categories,
      model: "llm",
      requestId: requestId,
      saveClassification: true,
      classification_type: "generic",
      classificationVariable: classification_variable,
      region: dataRegion
    };

    try {
      await axios.post(apiPaths.compute_async_classification, payload, status200);
    } catch (error) {
      console.error(error);
      errorToast(`Error computing classification for Question: ${question.name}`);
    }
    return requestId
  }


  const pollTaskProgress = async (requestId) => {
    let countFails = 0
    while (countFails < 10) {
      try {
        const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
        const response_body = progress_response.data;

        if (!response_body || !response_body.data) {
          await new Promise((resolve) => setTimeout(resolve, 1200));
          continue;
        }

        const {message, status, progress, additionalData} = response_body.data;

        if (status === 'failed' || status === 'error') {
          return null;

        } else if (status === 'success' && additionalData) {
          question.customSegmentations.classificationPerformed = true;
          return additionalData;
        }

        setProgressMessage(`${message}`);
        let norm_progress = Math.min(progress, 100)
        setProgress(norm_progress);

      } catch (e) {
        console.error(e);
        countFails++;
      }
      await new Promise((resolve) => setTimeout(resolve, 1200));
    }
    return null;
  };

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const successToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'success'})
  }

  useEffect(() => {
    initialize();
  }, [question]);

  useEffect(() => {
    if (enableCustomSegmentations) {
      const predefinedCustomCategories = getPredefinedCustomCategories();
      if (!question.customSegmentations?.segmentations?.length) {
        question.customSegmentations = {
          enabled: true,
          segmentations: [
            {
              categories: predefinedCustomCategories,
              label: "Intend of the answer",
              classificationVariable: `${question.answerVar}_custom_${generateRandomString(10)}_label`,
              classificationPerformed: false
            }
          ],
        };
        setCustomSegmentDefinitions(question.customSegmentations.segmentations);
        handleQuestionChange(question);
      }

    }
  }, [lng])

  return (
    <Grid container spacing={2}
          style={{display: 'flex', flexDirection: 'column'}}
          id="container-category-section">
      <Grid container item flexDirection="row" alignItems="center"
            spacing={1}>
        <Grid item>
          <Switch
            checked={enableCustomSegmentations}
            onChange={() => switchEnableCustomCategories()}
            color="primary"
            aria-labelledby={"category-switch-label"}
          />
        </Grid>
        <Grid item>
          <Typography id={"category-switch-label"} variant="body2">
            Define custom segments
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={2} direction={'column'}>
        {
          enableCustomSegmentations ? (
            customSegmentDefinitions.map((segment, index) => {
              return (
                <CustomAISegment
                  key={`custom-segmentation-cmp-${index}`}
                  segment={segment}
                  segmentIndex={index}
                  handleDeleteSegment={(deleteIndex) => {
                    const filteredSegments = customSegmentDefinitions.filter((segment, i) => i !== deleteIndex);
                    setCustomSegmentDefinitions(filteredSegments);
                    if (filteredSegments.length === 0) {
                      setEnableCustomSegmentations(false);
                      question.customSegmentations.enabled = false;
                    }
                    question.customSegmentations.segmentations = filteredSegments;
                    handleQuestionChange(question);
                  }}
                  handleUpdateSegment={(newSegment, index) => {
                    setCustomSegmentDefinitions(customSegmentDefinitions.map((segment, i) => i === index ? newSegment : segment));
                    question.customSegmentations.segmentations = customSegmentDefinitions.map((segment, i) => i === index ? newSegment : segment);
                    handleQuestionChange(question);
                  }}
                  handleClassification={(clasificationVariable) => handleClassifyWithCustomCategories(clasificationVariable)}
                />
              )
            })
          ) : null
        }
        {(enableCustomSegmentations) && (
          <Grid item>
            <Button variant="contained" onClick={addCustomSegmentation}>Add
              segment</Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
