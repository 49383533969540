// React and External Libraries
import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {useQueryClient} from 'react-query';

// Custom Hooks and Contexts
import {useAuth} from 'components/providers/AuthProvider';
import useOrganization from 'api/hooks/useOrganization';

// Assets
import logo_static from 'assets/img/logo.png';
import {MuiDivContainer, MuiImage, orgName} from './styles/orgInfo';
import {MuiTypography} from "./styles/navigationMenu";

const OrgInfo = () => {
  const [display, setDisplay] = React.useState(false);
  const [viewOrgs, setView] = React.useState(true);
  const [logo, setLogo] = React.useState('');
  // const [name, setName] = React.useState('CrowdVoice');

  const { user } = useAuth();
  const location = useLocation();
  const queryClient = useQueryClient();
  const Id =
    location.pathname.split('/')[2] === 'workspace' ? user.org_id : location.pathname.split('/')[2];
  const { isFetching, isError } = useOrganization(Id);
  const org = queryClient.getQueryData(['organization', Id]);

  React.useEffect(() => {
    if (location.pathname.split('/')[1] === 'orgs' && location.pathname.split('/')[2]) {
      if (location.pathname.split('/')[2] !== 'workspace') {
        setLogo(org?.attrs.logo_url);
        setDisplay(true);
        setView(false);
      } else {
        setDisplay(true);
        setLogo(user.org.attrs.logo_url);
        setView(false);
      }
    } else {
      setDisplay(true);
      setView(true)
    }
  }, [location.pathname, org, user]);

  return (
    <>
      {display && (
        <MuiDivContainer>
          {isFetching || isError || viewOrgs ? (
            <>
              <MuiImage src={logo_static} alt={'logo'}/>
              <MuiTypography variant='subtitle1' color={'white'} sx={{
                width:'120px'
              }}>CROWD-VOICE</MuiTypography>
            </>

          ) : (
            <>
              <MuiImage src={logo} alt={'logo'}/>
            </>
          )}
        </MuiDivContainer>
      )}
    </>
  );
};

export default OrgInfo;
