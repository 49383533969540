import {
  Grid, Typography, Skeleton,
  Link, TextField, styled
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const MuiGrid = styled(Grid)(({ theme,data }) => ({
  '&$unselected': {
    color: theme.palette.text.disabled,

    '& $progressbar div': {
      backgroundColor: theme.palette.text.disabled
    },
  },
  cursor: 'pointer',
  flexWrap: 'nowrap',

  '&:hover $progressbar div': {
    backgroundColor: theme.palette.secondary.dark
  },
  marginTop: '5px !important',
  ...(data.name === '__rest__' && {
    cursor: 'default'
  }),

}));

export const MuiTypographyLabel = styled(Typography)(({ theme }) => ({
  // marginRight: theme.spacing(2),
  color:'#424242',
  fontFamily:'Montserrat',
  fontSize:'14px',
  fontWeight:'normal'
}));

export const MuiGridProgressBar = styled(Grid)(({ theme,width }) => ({
  height: theme.spacing(0.5),
  margin: theme.spacing(0.5, 0, 1, 0),
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.action.disabledBackground,
  width:width,
  // animation: 'transitionToFillProgress 1s linear forwards',
  // '@keyframes transitionToFillProgress': {
  //   from: {
  //     width: '0',
  //   },
  //   to: {
  //     width: `${width}%`,
  //   },
  // },
}));

export const MuiGridSelectBar = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(0),
  paddingRight: theme.spacing(1),

  '& .MuiTypography-caption': {
    color: theme.palette.text.secondary
  },

  '& .MuiButton-label': {
    ...theme.typography.caption
  }
}));

export const MuiGridCategoryWrapper = styled(Grid)(({ theme }) => ({
  maxHeight: theme.spacing(40),
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(1, 1, 1, 0)
}));

export const MuiSkeleton = styled(Skeleton)(({ theme }) => ({
  height: theme.spacing(1),
  width: '100%',
  margin: theme.spacing(0.5, 0, 1, 0)
}));

export const MuiTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1.5),
  marginLeft:'5px',
  fontSize: '14px',
  fontFamily:'Montserrat',
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius:'22px',
    height: '42px',
    padding:'5px',
    fontSize: '14px',
    fontFamily:'Montserrat'
  },
  '& .MuiInputBase-root:hover': {
    outline: 'none',
    height: '42px',
    paddingRight: '5px',
    border: `2px solid ${theme.palette.primary.main} !important`,
    borderRadius:'22px'
  },
  '& input': {
    fontSize: '14px',
    fontFamily:'Montserrat',
    padding:'5px'
  }
}));

export const MuiGridElement = styled(Grid)(({ theme }) => ({
  '&$unselected': {
    color: theme.palette.text.disabled,

    '& $progressbar div': {
      backgroundColor: theme.palette.text.disabled
    },
  },

  '&$rest $progressbar div': {
    backgroundColor: theme.palette.text.disabled
  }
}));

export const MuiDivRoot  = styled("div")(({ theme }) => ({
  ...theme.typography.body2
}));

export const MuiLink  = styled(Link)(({ theme }) => ({
  ...theme.typography.caption,
  cursor: 'pointer',
  fontFamily:'Montserrat',
  fontSize: 12,
  fontWeight: '700',
  color:'primary',
  '& + hr': {
    margin: theme.spacing(0, 1)
  }
}));

export const MuiInfoOutlinedIcon = styled(InfoOutlinedIcon)(({theme}) => ({
  marginTop: theme.spacing(0),
}));

export const selectedCategoriesTitleStyles = {
  color:'#424242',
  marginLeft:'16px',
  marginTop:'-10px',
  fontSize:'12px',
  fontFamily:'Montserrat',
  fontWeight:'normal'

};
