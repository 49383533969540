import * as React from 'react';
import {Grid, Paper} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

import {Outlet} from 'react-router-dom';

import logo from '../../../assets/img/CrowdViewImgLogin.png';
import themeCrowdView from 'theme/crowdView';
import {MuiGridRoot} from './styles/login';

const LoginLayout = () => {

  return (
    <ThemeProvider theme={themeCrowdView}>
      <MuiGridRoot container component='main'>
        <Grid item xs={false} sm={false} md={8} sx={{
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        </Grid>
        <Grid item xs={12} sm={12} md={4} component={Paper} square sx={{
          display: 'flex', // Added for layout purposes, adjust as needed
          flexDirection: 'column', // Added for layout purposes, adjust as needed
          alignItems: 'center', // Added for layout purposes, adjust as needed
          justifyContent: 'center', // Ensures the form is centered, adjust as needed
          minWidth: '300px', // Ensures a minimum width for the form
          maxWidth:'556px',
          width:'556px'
        }}>
          <Outlet />
        </Grid>
      </MuiGridRoot>
    </ThemeProvider>
  );
};

export default LoginLayout;
