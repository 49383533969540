import * as React from 'react';
import {
  MenuItem,
  Divider,
  InputAdornment
} from '@mui/material';
import {
  MuiInputBase as BootstrapInput,
  MuiSelect, MuiExpandMoreIcon, button
} from './styles/languageSelector';

import flagES from 'assets/img/spanish.png'
import flagEN from 'assets/img/english.png'
import flagPO from 'assets/img/portuguese.png'
import flagFR from 'assets/img/french.png'

import {useTranslation} from 'components/providers/TranslationProvider';

const LanguageSelector = ({textColor = 'black'}) => {
  const {languages, lng, changeLanguage} = useTranslation();
  const FlagIcon = ({language}) => {
    switch (language) {
      case 'spanish':
        return <img src={flagES} alt="ES"/>;
      case 'portuguese':
        return <img src={flagPO} alt="PO"/>;
      case 'french':
        return <img src={flagFR} alt="FR"/>;
      case 'arabic':
        return <img src={flagEN} alt="AR"/>;
      default:
        return <img src={flagEN} alt="EN"/>;
    }
  };

  const LanguageCode = ({language}) => {
    switch (language) {
      case 'spanish':
        return 'ES';
      case 'portuguese':
        return 'PT';
      case 'french':
        return 'FR';
      case 'arabic':
        return 'AR';
      default:
        return 'EN';
    }
  }


  return (
    <MuiSelect
      value={lng||'english'}
      onChange={(e) => changeLanguage(e.target.value)}
      input={<BootstrapInput style={{color: textColor}}/>}
      IconComponent={(props) => <MuiExpandMoreIcon {...props}
                                                   style={{color: textColor}}/>}
      sx={button}
      startAdornment={<InputAdornment position="start"><FlagIcon
        language={lng}/></InputAdornment>}
    >
      <Divider variant='inset'/>
      {languages?.map((l) => (
        <MenuItem key={l} disabled={l === lng} value={l} sx={button}>
          <LanguageCode language={l}/>
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default LanguageSelector;
