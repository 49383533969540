import {CssBaseline} from '@mui/material'
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {useLocation} from "react-router-dom";
import Header from 'components/common/Header';
import TranslationProvider from 'components/providers/TranslationProvider';
import AuthProvider from './components/providers/AuthProvider';
import Views from './components/common/Views';
import CustomThemeProvider from 'components/providers/CustomThemeProvider';
import React from 'react';
// import { useSelector } from 'react-redux';
import ProsperiaLoader from './components/common/ProsperiaLoader';
import LoadingProvider from './components/providers/LoadingProvider';
import {MuiGrid} from './appStyle';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex = 3) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const App = () => {
  const { pathname } = useLocation();
  const NoHeaderRoutes = ["/login", "/reset", "/forgot-password", "/"];
  const showHeader = !NoHeaderRoutes.includes(pathname);

  return (
    <CustomThemeProvider>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <TranslationProvider>
          <AuthProvider>
            <LoadingProvider>
              <MuiGrid container={true} direction='column'>
                {showHeader && <Header />}
                <Views />
                <ProsperiaLoader />
              </MuiGrid>
            </LoadingProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </AuthProvider>
        </TranslationProvider>
      </QueryClientProvider>
    </CustomThemeProvider>
  );
};

export default App;
