import React, {useEffect, useState} from 'react';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import {Box, Divider, Grid, Tooltip} from "@mui/material";
import {
  boxAnswerStyles,
  boxUserQueryStyles,
  gridContainerStyles,
  gridFilterStyles,
  MuiCard,
  MuiChip,
  MuiTypographyLabel,
  typographyAnswerStyles
} from './styles/collapsibleCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {FilterList, Refresh} from "@mui/icons-material";
import * as _ from "lodash";
import {
  setInsightsFilter,
  setSegmentationFilter,
  setSimilarityFilter,
  setSimilarityQuery, setStatisticFilter, setTreeMapFilter
} from "../../../../store/appSlice";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "../../../providers/CustomThemeProvider";
import color from "color";

const CollapsibleCard = ({
                           item,
                           index,
                           expand = false,
                           onDelete,
                           onCardSelected,
                           updateHistoryItem
                         }) => {
  const [expanded, setExpanded] = useState(expand);

  const [externalFilterChanged, setExternalFilterChanged] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const segmentationFilter = useSelector(state => state.app.segmentationFilter);
  const similarityFilter = useSelector(state => state.app.similarityFilter);
  const similarityQuery = useSelector(state => state.app.similarityQuery);
  const statisticFilter = useSelector(state => state.app.statisticFilter);
  const insightsFilter = useSelector(state => state.app.insightsFilter);
  const dispatch = useDispatch();
  const {theme} = useTheme();
  const handleExpandClick = () => {
    let localExpand = !expanded;
    setExternalFilterChanged(localExpand);
    setExpanded(localExpand);
    if (onCardSelected) {
      onCardSelected(localExpand ? item : null);
    }
  };


  useEffect(() => {
    setExpanded(expand);
    setExternalFilterChanged(expand);
  }, [expand]);


  const QAResponse = ({message}) => {
    const prependIconToFirstParagraph = (htmlContent, icon) => {
      // Check if the content starts with a <p> tag and prepend the icon to its content
      const startsWithPTag = htmlContent.trim().startsWith("<p>");
      if (startsWithPTag) {
        // Insert the icon right after the opening <p> tag
        return htmlContent.replace("<p>", `<p>${icon} `);
      }
      // If the content does not start with a <p> tag, just prepend the icon
      return `${icon} ${htmlContent}`;
    }

    let htmlContent = prependIconToFirstParagraph(message.replace('\n', ''), '✨')
    return (
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{__html: htmlContent}}
        style={typographyAnswerStyles}
      />
    )
  }

  useEffect(() => {
    if (item && item.filters && item.filters.length > 0) {
      const localFiltersApplied = item.filters.map(filter => {
        let filterApplied=false;
        if (filter.type === "segmentation" && filter.value) {
          for (const key in segmentationFilter) {
            if (segmentationFilter[key] === filter.value[key]) {
              filterApplied=true;
              break
            }
          }
        }
        if (filter.type === "statistic" && filter.value) {
          for (const key in statisticFilter) {
            if (statisticFilter[key] === filter.value[key]) {
              filterApplied=true;
              break
            }
          }
        }
        if (filter.type === "treemap" && filter.value) {
          filterApplied = _.isEqual(filter.value, treeMapFilter[item.policyId]);
        }
        if (filter.type === "similarity") {
          const value = _.cloneDeep(filter.value);
          let localSimilarityQuery = filter.similarityQuery || item.userQuery;
          if (Object.hasOwn(value?.distance, "low") && Object.hasOwn(value?.distance, "high")) {
            value.distance = {
              low: Math.floor(value.distance.low),
              high: Math.ceil(value.distance.high)
            }
          }
          let currentSimilarityFilter=null;
          if(similarityFilter[item.policyId]?.distance?.low && similarityFilter[item.policyId]?.distance?.high){
            currentSimilarityFilter = {
              distance: {
                low: Math.floor(similarityFilter[item.policyId]?.distance?.low),
                high: Math.ceil(similarityFilter[item.policyId]?.distance?.high)
              }
            }
          }
          const similarityFiltersEqual = _.isEqual(value, currentSimilarityFilter);
          filterApplied= localSimilarityQuery === similarityQuery[item.policyId] &&currentSimilarityFilter&& similarityFiltersEqual
        }
        if (filter.type === "insights") {
          filterApplied= _.isEqual(filter.value, insightsFilter[item.policyId])
        }
        console.log(filter.type," applied:",filterApplied);
        return filterApplied;
      }).reduce((a, b) => a && b, true);
      if(!localFiltersApplied) {
        setFiltersApplied(localFiltersApplied);
      }
    }
  }, [segmentationFilter, similarityFilter, similarityQuery, statisticFilter, insightsFilter, treeMapFilter]);


  const applyHistoryFilter = () => {
    let localSegmentationFilter = {}
    let localInsightsFilter = {}
    let localStatisticFilter = {}
    let localTreeMapFilter = {}
    if (item && item.filters && item.filters.length > 0) {
      item.filters.forEach(filter => {
        if (filter.type === "segmentation" && filter.value) {
          localSegmentationFilter = {...localSegmentationFilter, ...filter.value}
        }
        if (filter.type === "statistic" && filter.value) {
          localStatisticFilter = {...localStatisticFilter, ...filter.value}
        }
        if (filter.type === "treemap" && filter.value) {
          localTreeMapFilter = {...localTreeMapFilter, ...filter.value}
        }
        if (filter.type === "similarity") {
          const value = _.cloneDeep(filter.value);
          if (Object.hasOwn(value?.distance, "low") && Object.hasOwn(value?.distance, "high")) {
            let localSimilarityQuery = {};
            localSimilarityQuery[item.policyId] = filter.similarityQuery || item.userQuery;
            dispatch(setSimilarityQuery(localSimilarityQuery));
            value.distance = {
              low: parseFloat(value.distance.low.toFixed(3)),
              high: parseFloat(value.distance.high.toFixed(3))
            }
          }
          dispatch(setSimilarityFilter({[item.policyId]: value}))
        }
        if (filter.type === "insights") {
          localInsightsFilter = {...localInsightsFilter, ...filter.value}
        }
      });

      dispatch(setSegmentationFilter(localSegmentationFilter));
      dispatch(setInsightsFilter({[item.policyId]: localInsightsFilter}))
      dispatch(setStatisticFilter(localStatisticFilter));

      let globalTreeMapFilter = {...treeMapFilter};
      globalTreeMapFilter[item.policyId] = localTreeMapFilter;
      dispatch(setTreeMapFilter(globalTreeMapFilter));
      setFiltersApplied(true);
    }

  }

  const clearFilters = () => {
    if (item && item.filters && item.filters.length > 0) {
      let localSegmentationFilter = _.cloneDeep(segmentationFilter)
      let localStatisticFilter = _.cloneDeep(statisticFilter)
      let localTreeMapFilter = _.cloneDeep(treeMapFilter)
      let localSimilarityFilter = _.cloneDeep(similarityFilter)
      let localSimilarityQuery = _.cloneDeep(similarityQuery)
      let localInsightsFilter = _.cloneDeep(insightsFilter)
      item.filters.forEach(filter => {
        if (filter.type === "segmentation" && filter.value) {
          for (const key in filter.value) {
            delete localSegmentationFilter[key]
          }
        }
        if (filter.type === "statistic" && filter.value) {
          for (const key in filter.value) {
            delete localStatisticFilter[key]
          }
        }
        if (filter.type === "treemap" && filter.value) {
          for (const key in filter.value) {
            delete localTreeMapFilter[item.policyId][key]
          }
          dispatch(setTreeMapFilter(localTreeMapFilter));
        }
        if (filter.type === "similarity") {
          const value = _.cloneDeep(filter.value);
          if (Object.hasOwn(value?.distance, "low") && Object.hasOwn(value?.distance, "high")) {
            localSimilarityQuery[item.policyId] = "";
            localSimilarityFilter[item.policyId] = {};
          }

        }
        if (filter.type === "insights") {
          for (const key in filter.value) {
            delete localInsightsFilter[item.policyId][key]
          }
          dispatch(setInsightsFilter(localInsightsFilter));
        }
      });
      dispatch(setSegmentationFilter(localSegmentationFilter));
      dispatch(setStatisticFilter(localStatisticFilter));
      setFiltersApplied(false);
    }
  }

  return (
    <MuiCard key={`history-card-${item.policyId}-${index}`}>
      <Tooltip title={item.userQuery} placement={"top"}
               key={`tt-history-card-${item.userQuery}-${index}`}>
        <CardActions disableSpacing sx={{paddingBottom: '0px'}}
                     key={`history-card-action-${item.userQuery}-${index}`}>
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            size="large"
          >
            <KeyboardArrowDownIcon sx={{color: '#757575', fontSize: '24px'}}/>
          </IconButton>
          <Box sx={boxUserQueryStyles}
               onClick={handleExpandClick}
          >
            <MuiTypographyLabel
              variant="subtitle1"
              component="div"
              sx={{
                ...(expanded ? {
                  whiteSpace: 'normal', // Allow wrapping
                  overflow: 'visible',
                  width: '330px'
                } : {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '350px'
                }),
                fontFamily: 'Raleway',
                fontSize: '16px',
                fontWeight: '700',
                letterSpacing: '0.005em',
                fontStyle: 'bold',
              }}
            >
              {item.userQuery}
            </MuiTypographyLabel>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '5px',
            marginRight: '19px'
          }}>
            {(externalFilterChanged) &&
              <IconButton
                onClick={async () => {
                  await updateHistoryItem()
                }}
                sx={{
                  height: '22px',
                  width: '22px'
                }}
              >
                <Refresh
                  sx={{color: '#757575', height: '18px', width: '18px'}}/>
              </IconButton>}
            <IconButton
              onClick={onDelete ? async () => onDelete(item.userQuery) : (() => console.warn("no delete function provided"))}
              aria-label="delete"
              sx={{
                height: '22px',
                width: '2px'
              }}
            >
              <DeleteIcon sx={{
                color: '#757575', height: '18px',
                width: '18px'
              }}/>
            </IconButton>
          </Box>
        </CardActions>
      </Tooltip>
      {expanded && (
        <CardContent sx={{paddingTop: '2px', pb: "5px !important"}}
                     key={`history-card-content-${item.userQuery}-${index}`}>
          <Divider orientation={"horizontal"} style={{marginTop: 0}}/>
          <Grid container sx={gridContainerStyles}>
            <Box sx={boxAnswerStyles}>
              {/* HTML content container with indent */}
              <Box sx={{flex: 1}}>
                <QAResponse message={item.aiAnswer}/>
              </Box>
            </Box>
            {item.filters?.length > 0 && (
              <Divider sx={{width: '100%', mb: '16px'}}/>)}
            <Grid container item xs={12} style={gridFilterStyles}>
              {item.filters?.length > 0 && (
                <>
                  <Grid item xs={11} display="flex" flexWrap="wrap"
                        sx={{rowGap: '10px', columnGap: '5px'}}
                        flexDirection="row"
                        justifyContent="flex-start">
                    {item.filters?.map((filter, index) => (
                      <HistoryFilter
                        filter={filter}
                        index={index}
                        key={`history-filter-${filter.type}-${index}`}
                        sx={{
                          backgroundColor:filtersApplied? color(theme.palette.primary.main).lighten(0.8).hex():'#EEEEEE',
                        }}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={1} sx={{height: '100%'}} alignContent='center'
                        justifyContent='center'>
                    <IconButton
                      key={`history-filter-apply-filters`}
                      onClick={() => !filtersApplied ? applyHistoryFilter() : clearFilters()}
                      variant="outlined"
                      sx={{
                        height: '26px',
                        width: '26px',
                        borderRadius: '18px',
                        border: filtersApplied?`1px solid ${color(theme.palette.primary.main).lighten(0.8).hex()}`:'1px solid #EEEEEE',
                        ':hover':{
                          backgroundColor:filtersApplied?color(theme.palette.primary.main).lighten(0.9).hex():'#FFFFFF',
                          borderColor: filtersApplied?color(theme.palette.primary.main).lighten(0.8).hex():'#757575'
                        }
                      }}
                    >
                      <FilterList sx={{
                        color: filtersApplied ? theme.palette.primary.main : '#757575',
                        width: '16px',
                        height: '16px'
                      }}/>
                    </IconButton>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      )}
    </MuiCard>
  );
}

export default CollapsibleCard;

const HistoryFilter = ({filter, index, ...props}) => {
  return (
    <Tooltip title={filter.prettyText} placement={"top"}
             key={`tt-history-filter-${filter.type}-${index}`}>
      <MuiChip
        key={`history-filter-${filter.type}-${index}`}
        label={filter.prettyText}
        variant="outlined"
        {...props}
      />
    </Tooltip>
  );
}
