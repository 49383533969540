import axios from 'axios';
import apiPaths from './apiPaths';

const changePassword = (data, onError, onSuccess) => {
  const { user, password, accessToken } = data;
  axios
    .post(
      process.env.REACT_APP_API_URL + apiPaths.changePassword,
      { password: password, user: user },
      {
        headers: {
          Authorization: `Basic ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((resp) => onSuccess(resp.data))
    .catch((e) => {
      console.log(e);
      onError(e.error);
    });
};

export default changePassword;
