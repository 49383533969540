import React, {useState} from 'react';
import {Tabs} from 'antd';
import {v4 as uuidv4} from 'uuid';
import {getNormalPolicy} from '../../../../../../utils/template';
import {useDispatch, useSelector} from 'react-redux';
import {setActivePoliciesTab,} from '../../../../../../store/appSlice';
import {DndContext, PointerSensor, useSensor} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable
} from '@dnd-kit/sortable';
import * as _ from 'lodash';
import {CSS} from '@dnd-kit/utilities';
import {css} from '@emotion/css';
import {Paper} from "@mui/material";
import {generateID} from "../../../../../../utils/utils";
import axios from "../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../api/apiPaths";
import {status200} from "../../../../../../api/status.utils";
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import {useLoading} from "../../../../../providers/LoadingProvider";
import Question from "./steps/Question";

const DraggableTabNode = ({className, onActiveBarTransform, ...props}) => {
  const {
    attributes, listeners, setNodeRef, transform, transition, isSorting
  } = useSortable({
    id: props['data-node-key'],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  React.useEffect(() => {
    if (!isSorting) {
      onActiveBarTransform('');
    } else if (className?.includes('ant-tabs-tab-active')) {
      onActiveBarTransform(css`
        .ant-tabs-ink-bar {
          transform: ${CSS.Transform.toString(transform)};
          transition: ${transition} !important;
        }
      `,);
    }
  }, [className, isSorting, transform]);

  return React.cloneElement(props.children, {
    ref: setNodeRef, style, ...attributes, ...listeners,
  });
};

const OpenQuestions = ({
                         questions,
                         dataRegion,
                         variables,
                         setQuestions,
                         dataset,
                         weight,
                         handleSaveScheme
                       }) => {
  const initialItems = [];
  const [activeKey, setActiveKey] = useState(0);
  const [items, setItems] = useState(initialItems);
  const dispatch = useDispatch()
  const activeTab = useSelector(state => state.app.activePoliciesTab)
  const {
    setTitle, setIsLoading, setShowProgressBar, setProgressMessage, setProgress
  } = useLoading();
  const {lng} = useTranslation();

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
    dispatch(setActivePoliciesTab(newActiveKey))
  };

  const add = () => {
    const id = uuidv4();
    let allQuestions = [...questions];
    let newQuestion = {
      id,
      name: "Question " + (allQuestions.length + 1),
      answerVar: '',
      audioVar: null,
      tree_state: null,
      parentPolicy: null,
      pos: items.length + 1,
      type: 'normal',
      topics: [],
      classified: false,
      segmentation_variables: {
        in_dataset: [], custom: null,
      },
    };

    if (questions[0]) {
      newQuestion = Object.assign({}, questions[0], newQuestion);
    } else {
      newQuestion = getNormalPolicy(newQuestion)
    }

    allQuestions.push(newQuestion);
    setQuestions(allQuestions);
  };

  const remove = (targetKey) => {
    let allQuestions = [...questions];
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    allQuestions = allQuestions.filter((item) => item.id !== targetKey);
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setQuestions(allQuestions);
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  const handleQuestionChange = (question) => {
    // console.log("New Question", pol);
    const questionIndex = questions.findIndex(p => p.id === question.id)
    let localQuestions = [...questions]
    const subordinates = localQuestions.filter(q => q.parentPolicy !== null && q.parentPolicy?.id === question.id && !_.isEqual(q.parentPolicy.topics.map(t => ({
      topic: t.topic,
      image_url: t.image_url,
      description: t.description,
      image_description: t.image_description,
      count: t.count
    })), question.topics.map(t => ({
      topic: t.topic,
      image_url: t.image_url,
      description: t.description,
      image_description: t.image_description,
      count: t.count
    }))));

    localQuestions[questionIndex] = question;
    setQuestions(localQuestions);
    if (subordinates?.length > 0) {
      console.log("Change detected in topics")
      console.log("Found subordinate questions", subordinates)
      updateSubordinateQuestions(subordinates, localQuestions, question);
    }
  }

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const updateSubordinateQuestions = async (subordinates, localQuestions, updatedParent) => {
    setShowProgressBar(false);
    setIsLoading(true);
    setTitle("Categorizing dependent questions");
    setProgress(0);
    setProgressMessage("This will only take a few seconds...")
    for (const subordinate of subordinates) {
      const localQuestionIdx = localQuestions.findIndex(lp => lp.id === subordinate.id);
      if (localQuestionIdx >= 0 && !_.isEqual(subordinate.parentPolicy.topics.map(t => ({
        ...t, subtopics: []
      })), updatedParent.topics.map(t => ({...t, subtopics: []})))) {
        subordinate.parentPolicy = updatedParent;
        subordinate.topics = await reInitializePartialClassification(subordinate);
        localQuestions[localQuestionIdx] = subordinate;
      }
    }
    setIsLoading(false);
    setTitle('');
    setProgressMessage("");
    return localQuestions;
  }

  const reInitializePartialClassification = async (questionClassify) => {
    let requestId = generateID();
    let sourceClassificationVar = questionClassify.parentPolicy?.answerVar + "_label";
    if (questionClassify.selectedClassificationSegment?.propName) {
      sourceClassificationVar = questionClassify.selectedClassificationSegment.propName;
    }
    if (!sourceClassificationVar) {
      errorToast("Error initializing classification. Please select a segmentation variable or a parent question.");
      return questionClassify.topics;
    }
    const payload = {
      collection_name: dataset,
      answers_column: questionClassify.answerVar,
      topics: questionClassify.parentPolicy?.topics || [],
      requestId: requestId,
      saveClassification: true,
      classification_type: "topics",
      sourceClassificationVariable: sourceClassificationVar,
      lang: lng,
      region: dataRegion || "US",
    }

    try {
      const response = await axios.post(apiPaths.initialize_partial_classification, payload, status200);
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData) {
          return responseData.updatedTopics;
        }
      }
    } catch (error) {
      console.error(error);
      errorToast(`Error computing classification for Question: ${questionClassify.name}`);
    }
    return questionClassify.topics
  }

  React.useEffect(() => {
    let firstKey = null;
    for (const question of questions) {
      initialItems.push({
        label: question.name, children: (<Question
          question={question}
          dataRegion={dataRegion}
          parentCandidates={questions.filter(p => p.id !== question.id)}
          variables={variables}
          handleQuestionChange={(p) => handleQuestionChange(p)}
          collection={dataset}
          weight={weight}
          key={question.id}
          handleSaveScheme={handleSaveScheme}
        />), key: String(question.id),
      })

      if (firstKey === null) {
        firstKey = question.id
      }
    }

    setItems(initialItems);
    setActiveKey(String(activeTab ? activeTab : firstKey))
  }, [questions])

  const [className, setClassName] = useState('');

  const sensor = useSensor(PointerSensor, {activationConstraint: {distance: 10}});

  const onDragEnd = ({active, over}) => {
    if (active.id !== over?.id) {
      let localQuestions = [...questions];
      const activeIndex = localQuestions.findIndex((i) => i.id === active.id);
      const overIndex = localQuestions.findIndex((i) => i.id === over?.id);
      setQuestions(arrayMove(localQuestions, activeIndex, overIndex));
    }
  };

  return (<Paper style={{borderTop: 'none', padding: "10px"}}>
    <Tabs
      className={className}
      style={{width: '100%', height: 'auto'}}
      type="editable-card"
      onChange={onChange}
      activeKey={activeKey}
      onEdit={onEdit}
      items={items}
      renderTabBar={(tabBarProps, DefaultTabBar) => (
        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
          <SortableContext items={items.map((i) => i.key)}
                           strategy={horizontalListSortingStrategy}>
            <DefaultTabBar {...tabBarProps}>
              {(node) => (<DraggableTabNode
                {...node.props}
                key={node.key}
                onActiveBarTransform={setClassName}
              >
                {node}
              </DraggableTabNode>)}
            </DefaultTabBar>
          </SortableContext>
        </DndContext>)}/>
  </Paper>);
};

export default OpenQuestions;
