import * as React from "react";
import {useEffect, useState} from "react";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Popover,
  Typography
} from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import CloseIcon from "@mui/icons-material/Close";
import {useTheme} from "../../../../providers/CustomThemeProvider";
import {useDispatch, useSelector} from "react-redux";
import {setTreeMapColorScheme} from "../../../../../store/appSlice";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {
  colorSchemes,
  findClosestPaletteName
} from "../../../../../utils/colorPaletteUtils";

const ColorPaletteSelector = () => {
  const iconRef = React.useRef(null);
  const [top, setTop] = React.useState(0);
  const [left, setLeft] = React.useState(0);
  const [openColorSelector, setOpenColorSelector] = useState(false);
  const [showFullPalette, setShowFullPalette] = useState(false);
  const currentColorScheme = useSelector(state => state.app.treeMapColorScheme);
  const dispatch= useDispatch();
  const {theme} = useTheme();
  const {t} = useTranslation();
  const recommended_palette = findClosestPaletteName({
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main
  }, colorSchemes);


  function updatePopoverPosition() {
    if (iconRef.current) {
      const boundingClientRect = iconRef.current.getBoundingClientRect();
      setTop(boundingClientRect.bottom);
      setLeft(boundingClientRect.left);
    }
  }

  useEffect(() => {
    updatePopoverPosition();
  }, []);

  const renderPalette = (palette_name, label,key) => (
    <div key={key}>
      <div style={{display: 'flex', position: 'relative'}}>
        {colorSchemes[palette_name].map((color, index) => (
          <div key={`palette-${key}-color-${index}`}
               style={{
                 width: '25px',
                 height: '25px',
                 backgroundColor: color,
                 // Keeping colors next to each other without separation
               }}
               onClick={() => dispatch(setTreeMapColorScheme(palette_name))}
          />
        ))}
        {palette_name === currentColorScheme && (
          <CheckIcon style={{
            position: 'absolute',
            // Calculate the top and left position to align the CheckIcon with the middle square
            top: 0, // Top of the middle square
            left: `${((colorSchemes[palette_name].length / 2) - 0.5) * 25}px`, // Center it on the middle square
            color: '#ffffff',
            // No need to adjust the size if the icon naturally fits the square
          }}/>
        )}
      </div>
      <Typography style={{
        fontFamily: 'Montserrat',
        fontSize: '12px',
        color: '#616161'
      }}>{label}</Typography>
    </div>
  );

  return (
    <Container
      style={{
        alignSelf: 'flex-end',
        width: '30px',
        // marginBottom: '0px',
        marginTop: '-45px',
        marginRight: '20px',
        zIndex: 1000,
        backgroundColor: 'transparent'
      }}
    >
      <IconButton
        ref={iconRef}
        onClick={() => {
          setOpenColorSelector(true);
          setShowFullPalette(false);
          updatePopoverPosition();
        }}
        aria-describedby={'color-selector'}
      >
        <PaletteIcon/>
      </IconButton>
      <Popover open={openColorSelector}
               id={'color-selector'}
               onClose={() => {
                 setOpenColorSelector(false)
                 setShowFullPalette(false)
               }}
               // anchorPosition={{
               //   top: `${top - 15}px`,
               //   left: `${left - 155}px`
               // }}
               // anchorReference={`anchorPosition`}
               style={{
                 position: 'absolute',
                 top: `${top - 15}px`,
                 left: `${left - 150}px`
               }}
      >
        <Box sx={{
          width: '175px',
          minHeight: '208px',
          maxHeight: '713px',
          bgcolor: '#FFFFFF',
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%', // Ensure the container fills the width of the parent Box
            mb: 1, // Margin bottom for spacing between the title/close button row and the content below
          }}>
            <Typography variant="body2" style={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              color: '#212121'
            }} component="div">
              {t('color_palette')}
            </Typography>
            <IconButton
              style={{
                alignSelf: 'flex-end',
                padding: '0px'
              }} // Align the close button to the right
              onClick={() => {
                setOpenColorSelector(false);
                setShowFullPalette(false);
              }}
            >
              <CloseIcon/>
            </IconButton>
          </Box>

          {renderPalette('material_colors', `${t('predefined_palette')}`,'predefined_palette')}
          {renderPalette(recommended_palette, `${t('recommended_palette')}`,'recommended_palette')}
          {!showFullPalette && (
            <Button
              onClick={() => setShowFullPalette(true)}
              style={{
                justifyContent: 'flex-start',
                padding: 0,
                fontFamily: 'Montserrat',
                fontSize: '12px'
              }}
            >
              {t('see_more')}
            </Button>
          )}
          {showFullPalette && (
            <Divider/>
          )}
          {showFullPalette && Object.keys(colorSchemes).map((paletteName,index) => renderPalette(paletteName, '',`palette-${index}`))}
          {showFullPalette && (
            <Button
              onClick={() => setShowFullPalette(false)}
              style={{
                justifyContent: 'flex-start',
                padding: 0,
                fontFamily: 'Montserrat',
                fontSize: '12px'
              }}
            >
              {t('see_less')}
            </Button>
          )}
        </Box>
      </Popover>
    </Container>
  );

}

export default ColorPaletteSelector;
