import {useMutation, useQueryClient} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const newUser = async (data) => {
  const { user } = data;
  await axios.post(apiPaths.create_user, user, status200).then((resp) => resp.data);
};

const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(async (values) => await newUser(values), {
    onSuccess: () => {
      queryClient.resetQueries('users');
    },
    onError: (error) => {
      console.error(error);
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries('users');
    },
  });
};
export default useCreateUser;
