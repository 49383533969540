export const tooltipContainerStyles = {
  backgroundColor: '#FFFFFF',
  padding: '5px',
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  pointerEvents: 'none'
};

export const tooltipStyles = {
  width: '12.5px',
  height: '12.5px',
  marginRight: '5px'
};

export const textNoDataStyles = {
  minWidth: "20%",
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1vw',
  fontFamily:'Montserrat',
  fontWeight: '600'
};




