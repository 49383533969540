import {
  Container,
  Toolbar,
  Paper,
  styled,
  Tabs,
  Tab,
  SvgIcon
} from '@mui/material';
import * as React from "react";

export const MuiContainer = styled(Container)(() => ({
  width: '100% !important',
  height: '100% !important',
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
  marginRight: '0',
  minWidth: "100vw",
  maxHeight: "100vh",
  overflow: 'hidden'
}));

export const MuiToolbar = styled(Toolbar)(() => ({
  backgroundColor: 'white',
  width: '100%',
  padding: '5px !important',
  marginRight: '0px',
}));

export const MuiPaper = styled(Paper)(() => ({
  borderRadius: '20px 20px 20px 20px',
  paddingLeft: '8px !important',
  width: '100%',
  zIndex: 1,
  paddingBottom: "5px",
  background: '#FAFAFA'
}));

export const MuiTabs = styled(Tabs)(({theme}) => ({
  padding: theme.spacing(0),
  marginTop: theme.spacing(1.3),
  paddingRight: "30px",
  borderBottom: '1px solid',
  borderColor: theme.palette.secondary.main,
  '& .Mui-selected': {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    color: theme.palette.primary.main,
    transition: 'font-size 10ms, font-weight 10ms',
  },
  '& .MuiTabs-indicator': {
    height: 3,
    width: '0',
    borderRadius: '10px 10px 0 0',
    transition: 'width 0.3s',
    marginBottom: theme.spacing(0)
  },
  '& .MuiTabs-indicator[data-indicator-color="secondary"]': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const MuiTab = styled(Tab)(({theme}) => ({
  minWidth: 'auto',
  padding: theme.spacing(0),
  marginLeft: theme.spacing(2),
}));

export const gridContainerStyle = {
  width: '100%',
  height: '100%',
  marginTop: '2px',
  paddingTop: 0
}

export const gridItemStyle = {
  overflowY: 'hidden',
  marginLeft: '0px !important',
  paddingRight: '5px',
  paddingTop: '0px'
}

export const treeMapGridStyle = {
  paddingLeft: 2,
  paddingTop: 0,
  marginTop: '-10px'
}

export const gridContainerTreeMapStyle = {
  background: '#FAFAFA',
  borderRadius: '20px'
}

export const paperWidgetsStyle = {
  width: '100%',
  overflowY: 'hidden'
}

export const paperInsightsStyle = {
  overflowY: 'auto',
  width: '100%',
  background: '#FAFAFA',
  borderColor: '#EEEEEE',
  borderWidth: '1px',
  borderRadius: '20px'
}

export const treePageStyle = {
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden'
}

export const fabStyle = {
  position: 'fixed',
  bottom: '16px',
  right: '20px',
  backgroundColor: 'primary'
}

export const gridInsightsStyle = {
  overflow: 'hidden',
  paddingBottom: '1px',
  paddingTop: '0px',
  borderLeft: '1px solid',
  borderRadius: '20px',
  borderLeftColor: '#EEEEEE',
  width: '100%',
  top: 0,
  marginTop: 0,
  paddingLeft: '5px',
  transition: 'opacity 0.5s, right 0.5s',
};

export const divTabStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}
export const titleTabStyle = {
  fontSize: 14,
  fontFamily: 'Montserrat',
  fontWeight: 600
}

export const fabIconInsigthsStyle = {
  padding: 0,
  borderRadius: '30%',
  borderLeft: '1px solid #EEEEEE',
  borderTop: 'none',
  borderBottom: 'none',
  borderRight:'none',
  position: 'fixed',
  top: '50%',
  height: '50px',
  width: '16px',
  transform: `translateY(50%)`,
  transition: 'transform 0.7s ease',
  background: '#FAFAFA',
  boxShadow: 'none',
  '&:hover': {
   background: '#FAFAFA',
  },
  '&:active': {
    background: '#FAFAFA',
  },
  '&:focus': {
    background: '#FAFAFA',
  },
  ':before': {
    background: '#FAFAFA',
  }
}

export const arrowLeftStyle = {
  marginRight: '2px',
  color: '#757575'
}

export const SubordinateQuestionIcon = (props) => {

  return (
    <SvgIcon
      style={{width: '20px', height: '20px', marginTop: '7px'}} {...props}>
      <path
            d="M5.83333 1.33325H11.1667V5.99992H9.16667V7.33325H11.1667H12.5V8.66659V9.99992H14.5V14.6666H9.16667V9.99992H11.1667V8.66659L9.16667 8.66658H7.83333L5.83333 8.66659V9.99992H7.83333V14.6666H2.5V9.99992H4.5V8.66659V7.33325H5.83333H7.83333V5.99992H5.83333V1.33325Z"
            fill={props.color||"#00828F"}/>
    </SvgIcon>
  );
}
