import {getRandomId} from './formatter';

export const getNormalPolicy = (policy) => {
  return Object.assign({
    id: getRandomId(),
    name: 'Question 1',
    audioVar:null,
    answerVar: null,
    tree_state: null,
    pos: 1,
    type: 'normal',
    topics: [],
    classified: false,

    previous_policies: [],

    segmentation_variables: {
      in_dataset: [],
      custom: null,
    },
    statistics: [],
  }, policy);
}
