import {useSnackbar} from "notistack";
import {
  fetchCollection,
  useCollection
} from "../../../../../../api/hooks/useCollection";
import {useDispatch} from "react-redux";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import {setTargetCollection} from "../../../../../../store/appSlice";
import {getRandomId} from "../../../../../../utils/formatter";
import React from "react";
import {Button, FormControl, Grid, MenuItem, TextField} from "@mui/material";

const GeneralParameters = ({
                             collections,
                             scheme,
                             setScheme,
                             weightFields,
                             isCreating,
                             orgId,
                             handleExportScheme
                           }) => {
  const {enqueueSnackbar} = useSnackbar()

  const {data: collection} = useCollection({
    user_id: orgId,
    collection: scheme.dataset,
    enabled: scheme.dataset !== '' && isCreating,
  })
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleChange = async (event) => {
    if (event.target.name === 'dataset') {
      const localCollection = await fetchCollection({
        user_id: orgId,
        collection: event.target.value
      })
      if (localCollection) {
        console.log("Dispatching", localCollection)
        dispatch(setTargetCollection(localCollection))
      }
    }
    setScheme(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value
    }))
  };


  const errorMsg = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'error',
      preventDuplicate: true,
    })
  }

  const handleImportScenario = async (ev) => {
    const fr = new FileReader();
    fr.onload = async e => {
      try {
        const schemeData = JSON.parse(e.target.result)
        schemeData['id'] = getRandomId()
        schemeData['dataset'] = scheme.dataset
        schemeData['name'] = scheme.name
        let errors = []

        if (scheme.weight === '') {
          errors.push("Choose weight variable.")
        }

        // validate policies
        schemeData.policies.forEach((x) => {
          x.classified = false;
          x.id = getRandomId()
          if (x.name === '') {
            errors.push('You need fill the name of the policy')
          }
          if (x.answerVar === '') {
            errors.push('You need to specify the answer variable')
          }
          if (x.topics.length === 0) {
            errors.push('You need to specify at least one topic')
            for (const topic of x.topics) {
              if (!topic.topic) {
                errors.push('You need to specify the topic')
              }
              if (!topic.description) {
                topic.description = ''
              }
            }
          }
        });

        if (schemeData.policies.length === 0) {
          errors.push("You need at least one policy")
        }

        if (errors.length > 0) {
          for (const error of errors) {
            errorMsg(error)
          }
        } else {
          setScheme(prev => ({...prev, ...schemeData}))
        }

        ev.target.value = null;
      } catch (e) {
        errorMsg('Invalid json file')
      }
    }

    if (ev.target.files.length > 0 && ev.target.files[0]) {
      fr.readAsText(ev.target.files[0]);
    }
  }

  React.useEffect(() => {
    if (collection) {
      dispatch(setTargetCollection(collection))
    }
  }, [collection])

  return (
    <Grid container spacing={2} noValidate>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            id="analysis_name"
            label={t('analysis_name')}
            name="name"
            value={scheme.name}
            onChange={async (e) => await handleChange(e)}
            inputProps={{maxLength: 80}}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            id="analysis_description"
            label={t('analysis_description')}
            name="description"
            value={scheme.description || ""}
            onChange={async (e) => await handleChange(e)}/>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="dataset_selection"
            name="dataset"
            label="Dataset"
            select
            onChange={async (e) => await handleChange(e)}
            value={scheme.dataset}>
            {collections?.map(d =>
              <MenuItem key={d.collection}
                        value={d.collection}>{d.name}</MenuItem>
            )}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="weight_selection"
            label="Weight"
            name="weight"
            placeholder='How many people an answer represents?'
            select
            onChange={async (e) => await handleChange(e)}
            value={scheme.weight ? scheme.weight : ""}>
            {weightFields?.map((weight) =>
              <MenuItem key={weight.propName}
                        value={weight.propName}>{weight.label ? weight.label : weight.propName}</MenuItem>
            )}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={12} container sm={12} justifyContent="flex-end">
        <input
          accept=".json"
          style={{display: 'none'}}
          id="import_scheme_button"
          name="import_scheme_button"
          multiple
          type="file"
          readOnly={!scheme.dataset && scheme.dataset === ''}
          onChange={(e) => handleImportScenario(e)}
        />
        <label htmlFor="import_scheme_button">
          <Button variant="contained"
                  color="secondary"
                  disabled={!scheme.dataset && scheme.dataset === ''}
                  component="span">
            {t('btn_import_scheme')}
          </Button>
        </label>

        <Button
          style={{marginLeft: 6}}
          data-cy='export_scheme_button'
          disabled={isCreating}
          variant="contained"
          color="secondary"
          onClick={() => handleExportScheme()}>
          {t('btn_export_scheme')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default GeneralParameters;
