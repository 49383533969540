import {Button, styled} from '@mui/material';

export const MuiButton = styled(Button)(({ theme }) => ({
  padding:'10px 16px 10px 16px',
  borderRadius: '20px',
  width:'105px',
  height:'37px',
  color:'#005F64',
  borderColor: '#005F64'
}));
