import * as React from 'react';
import {useEffect} from 'react';

import {Box, Divider, Grid, Typography} from '@mui/material';
import 'tippy.js/dist/tippy.css';

import CategoryWidget from './category/CategoryWidget';
import HistogramWidget from './histogram/HistogramWidget';
import {useTranslation} from "../../providers/TranslationProvider";
import FilterIcon from '@mui/icons-material/FilterList';
import {useSelector} from "react-redux";

import {
  divTitleStyles,
  titleStyles
} from '../styles/segments';
import {useTheme} from "../../providers/CustomThemeProvider";

const getDataAxis = (widget) => {
  const histColors = widget.customHistogramColors;

  if (histColors) {
    return histColors.map((d) => {
      return +d.tick_id;
    });
  }

  return [0, 10, 100, 1000, 10000];
};

const SegmentsWidget = ({widget}) => {
  const colorEnabled = false
  return (
    <Box style={{maxHeight: '100%', minHeight:50, overflow: 'auto', marginBottom:'5px'}}>
      {widget.type === 'histogram' && (
        <>
          <HistogramWidget
            id={widget.id}
            title={widget.name}
            dataSource={widget.dataSource}
            dataRegion={widget.dataRegion}
            column={widget.column}
            answerVar={widget.answerVar}
            ticks={[]}
            formatter={(v) => Number(v).toFixed(3)}
            dataAxis={getDataAxis(widget)}
            color={{
              enabled: colorEnabled,
              type: 'simple',
              customColors: [],
            }}
            global={true}
            description='not_description'
            wrapperProps={{
              description: widget.description,
              expand: !widget.folded_state,
              disabledWidget: false,
              actions: [],
            }}
            weight={widget.weight}
            policyId={widget.policyId}
          />
          <Divider style={{marginTop: '10px'}}/>
        </>
      )}
      {widget.type === 'category' && (
        <>
          <CategoryWidget
            id={widget.id}
            title={widget.name}
            dataSource={widget.dataSource}
            dataRegion={widget.dataRegion}
            column={widget.column}
            answerVar={widget.answerVar}
            weight={widget.weight}
            policyId={widget.policyId}
            enableSegmentationAggregation={widget.enableSegmentAggregation}
            wrapperProps={{
              expand: !widget.folded_state,
              description: widget.description,
              disabledWidget: false,
              actions: [],
            }}
          />
          <Divider style={{marginTop: '10px'}}/>
        </>
      )}
    </Box>
  );
};

const Segments = ({widgets, policyId,height}) => {
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSegmentationFilters = useSelector(state => state.app.segmentationFilter);
  const [filtersEnable, setFiltersEnable] = React.useState(false);
  const {t} = useTranslation();
  const {theme} = useTheme();

  useEffect(() => {
    const hasStatisticFilters = activeStatisticFilters && Object.keys(activeStatisticFilters).length > 0;
    let hasSegmentationFilters = activeSegmentationFilters && Object.keys(activeSegmentationFilters).length > 0;
    if (hasSegmentationFilters) {
      hasSegmentationFilters = widgets.findIndex(w => w.type === 'category' && w.column in activeSegmentationFilters) > -1
    }
    setFiltersEnable(hasStatisticFilters || hasSegmentationFilters);
  }, [activeSegmentationFilters, activeStatisticFilters, policyId]);

  if (widgets?.length) {
    return (
      <Box style={{
        flexDirection: 'column',
        maxHeight: height,
        height:height,
        maxWidth:'100%',
        overflowY:'hidden'
      }}>
        <div style={divTitleStyles}>
          <Typography style={titleStyles}  display="block" gutterBottom>
            {t('filters')}
          </Typography>
          {filtersEnable && <FilterIcon style={{ marginLeft: '5px', marginTop:'20px' }} color='secondary'/>}
        </div>
        <Divider style={{backgroundColor:theme.palette.secondary.main, marginLeft: '8px'}}/>
        <Box style={{ flex: 1, overflowY: 'auto',marginTop: '10px',maxHeight:height-60 }} id={'widget-box-container'}>
          <div>
            {widgets?.filter(w => w.type !== 'list' && w.type !== 'similarity').map((w,i) => (
              <Grid style={{ display: 'inline' }} key={`$segment-${i}-${policyId}`}>
                {w.visible && <SegmentsWidget widget={w}/>}
              </Grid>
            ))}
          </div>
        </Box>
      </Box>

    );
  }
  return null;
};
export default Segments;
