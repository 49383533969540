import {useQuery} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchUsers = (id) =>
  axios.post(apiPaths.users_organization, { org_id: id }, status200).then((resp) => resp.data);

const useUsers = (id) =>
  useQuery('users', () => fetchUsers(id));

export default useUsers;
