import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import {FormControl, Grid, Paper, TextField} from "@mui/material";
import {MuiAutocomplete} from "./styles/segmentationParameters";

const SegmentationParameters = ({
                                  scheme,
                                  setScheme, categoricalFields, statisticFields
                                }) => {

  const [selectedCategoricalFields, setSelectedCategoricalFields] = useState([]);
  const [selectedStatisticFields, setSelectedStatisticFields] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    if (scheme) {
      let localSelectedCategoricalFields = scheme.segmentation_variables;
      if (localSelectedCategoricalFields) {
        setSelectedCategoricalFields(
          categoricalFields.filter(d => localSelectedCategoricalFields.includes(d.propName))
        );
      }
      let localSelectedStatisticFields = scheme.statistic_variables;
      if (localSelectedStatisticFields) {
        setSelectedStatisticFields(
          statisticFields.filter(d => localSelectedStatisticFields.includes(d.propName))
        );
      }
    }
  }, [scheme, categoricalFields, statisticFields]);

  return (
    <Paper style={{padding: "8px"}}>
      <Grid container direction="column" spacing={3} style={{width: '100%'}}>
        <Grid item xs={12}>
          <FormControl sx={{width: '100%'}} fullWidth variant="outlined">
            <MuiAutocomplete
              id="categorical_segment_selection"
              options={categoricalFields}
              value={selectedCategoricalFields}
              onChange={(e, values) => {
                setSelectedCategoricalFields(values);
                const local_scheme = {...scheme};
                local_scheme.segmentation_variables = values?.map(d => d.propName);
                setScheme(local_scheme);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t('segmentation_criteria')}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <MuiAutocomplete
              id="statistic_segment_selection"
              options={statisticFields}
              value={selectedStatisticFields}
              onChange={(e, values) => {
                setSelectedStatisticFields(values)
                const local_scheme = {...scheme};
                local_scheme.statistic_variables = values?.map(d => d.propName);
                setScheme(local_scheme);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t('statistic_column')}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SegmentationParameters;
