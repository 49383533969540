export const boxContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100%',
  overflowY:'hidden'
};

export const divTitleStyles = {
  display: 'flex',
  alignItems: 'center',
};

export const titleStyles = {
  fontSize: 14,
  marginLeft: '16px',
  textTransform: 'uppercase',
  fontWeight:700,
  marginTop: '24px',
  fontStyle:'normal',
  fontFamily:'Montserrat',
  color:'#212121'
};
