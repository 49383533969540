import * as React from 'react';
import {TableContainer} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import useUsers from 'api/hooks/useUsers';
import {usePermissions} from 'components/hooks/usePermissions';
import Table from '../common/Table';
import ActionBar from './ActionBar';
import UpdateUser from './UpdateUser';
import DeleteUser from './DeleteUser';
import {MuiContainer} from '../styles/user';
import TabsCustoms from '../common/TabsCustoms';
import TPagination from '../common/TPagination';


const columns = ['Email', 'Type', 'Organization', 'Partner organizations',
                 'Admin Rights', 'Actions'];
const row = ['email', 'type', 'myOrg', 'myOrgsLevel', 'myOrgs'];

const Users = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState(new Array(columns.length).fill(''));

  const { pathname } = useLocation();
  const location = useLocation();
  const orgId = pathname.split('/')[2];
  const { data, isLoading } = useUsers(orgId);
  const { hasPermissionFor } = usePermissions();
  const navigate = useNavigate();

  const handleDelete = (id, data) => {
    navigate(`${location.pathname}?delete=${data._id}`, { replace: true, state: data });
  };

  const handleEdit = (id, user) => {
    navigate(`${location.pathname}?edit=${id}`, { replace: true, state: user });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate the current page data based on the pagination settings
  const filteredData = data
    ? data?.filter((item) => {
      const lowerCaseSearchTerms = searchTerm.map((term) => term.toLowerCase());
      const itemString = `${item.email} ${item.type} ${item.myOrgs} ${item.myOrgsLevel}`.toLowerCase();
      return lowerCaseSearchTerms.every((term) => itemString.includes(term));
    })
    : [];

  const offset = page * rowsPerPage;
  const currentPageData = filteredData.slice(offset, offset + rowsPerPage);

  const handlePreviousPage = () =>
    setPage((prevPage) => Math.max(0, prevPage - 1));


  const handleNextPage = () =>
    setPage((prevPage) => Math.min(Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1, prevPage + 1));


  const handleFirstPage = () => setPage(0);

  const handleLastPage = () => {
    const lastPage = Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1);
    setPage(lastPage);
  };

  const handleSearch = (event, index) => {
    const newSearchTerms = [...searchTerm];
    newSearchTerms[index] = event.target.value;
    setSearchTerm(newSearchTerms);
    setPage(0);
  };
  return (
    <MuiContainer>
      <TabsCustoms/>
      <ActionBar />
      <TableContainer sx={{background:'#FAFAFA'}}>
        <UpdateUser />
        <DeleteUser />
        <Table
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          data={currentPageData}
          row={row}
          columns={columns}
          onDelete={handleDelete}
          showDelete={hasPermissionFor('delete_users')}
          onEdit={handleEdit}
          showEdit={hasPermissionFor('update_users')}
          isLoading={isLoading}
        />
      </TableContainer>
      <TPagination
        data={filteredData}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleFirstPage={handleFirstPage}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        handleLastPage={handleLastPage}
      />
    </MuiContainer>
  );
};

export default Users;
