const enginePaths = {
  aggregate_answers: '/aggregation/answers',
  aggregate_topics_segments: '/aggregation/label-segments',
  aggregate_list: "/aggregation/list",
  aggregate_category: "/aggregation/categorical",
  aggregate_statistic: "/aggregation/statistic",
  query_similarity: "/query-similarity",
  qa: "/query"

}

export default enginePaths;
