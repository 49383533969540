import {useSnackbar} from "notistack";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {useLoading} from "../../../../providers/LoadingProvider";
import useCreateScheme from "../../../../../api/hooks/useCreateScheme";
import useUpdateScheme from "../../../../../api/hooks/useUpdateScheme";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import axiosEngineInstance from "../../../../../api/axios/axiosEngineInstance";
import enginePaths from "../../../../../api/enginePaths";
import {status200} from "../../../../../api/status.utils";
import {getRandomId} from "../../../../../utils/formatter";
import {generateID, schemeChanged} from "../../../../../utils/utils";
import axios from "../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../api/apiPaths";
import {
  setTargetCollection,
  setTargetScheme
} from "../../../../../store/appSlice";
import {Box, CardActions, CardContent, Grid, Typography} from "@mui/material";
import GeneralParameters from "./sections/GeneralParameters";
import SegmentationParameters from "./sections/SegmentationParameters";
import OpenQuestions from "./sections/OpenQuestions";
import {MuiButton, MuiCard, MuiPaper} from "./styles/configurationSteps";

const ConfigurationSections = ({scenario, schemes, orgId, isWorkspace}) => {
  const initialValues = {
    dataset: '',
    name: '',
    description: ''
  };
  const defaultWeight = {
    propName: 'weight',
    label: 'weight',
    category: 'weight',
  }
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [scheme, setScheme] = React.useState(initialValues);
  const [selectedCollection, setSelectedCollection] = React.useState(null)
  const [weightFields, setWeightFields] = React.useState([defaultWeight])
  const [variables, setVariables] = React.useState([])
  const [questions, setQuestions] = React.useState([])
  const [schemeCollectionConfig, setSchemeCollectionConfig] = React.useState([])
  const [enableSave, setEnableSave] = React.useState(false)
  const [dataRegion, setDataRegion] = React.useState("US");
  const {
    setIsLoading,
    setIsClassificationLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();
  const targetScheme = useSelector(state => state.app.targetScheme)
  const targetCollection = useSelector(state => state.app.targetCollection)
  const schemeNameChange = false
  const {mutateAsync: createScheme} = useCreateScheme();
  const {mutateAsync: updateScheme} = useUpdateScheme();
  const location = useLocation();
  const isCreating = location.pathname.split('/')[4] === 'create'
  let schemeId = null

  const errorToast = (msg) => enqueueSnackbar(msg, {variant: 'error'})

  const successToast = (msg) => enqueueSnackbar(msg, {variant: 'success'})

  //methods
  const buildFilters = () => {
    let localCollection = _.cloneDeep(targetCollection)
    setDataRegion(localCollection.region || "US");
    setVariables(localCollection.variables);

    let localWeightFields = localCollection.variables
      .filter((d) => d.category === 'weight')
    setWeightFields(
      localWeightFields.length > 0 ? localWeightFields : [defaultWeight]
    )

    let schemeCollectionConfigLocal = scenario.scheme.schemeCollectionConfig ? _.cloneDeep(scenario.scheme.schemeCollectionConfig) : []

    setSchemeCollectionConfig(schemeCollectionConfigLocal)
  }

  const setLocalTargetScheme = () => {
    setSelectedCollection(scheme.dataset);
    setQuestions(_.cloneDeep(scheme.policies) || []);
    setSchemeCollectionConfig(scheme.schemeCollectionConfig)
  };

  const questionDataSize = async (question) => {
    const payload = {
      surveyId: selectedCollection,
      region: dataRegion || "US",
      questionId: question.answerVar,
      audioVariable: question.audioVar || null,
      weight: scheme.weight || defaultWeight,
      slice: question.amountSamples ? question.amountSamples : 5,
      segmentation: {},
      statistics: {},
      similarity_query: {},
      similarity: {},
      pick_closest: process.env.REACT_APP_PICK_CLOSEST_ANSWER === 'true',
      includeIcons: false,
      topics: question.topics,
      customSegments: question.customSegmentations,
      maxClassificationTopics: question.maxClassificationTopics
    }
    return await axiosEngineInstance.post(enginePaths.aggregate_answers + '?head=True', payload, {
      ...status200
    }).catch((err) => {
      console.log("Error fetching data", err);
      return err;
    }).then((res) => {
      const data = res.data;
      return data["Content-Length"];
    });

  }

  const saveTargetScheme = async () => {
    const updatedPolicies = await Promise.all(questions.map(async (policy) => {
      const size = await questionDataSize(policy);
      const policyCopy = {...policy, size};
      if (!policyCopy.classified) {
        policyCopy.classified = false
      }
      return policyCopy;
    }));
    let localTargetScheme = {
      id: scheme && scheme.id ? scheme.id : getRandomId(),
      name: scheme && scheme.name !== '' ? scheme.name : "scheme_" + getRandomId(),
      description: scheme.description,
      dataset: selectedCollection,
      weight: scheme.weight,
      segmentation_variables: scheme.segmentation_variables || [],
      statistic_variables: scheme.statistic_variables || [],
      policies: updatedPolicies,
      nameChange: schemeNameChange,
      schemeCollectionConfig: schemeCollectionConfig,
    };
    schemeId = localTargetScheme.id
    if (schemes.schemes.some((d) => d.id === localTargetScheme.id)) {

      let _schemeChanged = schemeChanged(localTargetScheme, scenario.scheme);
      if (_schemeChanged) {
        return updateScheme({
          user_id: orgId,
          scheme: localTargetScheme,
        });
      } else {
        return await new Promise((resolve) => {
          resolve(true);
        });
      }
    } else {

      return await createScheme({
        user_id: orgId,
        scheme: localTargetScheme,
      });
    }
  }


  const handleGoToChart = async () => {
    setIsLoading(true)
    let errors = []
    if (!validateQuestions(questions)) {
      errorToast('Fill all fields. You need to specify an answer column and at least 2 topics.')
      setIsLoading(false);
      return false
    }
    let classifiedQuestions = await handleComputeClassification();

    for (const question of classifiedQuestions) {
      if (!question.classified) {
        console.log(`Question ${question.name} is not classified`)
        errors.push(`Query ${question.name} is not classified`)
      }
    }
    if (errors.length > 0) {
      for (const error of errors) {
        errorToast(error)
      }
      setIsLoading(false);
      return false
    }

    try {
      const response = await saveTargetScheme();
      if (response) {
        successToast('Scheme Saved')
        const protocol = window.location.protocol
        const domain = window.location.host
        setIsLoading(false)
        window.location.href = `${protocol}//${domain}/orgs/${isWorkspace ? 'workspace' : orgId}/scenarios/${schemeId}/analysis`;
      }
    } catch (e) {
      errorToast('Scheme name is already be taken')

    }
    setIsLoading(false)
  }

  const processQuestion = async (question) => {
    if (question.classified) return question;
    const inferenceMode = ["llm", "vector"].includes(question.inferenceMode) ? question.inferenceMode : "llm";
    const requestId = generateID();
    question.requestId = requestId;

    const payload = {
      collection_name: scheme.dataset,
      answers_column: question.answerVar,
      topics: question.topics,
      model: inferenceMode,
      requestId: requestId,
      saveClassification: true,
      region: dataRegion || "US",
    };

    try {
      await axios.post(apiPaths.compute_async_classification, payload, status200);
    } catch (error) {
      console.error(error);
      question.classified = false;
      errorToast(`Error computing classification for Question: ${question.name}`);
    }
    return question
  };

  const pollClassificationTaskProgress = async (requestId, classifiedQuestions, seenProgress, currentProgress, total_progress) => {
    let allCompleted = true;

    const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
    const response_body = progress_response.data;

    if (!response_body || !response_body.data) {
      return false;
    }

    const policy = classifiedQuestions.find(p => p.requestId === requestId);
    const {message, status, progress} = response_body.data;

    if (status !== "success" && status !== "failed" && status !== 'error') {
      allCompleted = false;
    }

    if (status === 'failed' || status === 'error') {
      policy.classified = false;

    } else if (status === 'success') {
      policy.classified = true;
      const representativity = response_body.data.additionalData?.topic_representativity;
      if (representativity >= 0) {
        for (let topic of policy.topics) {
          topic.representativity = representativity[topic.topic];
          if (topic.subtopics && topic.subtopics.length > 0) {
            let overallRep = 0;
            for (let subtopic of topic.subtopics) {
              let stRepresentativity = representativity[subtopic.topic];
              subtopic.representativity = stRepresentativity;
              overallRep += stRepresentativity;
            }
            topic.representativity = overallRep;
          }

        }
      }
    }

    if (!seenProgress.includes(`${requestId}_${message}:${progress}`)) {
      seenProgress.push(`${requestId}_${message}:${progress}`);
      setProgressMessage(`${policy.name}: ${message}`);
      if (progress) {
        let norm_progress = Math.min(progress, 100)
        const updated_progress = ((currentProgress + norm_progress) / total_progress) * 100;
        setProgress(updated_progress);
      }
    }

    return allCompleted;
  };

  const pollUntilCompleted = (requestId, classifiedQuestions, seenProgress, currentProgress, total_progress) => {
    return new Promise(async (resolve) => {
      if (await pollClassificationTaskProgress(requestId, classifiedQuestions, seenProgress, currentProgress, total_progress)) {
        resolve();
      } else {
        setTimeout(() => {
          pollUntilCompleted(requestId, classifiedQuestions, seenProgress, currentProgress, total_progress).then(resolve);
        }, 1200);
      }
    });
  };

  const validateQuestions = (questions) => {
    const missingSomething = questions.some((x) => {
      return (
        x.answerVar === '' || x?.topics?.length < 2
      );
    });

    if (questions.length === 0 || missingSomething) {
      return false
    }
    return !missingSomething;
  }

  const handleComputeClassification = async () => {
    const classifiedQuestions = [...questions];
    let total_progress = classifiedQuestions.map(policy => policy.classified ? 0 : 1).reduce((acc, currentValue) => {
      return acc + currentValue;
    });
    let policyContribution = 0;
    total_progress *= 100;
    setShowProgressBar(true);
    setProgress(0);
    setIsClassificationLoading(true);
    setTitle("Categorizing your data with topics...");
    const seenProgress = [];
    for (let policy of classifiedQuestions) {
      let processed_policy = await processQuestion(policy);
      if (processed_policy.requestId && !processed_policy.classified) {
        await pollUntilCompleted(processed_policy.requestId, classifiedQuestions, seenProgress, policyContribution, total_progress);
        policyContribution += 100;
        if (policy.classified) {
          successToast("Question successfully classified");
        } else {
          errorToast(`Failed to classify '${policy.name}'`)
        }
      }
    }
    setShowProgressBar(false);
    setProgressMessage('');
    setTitle("");
    setIsClassificationLoading(false);
    handleQuestionsChange(classifiedQuestions);
    return classifiedQuestions;
  }


  const handleSaveScheme = async () => {
    setIsLoading(true)
    let errors = []
    // validate policies
    const validQuestions = validateQuestions(questions);
    if (!validQuestions) {
      setIsLoading(false);
      errorToast("Fill all fields. You need to specify an answer column and at least 2 topics.")
      return false
    }
    setIsLoading(false);
    setShowProgressBar(true);
    let classifiedQuestions = await handleComputeClassification();

    setIsLoading(true);
    handleQuestionsChange(classifiedQuestions);
    for (const question of classifiedQuestions) {
      if (!question.classified) {
        console.log(`Question ${question.name} is not classified`)
        errors.push(`Query ${question.name} is not classified`)
      }
    }
    if (errors.length > 0) {
      for (const error of errors) {
        errorToast(error)
      }
      setIsLoading(false)
      return false;
    }


    try {
      const response = await saveTargetScheme();

      if (response) {
        try {
          setIsLoading(false)
          successToast('Scheme Saved')
        } catch (error) {
          console.log(error)
        }
        if (schemeId && location.pathname.split('/')[4] === 'create') {
          const protocol = window.location.protocol
          const domain = window.location.host
          window.location.href = `${protocol}//${domain}/orgs/${isWorkspace ? 'workspace' : orgId}/scenarios/${schemeId}/specification`;
        }
        return true;
      }
    } catch (e) {
      errorToast('Scheme name is already be taken')
      setIsLoading(false);
    }
    setIsLoading(false);
    return false;
  }


  const handleExportScheme = () => {
    let schemeDataToExport = {
      name: scheme && scheme.name !== '' ? scheme.name : "scheme_" + getRandomId(),
      policies: questions
    }

    const schemeDataToExportJson = JSON.stringify(schemeDataToExport);

    const anchorLinkDonwload = document.createElement('a');
    anchorLinkDonwload.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(schemeDataToExportJson);
    anchorLinkDonwload.download = `${scheme.name}_export.json`;

    anchorLinkDonwload.click();
  }

  React.useEffect(() => {
    setScheme(scenario.scheme)
    dispatch(setTargetCollection(scenario.collection));
    dispatch(setTargetScheme(scenario.scheme));
  }, [scenario.scheme])

  React.useEffect(() => {
    if (targetCollection && Object.keys(targetCollection).length > 0) {
      buildFilters();
    }
  }, [scheme, targetCollection]);

  React.useEffect(() => {
    if (isCreating && targetCollection && Object.keys(targetCollection).length > 0) {
      buildFilters()
    }
  }, [isCreating, targetCollection]);

  React.useEffect(() => {
    if (targetScheme
      && Object.keys(targetScheme).length > 0
      && targetCollection
      && Object.keys(targetCollection).length > 0
    ) {
      setLocalTargetScheme();
      buildFilters();
    }
  }, [targetScheme, targetCollection, scheme]);

  React.useEffect(() => {
    if (scheme && selectedCollection && validateQuestions(questions)) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [scheme, targetCollection, questions]);


  const handleQuestionsChange = (newQuestions) => {
    setQuestions(newQuestions || []);
    if (scheme) {
      const localScheme = _.cloneDeep(scheme);
      localScheme.policies = newQuestions || [];
      setScheme(localScheme);
    }
  }


  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item md={12}>
        <MuiCard>
          <CardContent style={{padding: 0}}>
            <Grid item container spacing={1} justifyContent="center">
              <Box sx={{flexGrow: 1}} style={{maxWidth: '100%'}}>
                {/*Step 1*/}
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography>{t('select_dataset')}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MuiPaper>
                      <GeneralParameters
                        collections={schemes.collections.filter((c) => {
                          const collectionScheme = schemes.schemes.find(s => s.collection === c.collection);
                          return !collectionScheme||collectionScheme?.name===scheme?.name
                        })}
                        scheme={scheme}
                        weightFields={weightFields}
                        isCreating={isCreating}
                        setScheme={setScheme}
                        orgId={orgId}
                        handleExport={handleExportScheme}/>
                    </MuiPaper>
                  </Grid>
                </Grid>
                {(scheme && scheme.dataset !== '') && (
                  <Grid container spacing={2} sx={{marginTop: '20px'}}
                        direction='row'>
                    <Grid item xs={4}>
                      <Typography>{t('filters')}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <SegmentationParameters scheme={scheme}
                                              setScheme={setScheme}
                                              categoricalFields={variables.filter((v) => v.category === 'categorical')}
                                              statisticFields={variables.filter((v) => v.category === 'statistic')}/>
                    </Grid>
                  </Grid>
                )}
                {scheme && scheme.dataset !== '' && (
                  <>
                    <div style={{
                      display: "flex",
                      marginTop: 30,
                      justifyContent: "flex-end"
                    }}/>

                    <div style={{
                      display: "flex",
                      marginTop: 30,
                      justifyContent: "flex-end"
                    }}/>

                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography>{t('survey_questions')}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <OpenQuestions
                          questions={questions}
                          dataRegion={dataRegion}
                          setQuestions={handleQuestionsChange}
                          variables={variables}
                          dataset={scheme.dataset}
                          weight={scheme.weight}
                          handleSaveScheme={saveTargetScheme}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container spacing={1}>
              <Grid item md={8}></Grid>
              <Grid item md={2}>
                <MuiButton
                  data-cy='btn-goto-chart'
                  variant="contained"
                  color="secondary"
                  type="Submit"
                  onClick={(e) => handleSaveScheme(e)}
                  disabled={!enableSave}>
                  {t('generic_save')}
                </MuiButton>
              </Grid>
              <Grid item md={2}>
                <MuiButton
                  data-cy='btn-goto-chart'
                  variant="contained"
                  color="secondary"
                  type="Submit"
                  disabled={!enableSave}
                  onClick={() => handleGoToChart()}>
                  {t('go_to_chart')}
                </MuiButton>
              </Grid>
            </Grid>
          </CardActions>
        </MuiCard>
      </Grid>
    </Grid>
  )
}

export default ConfigurationSections;
