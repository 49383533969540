import * as React from 'react';
import {useEffect, useState} from 'react';
import * as _ from 'lodash';
import {useDispatch, useSelector} from "react-redux";
import {ResponsiveTreeMap} from '@nivo/treemap'
import {setTreeMapFilter, setUpdateQA} from "../../../../../../store/appSlice";
import {Box, Dialog, DialogContent, Typography} from '@mui/material';
import {useContainerDimensions} from "../useContainerDimensions";

import {animated, useSpring} from 'react-spring';
import {useTranslation} from "../../../../../providers/TranslationProvider";
import {useLoading} from "../../../../../providers/LoadingProvider";
import {
  textNoDataStyles, tooltipStyles
} from '../styles/nivoTreeMap';

import {generateScaledPalette} from "../../../../../../utils/colorPaletteUtils";
import {useDebounce} from "../../../../../providers/DebounceContext";
import {CustomLayer} from "./CustomLayer";
import {ArtisticTileLayer} from "../art/ArtisticTileLayer";
import {Album} from "../art/Album";

const NivoTreeMap = ({data, policy, topics, visualizationType}) => {
  const [treeData, setTreeData] = useState({});
  const debouncedData = useDebounce(data, 200);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const debouncedTreeMapFilter = useDebounce(treeMapFilter, 200);
  const [drillDownNode, setDrillDownNode] = useState(null);
  const debouncedDrillDownNode = useDebounce(drillDownNode, 200);
  const dispatch = useDispatch();
  const [containerRef, dimensions] = useContainerDimensions();
  const currentColorSchemeName = useSelector(state => state.app.treeMapColorScheme)
  const [numTopicColors, setNumTopicColors] = useState(policy?.topics?.length);
  const [currentColorScheme, setCurrentColorScheme] = useState(generateScaledPalette(currentColorSchemeName, topics?.length));
  const [policyTopics, setPolicyTopics] = useState(topics);
  const hasAudio = !!policy.audioVar
  const [displayNodes, setDisplayNodes] = useState(new Set());
  const {treeMapLoading, isSearchLoading, isLoading} = useLoading();
  const {t, lng} = useTranslation();
  const INCLUDE_TOOLTIP_ANSWERS = true;
  const [currentAnswerPage, setCurrentAnswerPage] = useState(0);
  const [albumTopic, setAlbumTopic] = useState(null);


  const getTreeMapData = (localData) => {
    const totalWeight = data.reduce((sum, d) => sum + d.weight, 0);
    const totalAnswers = Math.round(data.reduce((sum, d) => sum + d.weight_fraction, 0));
    let localTreeData = null;
    if (localData && localData.length > 0) {
      if (!drillDownNode) {
        localTreeData = getFirstLevelTreeMap(localData, policy.answerVar, totalWeight, totalAnswers)
      } else if ((drillDownNode && drillDownNode.type === "topic" && drillDownNode.hasSubtopics)) {
        localTreeData = getSecondLevelTreeMap(localData, policy.answerVar, totalWeight, totalAnswers);
      } else {
        localTreeData = getThirdLevelTreeMap(localData, totalWeight, totalAnswers);
      }
    }
    return localTreeData;
  }
  const getFirstLevelTreeMap = (localData, answerVar, totalWeight, totalAnswers) => {
    let rootNodeData = {
      "name": "",
      "rootText": `${(!!policy.selectedClassificationSegment) ? `${t('choose_group_heading')} "${policy.selectedClassificationSegment.label}"` : t('all_topics')} ${(policy.maxClassificationTopics || 1) === 1 ? ('(100%)') : ''}`,
      "tooltipText": `${t('answers_provided')}: ${totalAnswers || totalWeight}`,
      "type": "root",
      "rootType": "l0",
      "summary": policy.maxClassificationTopics > 1 ? t('treemap_methodology_mt') : t('treemap_methodology_st'),
      "icon": null,
      "topic": null,
      "image_url": null,
      "children": null,
      "index": 0,
      "color": '#FAFAFA',
    };

    let rootChildren = [];
    let ti = 0;
    for (let topic of policyTopics) {
      let topicLevelChild = {
        "type": "topic",
        "children": null,
        "topic": topic.topic,
        "icon": topic.icon,
        "hasSubtopics": false,
        "image_url": topic.image_url || null,
        "color": topic.color,
        "summary": topic.summary || topic.description,
        "index": ti
      }
      if (topic.subtopics && topic.subtopics.length > 0 && !drillDownNode) {
        let subtopicsData = topic.subtopics.map(subtopic => findTopicInData(subtopic.topic, answerVar, localData))
        let topicWeight = subtopicsData.reduce((sum, d) => sum + (d && d.weight ? d.weight : 0), 0);
        if (topicWeight) {
          let tooltipText = `${topic.topic} ${topicWeight} (${(topicWeight * 100 / totalWeight).toFixed(0)}%)`;
          if (!!policy.selectedClassificationSegment) {
            const tooltipTemplate = _.template(t('tree_segment_tooltip'))
            tooltipText = tooltipTemplate({segment: `(${topic.topic})`})
          }
          let topicRepresentativity = topicWeight * 100 / totalAnswers;
          topicLevelChild.name = `${topic.topic} (${topicRepresentativity.toFixed(0)}%)`;
          topicLevelChild.tooltipText = tooltipText;
          topicLevelChild.value = topicWeight;
          topicLevelChild.hasSubtopics = true;
          topicLevelChild.color = topic.color
          rootChildren.push(topicLevelChild)
        }
      } else {
        let topicData = findTopicInData(topic.topic, answerVar, localData)
        if (topicData) {
          let topicRepresentativity = ((topicData.weight / totalAnswers) * 100).toFixed(0)
          topicLevelChild = Object.assign(topicLevelChild, {
            "name": `${topic.topic} (${topicRepresentativity}%)`,
            "tooltipText": `${topic.topic} ${topicData.weight} (${topicRepresentativity}%)`,
            "value": topicData.weight,
            "color": topic.color,
            "image_url": topic.image_url || null,
            "children": null
          });
          rootChildren.push(topicLevelChild)
        }
      }
      ti++;
    }
    rootNodeData.children = rootChildren
      .sort((a, b) => a.value - b.value)
    return rootNodeData;
  }

  const getSecondLevelTreeMap = (localData, answerVar, totalWeight, totalAnswers) => {
    const parentTopicIndex = policyTopics.findIndex(topic => normalizeString(topic.topic) === normalizeString(drillDownNode.topic));
    let parentTopic = _.cloneDeep(policyTopics[parentTopicIndex])
    const parentTopicColor = parentTopic.color;
    let parentTopicNode = {
      "name": "",
      "type": "parent_topic",
      "topic": parentTopic.topic,
      "icon": parentTopic.icon,
      "summary": parentTopic.summary || parentTopic.description,
      "image_url": parentTopic.image_url || null,
      "rootType": "l1",
      "children": [],
      "index": 0,
      "color": parentTopicColor,
    }
    let rootChildren = [];
    let topicsData = parentTopic.subtopics.map(subtopic => findTopicInData(subtopic.topic, answerVar, localData))
    if (!topicsData || topicsData.length === 0) {
      return null
    }

    let rootWeight = topicsData.reduce((sum, d) => sum + (d && d.weight ? d.weight : 0), 0);
    let rootRepresentativity = rootWeight * 100 / totalAnswers;
    parentTopicNode.tooltipText = `${parentTopic.topic} ${rootWeight} (${rootRepresentativity.toFixed(0)}%)`;
    parentTopicNode.rootText = `${parentTopic.topic} (${rootRepresentativity.toFixed(0)}%)`;

    let ti = 0;
    for (let topic of parentTopic.subtopics) {
      let topicData = topicsData[ti];
      if (topicData) {
        let topicRepresentativity = ((topicData.weight / rootWeight) * 100).toFixed(0)
        let topicLevelChild = {
          "name": `${topic.topic} (${topicRepresentativity}%)`,
          "tooltipText": `${topic.topic} ${topicData.weight} (${topicRepresentativity}%)`,
          "value": topicData.weight,
          "summary": topic.summary || topic.description,
          "type": "topic",
          "topic": topic.topic,
          "icon": topic.icon,
          "image_url": topic.image_url || null,
          "hasSubtopics": false,
          "children": null,
          "index": ti
        };
        rootChildren.push(topicLevelChild)
      }
      ti += 1;
    }
    const subtopics_gradient = generateGradientColors(parentTopicColor, rootChildren.length);
    parentTopicNode.children = rootChildren.sort((a, b) => a.value - b.value).map((child, i) => {
      parentTopic.subtopics[child.index].index = i
      parentTopic.subtopics[child.index].color = subtopics_gradient[i]
      child.index = i
      child.color = subtopics_gradient[i]
      return child;
    });

    parentTopic.subtopics = parentTopic.subtopics.sort((a, b) => a.index - b.index);
    let localPolicyTopics = policyTopics.map(pt => _.cloneDeep(pt))
    localPolicyTopics[parentTopicIndex] = parentTopic;
    setPolicyTopics(localPolicyTopics);
    return {
      "name": "",
      "rootText": `${(!!policy.selectedClassificationSegment) ? `${t('choose_group_heading')} "${policy.selectedClassificationSegment.label}"` : t('all_topics')} (100%)`,
      "tooltipText": `${t('answers_provided')}: ${totalAnswers || totalWeight}`,
      "type": "root",
      "rootType": "l0",
      "summary": policy.maxClassificationTopics > 1 ? t('treemap_methodology_mt') : t('treemap_methodology_st'),
      "topic": null,
      "children": [parentTopicNode],
      "color": '#FAFAFA',
      "index": 0
    };
  }

  const getThirdLevelTreeMap = (local_data, totalWeight, totalAnswers) => {
    /**
     * Generates a third-level analysis map based on the provided data and policy topics.
     *
     * @param {Array} local_data - The local data array.
     * @param {Number} totalWeight - The total weight.
     * @returns {Object} - The generated analysis map object.
     */
    if (!local_data || local_data.length === 0) {
      return null;
    }
    let localDisplayNodes = new Set();
    const topicData = local_data[0];
    const topicName = topicData[`${policy.answerVar}_label`];
    const parentTopic = _.cloneDeep(findSubtopicParent(policyTopics, topicName));
    let parentTopicWeight = totalWeight;
    let policyIcon = policyTopicIcon(policyTopics, topicName);
    let parentTopicData;
    // Distribute the weight among the answers
    const weightDistribution = distributeWeight(topicData.weight, topicData[policy.answerVar].length);
    // Create the answers nodes data
    let answersNodesData = topicData[policy.answerVar].map((answer, index) => ({
      name: answer,
      tooltipText: answer,
      value: weightDistribution[index],
      type: 'answer',
      weight: weightDistribution[index],
      index: index, // default index for no parentTopic
      audio: topicData.audio ? topicData.audio[index] : null,
      image_url: parentTopic?.image_url || null,
      answers: topicData[policy.answerVar],
    })).sort((a, b) => b.value - a.value).map((node, i) => {
      node.index = i
      localDisplayNodes.add(node.name)
      return node
    });
    if (visualizationType === 'art') {
      answersNodesData = [{
        name: "Answers",
        tooltipText: '',
        value: topicData.weight,
        index: 0,
        audio: null,
        type: 'answer',
        image_url: parentTopic?.image_url || null,
        answers: topicData[policy.answerVar],
        audios: topicData.audio ? topicData.audio : null
      }]
    }
    localDisplayNodes.add("Answers");


    // Calculate topic representativity and set the icon and root text
    let topicRepresentativity = (topicData.weight / totalAnswers * 100).toFixed(0);

    let icon = policyIcon || topicData.icon;

    let rootText = `${topicName} (${topicRepresentativity}%)`;

    // If there is a parent topic
    if (parentTopic) {
      let subtopics = parentTopic.subtopics;
      let parentTopicColor = parentTopic.color;
      let subtopicIndex = parentTopic ? parentTopic.subtopics.findIndex(subtopic => normalizeString(subtopic.topic) === normalizeString(topicName)) : null;
      const subtopic = parentTopic.subtopics[subtopicIndex];
      let subTopicColor = subtopic.color;//subtopicGradient[subtopicIndex];
      let subtopicsData = data.filter(d => subtopics.map(s => normalizeString(s.topic)).includes(normalizeString(d[`${policy.answerVar}_label`])));
      // Calculate the parent topic weight based on the added weight of the subtopics
      parentTopicWeight = subtopicsData.reduce((sum, d) => sum + d.weight, 0);
      // Calculate the subtopic representativity within the total weight of its parent
      topicRepresentativity = (topicData.weight / parentTopicWeight * 100).toFixed(0);
      // Adjust the index for having parentTopic and set the number of topic colors
      answersNodesData.forEach(node => node.index += 1); // Adjust index for having parentTopic
      // setNumTopicColors(answersNodesData.length);

      rootText = `${parentTopic.topic} (${(parentTopicWeight * 100 / totalWeight).toFixed(0)}%)`;

      // Create the subtopicNodeData and parentTopicData objects
      let subtopicNodeData = {
        name: '',
        rootText: `${topicName} (${topicRepresentativity}%)`,
        tooltipText: `${topicName}: ${topicData.weight} (${topicRepresentativity}%)`,
        type: 'root',
        rootType: 'l2',
        topic: topicName,
        summary: subtopic.summary || subtopic.description,
        icon: icon,
        children: answersNodesData.map(nodeData => {
          return {
            ...nodeData, color: subTopicColor, image_url: subtopic.image_url
          }
        }),
        hasSubtopics: false,
        color: subTopicColor,
        index: 0
      };
      parentTopicData = {
        name: "",
        rootText: rootText,
        tooltipText: `${parentTopic.topic} (${(parentTopicWeight * 100 / totalWeight).toFixed(0)}%)`,
        type: "root",
        rootType: "l1",
        icon: parentTopic.icon,
        summary: parentTopic.summary || parentTopic.description,
        topic: parentTopic.topic,
        children: [subtopicNodeData],
        hasSubtopics: true,
        color: parentTopicColor,
        index: 0
      };
    } else { // If there is no parent topic
      const parentTopicIdx = policyTopics.map(pt => normalizeString(pt.topic)).indexOf(normalizeString(topicName));
      const parentTopic = policyTopics[parentTopicIdx];
      let parentTopicColor = parentTopic.color;
      parentTopicData = {
        name: '',
        rootText: rootText,
        tooltipText: `${topicName}: ${topicData.weight} (${topicRepresentativity}%)`,
        type: 'root',
        rootType: 'l1',
        topic: topicName,
        summary: parentTopic.summary || parentTopic.description,
        icon: icon,
        children: answersNodesData.map(nodeData => {
          return {
            ...nodeData,
            color: parentTopicColor,
            image_url: parentTopic?.image_url || null
          }
        }),
        hasSubtopics: false,
        color: parentTopicColor,
        index: 0
      };
    }

    const rootNode = {
      name: "",
      rootText: `${(!!policy.selectedClassificationSegment) ? `${t('choose_group_heading')} "${policy.selectedClassificationSegment.label}"` : t('all_topics')} (100%)`,
      tooltipText: `${t('answers_provided')}: ${totalAnswers || totalWeight}`,
      type: "root",
      rootType: "l0",
      "summary": policy.maxClassificationTopics > 1 ? t('treemap_methodology_mt') : t('treemap_methodology_st'),
      icon: null,
      topic: null,
      children: [parentTopicData],
      color: "#FAFAFA",
      index: 0
    };
    setDisplayNodes(localDisplayNodes);
    return rootNode;
  };


  useEffect(() => {
    if (debouncedData) {
      const localTopicColorScheme = generateScaledPalette(currentColorSchemeName, policyTopics.length);
      const sortedPolicyTopics = [...policyTopics].sort((a, b) => a.representativity - b.representativity).map((pt, i) => {
        return {
          ...pt, color: localTopicColorScheme[i]
        };
      });

      setPolicyTopics(sortedPolicyTopics);
      setCurrentColorScheme(localTopicColorScheme);
      setNumTopicColors(policyTopics.length);
    }
  }, [currentColorSchemeName, debouncedData]);

  useEffect(() => {
    if (debouncedData && debouncedDrillDownNode === drillDownNode&&visualizationType==='tree'||visualizationType==='art') {
      if (data && !drillDownNode) {
        setTreeData(getTreeMapData(data));
      } else if (drillDownNode && !isSearchLoading) {
        let filtered_data;
        if (!drillDownNode.hasSubtopics) {
          filtered_data = data.filter((data) => normalizeString(data[policy.answerVar + "_label"]) === normalizeString(drillDownNode.topic))
        } else {
          let policyTopics = policy.topics.find(topic => normalizeString(topic.topic) === normalizeString(drillDownNode.topic));
          let categories = policyTopics?.subtopics?.map(subtopic => normalizeString(subtopic.topic))
          filtered_data = data.filter((data) => categories?.includes(normalizeString(data[policy.answerVar + "_label"])))
        }
        setTreeData(getTreeMapData(filtered_data));
      }
    }

  }, [debouncedDrillDownNode, currentColorScheme,visualizationType, lng]);


  const findTopicInData = (topicName, answerVar, data) => {
    function isTopicInData(dataTopic, policyTopicName) {
      const normalizedDataTopic = normalizeString(dataTopic[answerVar + "_label"])
      const normalizedName = normalizeString(policyTopicName);
      return normalizedDataTopic === normalizedName;
    }

    return data.find((dataTopic) => isTopicInData(dataTopic, topicName))
  }

  const normalizeString = (str) => str.toLowerCase()
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s*-\s*/g, "-");

  const policyTopicIcon = (policyTopics, topicName) => {
    let normTopicName = normalizeString(topicName);
    const topic = policyTopics.find(topic => normalizeString(topic.topic) === normTopicName);
    if (!topic) {
      for (let pTopic of policyTopics) {
        if (pTopic.subtopics) {
          for (let subtopic of pTopic.subtopics) {
            if (normalizeString(subtopic.topic) === normTopicName) {
              return subtopic.icon;
            }
          }
        }
      }
    }

    return topic ? topic.icon : null
  }
  const distributeWeight = (n, k) => {
    const baseValue = Math.floor(n / k);
    const remainder = n % k;
    const result = Array(k).fill(baseValue);

    for (let i = 0; i < remainder; i++) {
      result[i]++;
      result[i] += Math.random();
    }
    return result;
  }

  const findSubtopicParent = (policyTopics, topicName) => {
    for (let parent of policyTopics) {
      if (parent.subtopics && parent.subtopics.length > 0) {
        for (let subtopic of parent.subtopics) {
          if (normalizeString(subtopic.topic) === normalizeString(topicName)) {
            return parent;
          }
        }
      }
    }
    return null;
  }

  function updateTreeMapFilter(local_data) {
    if (local_data && local_data.type === "topic" && !local_data.hasSubtopics) {
      let globalFilter = {...treeMapFilter}
      let policy_filters = {...globalFilter[policy.id]};
      let category = local_data.topic
      if (!policy_filters) {
        policy_filters = {}
        policy_filters[`${policy.answerVar}_label`] = [category];
      } else {
        policy_filters[`${policy.answerVar}_label`] = [category];
      }
      globalFilter[policy.id] = policy_filters;
      dispatch(setTreeMapFilter(globalFilter));
      setDrillDownNode(local_data);
    } else if (local_data && local_data.type === "topic" && local_data.hasSubtopics) {
      let globalFilter = {...treeMapFilter}
      let policy_filters = {...globalFilter[policy.id]};
      let filter_topics = policy.topics.find(topic => topic.topic === local_data.topic)
      let categories = filter_topics?.subtopics?.map(subtopic => subtopic.topic)
      if (!policy_filters) {
        policy_filters = {}
        policy_filters[`${policy.answerVar}_label`] = categories;
      } else {
        policy_filters[`${policy.answerVar}_label`] = categories;
      }
      globalFilter[policy.id] = policy_filters;
      dispatch(setTreeMapFilter(globalFilter));
      setDrillDownNode(local_data);

    } else if (local_data && local_data.type === "root") {
      if (drillDownNode && local_data.topic !== drillDownNode.topic) {
        let globalFilter = {...treeMapFilter}
        let policyFilters = {}
        let localDrillDownNode = null
        if (Object.hasOwn(local_data, "topic")) {
          //
          if (local_data.topic && local_data.hasSubtopics) {
            let parentTopic = policy.topics.find((pTopic) => pTopic.topic === local_data.topic)//findSubtopicParent(policy.topics, local_data.topic);
            policyFilters[`${policy.answerVar}_label`] = parentTopic.subtopics.map(subtopic => subtopic.topic);
            localDrillDownNode = {
              type: "topic", topic: local_data.topic, hasSubtopics: true,

            }
          }
        }
        setDrillDownNode(localDrillDownNode);
        globalFilter[policy.id] = policyFilters;
        dispatch(setTreeMapFilter(globalFilter));
      }

    } else {
      let globalFilter = {...treeMapFilter}
      let policy_filters = {...globalFilter[policy.id]};
      if (policy_filters) {
        policy_filters = null
      }
      globalFilter[policy.id] = policy_filters;
      dispatch(setTreeMapFilter(globalFilter));
    }
    dispatch(setUpdateQA(true));
  }

  function onNodeClick() {
    return (node) => {
      const local_data = node.data;
      if (local_data.type !== 'answer' && local_data.topic !== drillDownNode?.topic) {
        updateTreeMapFilter(local_data);
        setCurrentAnswerPage(0);
      }
    }
  }

  useEffect(() => {
    // console.log("Update on treemap filter change");
    if (debouncedTreeMapFilter) {
      let current_filter = treeMapFilter[policy.id];
      if (current_filter && current_filter[`${policy.answerVar}_label`]) {
        let selected_filters = current_filter[`${policy.answerVar}_label`];
        let categoryToFind = null;
        let hasSubtopics = false
        if (selected_filters && selected_filters.length === 1) {
          categoryToFind = selected_filters[0];
          for (let topic of policy.topics) {
            if (normalizeString(topic.topic) === normalizeString(categoryToFind)) {
              hasSubtopics = topic.subtopics && topic.subtopics.length > 0
              break
            }
          }
        }
        if (selected_filters && selected_filters.length > 0 && !categoryToFind) {
          let policyTopics = policy.topics
          for (let topic of policyTopics) {
            if (topic.subtopics && topic.subtopics.length > 0) {
              if (listsShareCommonElement(selected_filters, topic.subtopics.map(st => st.topic))) {
                categoryToFind = topic.topic
                hasSubtopics = true;
                break
              }
            }
          }
        }

        if (categoryToFind) {
          const updatedNodeData = {
            type: "topic", topic: categoryToFind, hasSubtopics: hasSubtopics
          };
          if (drillDownNode == null || drillDownNode.topic !== updatedNodeData.topic) {
            updateTreeMapFilter(updatedNodeData);
          }

        }
      } else if (drillDownNode) {
        setDrillDownNode(null);
      }
    }
  }, [debouncedTreeMapFilter]);

  function listsShareCommonElement(list1, list2) {
    // Check if any element of list1 is present in list2
    for (const element of list1) {
      if (list2.includes(element)) {
        return true;
      }
    }
    return false;
  }


  function generateGradientColors(baseColor, gradientSteps = 5) {
    let r, g, b;

    // Check if the color is in hexadecimal format
    if (baseColor.startsWith('#')) {
      // Convert hex to RGB
      const hex = baseColor.replace('#', '');
      const bigint = parseInt(hex, 16);
      r = (bigint >> 16) & 255;
      g = (bigint >> 8) & 255;
      b = bigint & 255;
    } else {
      // Otherwise, assume it's in RGB format and extract components
      const match = baseColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      if (!match) {
        throw new Error('Invalid color format');
      }
      [, r, g, b] = match.map(Number);
    }
    const factor = 0.25;
    const adjustFactors = Array.from({length: gradientSteps}, (_, i) => 1 + (i + 1) * factor);

    return adjustFactors.map((adjustFactor) => {
      return `rgb(${Math.min(Math.round(r * adjustFactor), 255)},
                 ${Math.min(Math.round(g * adjustFactor), 255)},
                 ${Math.min(Math.round(b * adjustFactor), 255)})`;
    }).reverse();
  }

  const customColor = (node) => {
    return node.data.type === 'root' || node.data.type === 'parent_topic' ? '#FAFAFA' : node.data.color;

  };


  function getLayer(props) {
    return visualizationType === 'tree' ? < CustomLayer {...props}
                                                        onClick={onNodeClick()}
                                                        policy={policy}
                                                        drillDown={!!drillDownNode}
                                                        hasAudio={hasAudio}
      key={`${policy.id}-layer`}/> :
      <ArtisticTileLayer {...props}
                         onClick={onNodeClick()}
                         policy={policy}
                         drillDown={drillDownNode}
                         hasAudio={hasAudio}
                         currentAnswer={currentAnswerPage}
                         onPageChange={setCurrentAnswerPage}
                         dimensions={dimensions}
                         openAlbumHandler={setAlbumTopic}
                         key={`${policy.id}-layer`}
      />;
  }

  const layers = ["grid", "nodes", props => getLayer(props), 'parents', 'annotations'];
  const displayedNodes = new Set();
  const RoundedTreeNode = ({node}) => {
    const fill = customColor(node);
    // Define the spring-animated properties
    const animatedProps = useSpring({
      to: {
        x: node.x, y: node.y, width: node.width, height: node.height,
      }, from: {
        x: node.x + (node.width / 2),
        y: node.y + (node.height / 2),
        width: 0,
        height: 0,
      }, config: {tension: 130, friction: 40}, // Customize spring config as needed
    });
    if ((!drillDownNode || drillDownNode?.hasSubtopics) && node.data.type === 'answer') {
      return null;
    }
    if (node.data.type === 'answer' && !displayNodes.has(node.data.name) || displayedNodes.has(node.data.name)) {
      return null;
    }

    const isCornerNode = () => {
      const toleranceX = 15;
      const toleranceY = drillDownNode ? 120 : 40;
      const atTop = Math.abs(node.y) <= toleranceY;
      const atBottom = Math.abs((node.y + node.height) - dimensions.height) <= toleranceY;
      const atLeft = Math.abs(node.x) <= toleranceX;
      const atRight = Math.abs((node.x + node.width) - dimensions.width) <= toleranceX;

      return {atTop, atBottom, atLeft, atRight};
    };

    const calculatePath = () => {
      const {atTop, atBottom, atLeft, atRight} = isCornerNode();
      const topRadius = 10; // Radius for corners
      const bottomRadius = topRadius + 10;
      const {x, y, width, height} = node;

      let path = `M ${x + (atLeft && atTop ? topRadius : 0)}, ${y} `; // Start at top left

      // Top right corner
      if (atRight && atTop) {
        path += `H ${x + width - topRadius} Q ${x + width}, ${y} ${x + width}, ${y + topRadius} `;
      } else {
        path += `H ${x + width} `;
      }
      // Bottom right corner

      if (atRight && atBottom) {
        path += `V ${y + height - bottomRadius} Q ${x + width}, ${y + height} ${x + width - bottomRadius}, ${y + height} `;
      } else {
        path += `V ${y + height} `;
      }
      // Bottom left corner
      if (atLeft && atBottom) {
        path += `H ${x + bottomRadius} Q ${x}, ${y + height} ${x}, ${y + height - bottomRadius} `;
      } else {
        path += `H ${x} `;
      }
      // Close path back to start, top left corner
      if (atLeft && atTop) {
        path += `V ${y + topRadius} Q ${x}, ${y} ${x + topRadius}, ${y} `;
      } else {
        path += `V ${y} `;
      }
      path += 'Z'; // Close the path

      return path;
    };
    const path_data = calculatePath();
    return node.data.type === 'topic' || node.data.type === 'answer' ? (
      <animated.path
        d={path_data}
        key={`path-${node.data.index}-${node.x}-${node.y}`}
        onMouseMove={node.onMouseMove}
        onClick={node.onClick}
        onMouseLeave={node.onMouseLeave}
        onMouseEnter={node.onMouseEnter}
        fill={fill}
        stroke={node.borderColor}
        strokeWidth={0.5}
        fillOpacity={1.0}
        cursor={node.data.type !== 'answer' ? 'pointer' : 'default'}
        strokeOpacity={1.0}
        {...animatedProps}
      />) : (<rect
        x={node.x}
        y={node.y}
        width={node.width}
        height={node.height}
        fill={fill}
        onMouseMove={node.onMouseMove}
        onClick={node.onClick}
        onMouseLeave={node.onMouseLeave}
        onMouseEnter={node.onMouseEnter}
        stroke={fill}
        cursor="pointer"
        strokeWidth={0}
      />);
  };

  const ArtTreeNode = ({node}) => {
    const [opacity, setOpacity] = useState(0.5);
    const fill = customColor(node);
    // Define the spring-animated properties
    const animatedProps = useSpring({
      to: {
        x: node.x, y: node.y, width: node.width, height: node.height,
      }, from: {
        x: node.x + (node.width / 2),
        y: node.y + (node.height / 2),
        width: 0,
        height: 0,
      }, config: {tension: 130, friction: 40}, // Customize spring config as needed
    });
    if ((!drillDownNode || drillDownNode?.hasSubtopics) && node.data.type === 'answer') {
      return null;
    }
    if (node.data.type === 'answer' && !displayNodes.has(node.data.name) || displayedNodes.has(node.data.name)) {
      return null;
    }

    const isCornerNode = () => {
      const toleranceX = 15;
      const toleranceY = drillDownNode ? 120 : 40;
      const atTop = Math.abs(node.y) <= toleranceY;
      const atBottom = Math.abs((node.y + node.height) - dimensions.height) <= toleranceY;
      const atLeft = Math.abs(node.x) <= toleranceX;
      const atRight = Math.abs((node.x + node.width) - dimensions.width) <= toleranceX;

      return {atTop, atBottom, atLeft, atRight};
    };

    const calculatePath = () => {
      const {atTop, atBottom, atLeft, atRight} = isCornerNode();
      const topRadius = 10; // Radius for corners
      const bottomRadius = topRadius + 10;
      const {x, y, width, height} = node;

      let path = `M ${x + (atLeft && atTop ? topRadius : 0)}, ${y} `; // Start at top left

      // Top right corner
      if (atRight && atTop) {
        path += `H ${x + width - topRadius} Q ${x + width}, ${y} ${x + width}, ${y + topRadius} `;
      } else {
        path += `H ${x + width} `;
      }
      // Bottom right corner

      if (atRight && atBottom) {
        path += `V ${y + height - bottomRadius} Q ${x + width}, ${y + height} ${x + width - bottomRadius}, ${y + height} `;
      } else {
        path += `V ${y + height} `;
      }
      // Bottom left corner
      if (atLeft && atBottom) {
        path += `H ${x + bottomRadius} Q ${x}, ${y + height} ${x}, ${y + height - bottomRadius} `;
      } else {
        path += `H ${x} `;
      }
      // Close path back to start, top left corner
      if (atLeft && atTop) {
        path += `V ${y + topRadius} Q ${x}, ${y} ${x + topRadius}, ${y} `;
      } else {
        path += `V ${y} `;
      }
      path += 'Z'; // Close the path

      return path;
    };
    const path_data = calculatePath();
    return node.data.type === 'topic' || node.data.type === 'answer' ? (
      <animated.g {...animatedProps}>
        <defs>
          <clipPath id={`clip-path-${node.data.index}`}>
            <path
              d={path_data}
              fill={fill}
            />
          </clipPath>
        </defs>
        {(node.data.image_url) && (<image
            href={node.data.image_url}
            x={node.x}
            y={node.y}
            width={node.width}
            height={node.height}
            preserveAspectRatio="xMidYMid slice"
            clipPath={`url(#clip-path-${node.data.index})`}
          />)}
        <animated.path
          d={path_data}
          key={`path-${node.data.index}-${node.x}-${node.y}`}
          onMouseMove={(evt) => {
            setOpacity(0);
            node.onMouseMove(evt);
          }}
          onClick={node.onClick}
          onMouseLeave={(evt) => {
            setOpacity(0.5);
            node.onMouseLeave(evt);
          }}
          onMouseEnter={(evt) => {
            setOpacity(0);
            node.onMouseEnter(evt);
          }}
          fill={fill}
          stroke={node.borderColor}
          strokeWidth={0.5}
          fillOpacity={node.data.image_url ? (node.data.type !== 'answer' ? opacity : 0.0) : 1.0}
          cursor={node.data.type !== 'answer' ? 'pointer' : 'default'}
          strokeOpacity={1.0}
        />

      </animated.g>) : (<rect
        x={node.x}
        y={node.y}
        width={node.width}
        height={node.height}
        fill={fill}
        onMouseMove={node.onMouseMove}
        onClick={node.onClick}
        onMouseLeave={node.onMouseLeave}
        onMouseEnter={node.onMouseEnter}
        stroke={fill}
        cursor="pointer"
        strokeWidth={0}
      />);
  };


  return (<Box ref={containerRef} width="100%" height="100%" display="flex"
               minWidth="100%" minHeight={"100%"}
               id={'nivo-analysis-map-box'}
               flexDirection="column">
      {(data && treeData && numTopicColors) ? (<>
          <Dialog open={albumTopic !== null}>
            <DialogContent>
              {(albumTopic?.type !== 'answer') ? (<Album
                  image_url={albumTopic?.image_url}
                  title={albumTopic?.topic}
                  description={albumTopic?.summary}
                  descriptionType="topic"
                  onClose={() => setAlbumTopic(null)}
                />) : (<Album
                  image_url={drillDownNode?.image_url}
                  title={drillDownNode?.topic}
                  description={albumTopic?.answers[currentAnswerPage]}
                  descriptionType="answer"
                  onClose={() => setAlbumTopic(null)}
                />)}
            </DialogContent>
          </Dialog>
          <ResponsiveTreeMap
            width={dimensions.width}
            height={dimensions.height}
            tile={'squarify'}
            orientLabel={false}
            label={null}
            data={treeData}
            layers={layers}
            nodeComponent={({node}) => (visualizationType === 'art' && node.data.image_url ?
              <ArtTreeNode node={node}/> : <RoundedTreeNode node={node}/>)}
            identity="name"
            value="value"
            valueFormat=".02s"
            animate={false}
            innerPadding={10}
            margin={{top: 5, right: 10, bottom: 0, left: 1}}
            colors={(node) => customColor(node)}
            labelSkipSize={12}
            labelTextColor={{
              from: 'color', modifiers: [['darker', 3]]
            }}
            parentLabelSize={40}
            parentLabelPosition="top"
            parentLabelTextColor={{
              from: 'color', modifiers: [['darker', 2]]
            }}
            nodeOpacity={0.0}
            borderOpacity={0.0}
            borderWidth={0}
            borderColor={{
              from: 'color', modifiers: [['darker', 0.1],],
            }}
            onClick={onNodeClick()}
            tooltip={({node}) => ((node.data.type !== 'answer' || INCLUDE_TOOLTIP_ANSWERS) && (
                <div style={{
                  maxWidth: dimensions.width / 2,
                  backgroundColor: '#FFFFFF',
                  gap: '0px',
                  padding: 5,
                  borderRadius: '4px',
                  boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.1)',
                  marginTop: '100px',
                }}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {(node.data.type !== 'answer' && node.data.rootType !== 'l0') && (
                      <div style={{
                        ...tooltipStyles, backgroundColor: node.color
                      }}></div>)}
                    <Typography variant={"body1"} sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      fontSize: '16px',
                    }}>{node.data.type === 'answer' && visualizationType === 'art' ? node.data.answers[currentAnswerPage] : node.data.tooltipText}</Typography>
                  </div>
                  {(!!node.data.summary) && (<div>
                      <Typography variant={'body1'} sx={{
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}>{`${node.data.rootType !== 'l0' ? (t('ai_summary_prefix') + ":") : ''} ${node.data.summary}`}</Typography>
                    </div>)}
                </div>))}

          />
        </>) : (<Typography
          variant="h6"
          align="center"
          style={{
            ...textNoDataStyles,
            height: dimensions.height,
            width: dimensions.width
          }}
        >
          {(treeMapLoading[policy.id] || isLoading || isSearchLoading) ? 'Loading data...' : 'No data'}
        </Typography>)}
    </Box>

  )

}
export default NivoTreeMap;
