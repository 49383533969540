import React, {useState} from 'react';
import {Box, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useWheel} from '@use-gesture/react';
import {useSpring, animated} from '@react-spring/web';

export const Album = ({
                        image_url,
                        title,
                        description,
                        descriptionType = 'topic',
                        onClose
                      }) => {
  const [scale, setScale] = useState(1);

  const bind = useWheel(({delta: [, dy]}) => {
    setScale((prev) => Math.min(Math.max(prev - dy / 500, 0.5), 3));
  });

  const style = useSpring({scale});

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        zIndex: 1300, // Ensure it appears above other elements
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: 2,
          color: '#fff',
        }}
      >
        <Typography sx={{
          fontFamily: 'Montserrat',
          fontSize: '18px',
          fontWeight: '600',
          textAlign: 'center',
          width: '100%'
        }}>{title}</Typography>
        <IconButton onClick={onClose}
                    sx={{color: '#fff', position: 'absolute', right: 16}}>
          <CloseIcon/>
        </IconButton>
      </Box>
      <Box
        {...bind()}
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <animated.div
          style={{
            ...style,
            backgroundImage: `url(${image_url})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            filter: 'brightness(70%)',
          }}
        />
      </Box>
      <Box sx={{padding: 2, color: '#fff', width: '100%', textAlign: 'center'}}>
        <Typography sx={{
          width: '100%',
          maxWidth: '80%',
          margin: '0 auto',
          textAlign: 'center',
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontWeight: '400',
          fontStyle: descriptionType === 'topic' ? 'normal' : 'italic'
        }}>{descriptionType === 'topic' ? description : `"${description}"`}</Typography>
      </Box>
    </Box>
  );
};
