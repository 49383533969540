/* eslint-disable no-underscore-dangle */
import React, {useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material';

import HistogramChart from '../d3-charts/Histogram';

import detectTouchScreen from '../utils/detectTouchScreen';
import {useTranslation} from "../../../providers/TranslationProvider";
import {
  MuiGridSelectBar,
  MuiLink
} from '../../styles/similarityHistogramWidgetUI';
import {selectedHistogramTitleStyles} from './styles/SimilarityWidget';


const IS_TOUCH_SCREEN = detectTouchScreen();

// eslint-disable-next-line no-underscore-dangle
function __generateDefaultConfig(
  {
    dataAxis,
    tooltip,
    tooltipFormatter,
    xAxisFormatter = (v) => v.toFixed(0),
    yAxisFormatter = (v) => v.toFixed(0)
  },
  data,
  theme
) {
  return {
    grid: {
      left: theme.spacing(0),
      top: theme.spacing(2),
      right: theme.spacing(0),
      bottom: theme.spacing(3),
    },
    brush: {
      toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
      xAxisIndex: 0,
    },
    toolbox: {
      show: true,
    },
    tooltip: {
      show: tooltip,
      trigger: 'axis',
      padding: [theme.spacing(0.5), theme.spacing(1)],
      textStyle: {
        ...theme.typography.caption,
        fontSize: 12,
        lineHeight: 16,
      },
      backgroundColor: theme.palette,
      position(point, params, dom, rect, size) {
        const position = {top: 0};

        if (size.contentSize[0] < size.viewSize[0] - point[0]) {
          // eslint-disable-next-line prefer-destructuring
          position.left = point[0];
        } else {
          position.right = size.viewSize[0] - point[0];
        }
        return position;
      },
      ...(tooltipFormatter ? {formatter: tooltipFormatter} : {}),
    },
    color: [theme.palette.secondary.main],
    xAxis: {
      type: 'category',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        ...theme.typography,
        padding: [theme.spacing(0.5), 0, 0, 0],
        formatter: (v) => {
          const formatted = xAxisFormatter(v);
          return typeof formatted === 'object'
            ? `${formatted.prefix || ''}${formatted.value}${formatted.suffix || ''}`
            : formatted;
        },
      },
      data: dataAxis,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        margin: 0,
        padding: [
          0,
          0,
          theme.typography.fontSize * theme.typography.lineHeight + 4,
          0,
        ],
        show: true,
        showMaxLabel: true,
        showMinLabel: false,
        inside: true,
        color: (value) => {
          // FIXME: Workaround to show only maxlabel
          let col = 'transparent';
          const maxValue = Math.max(...data.map((d) => d || Number.MIN_SAFE_INTEGER));
          if (value > maxValue) {
            col = theme.palette;
          }

          return col;
        },
        ...theme.typography,
        formatter: (v) => {
          const formatted = yAxisFormatter(v);
          return typeof formatted === 'object'
            ? `${formatted.prefix}${formatted.value}${formatted.suffix || ''}`
            : formatted;
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        onZero: false,
        lineStyle: {
          color: theme.palette,
        },
      },
    },
  };
}

// eslint-disable-next-line no-unused-vars
function __generateSerie(name, data, selectedBars = [], theme) {
  return [
    {
      type: 'bar',
      name,
      colorBy: 'data',
      data: data.map((value) => {
        const bar = {
          value: value,
          color: theme.palette.secondary.main,
        };
        return bar;
      }),
      barCategoryGap: 1,
      barMinWidth: '95%',
      ...(!IS_TOUCH_SCREEN && theme
        ? {
          emphasis: {
            itemStyle: {
              color: theme.palette.secondary.dark,
            },
          },
        }
        : {}),
    },
  ];
}

function SimilarityHistogramWidgetUI(props) {
  const theme = useTheme();
  const {
    id,
    name,
    data = [],
    dataAxis,
    onSelectedBarsChange,
    selectedBars,
    tooltip,
    tooltipFormatter,
    xAxisFormatter,
    yAxisFormatter,
    color,
    query,
    answerCount,
    isLoading,
  } = props;

  const {t} = useTranslation();
  const chartInstance = useRef();

  const options = useMemo(() => {
    const config = __generateDefaultConfig(
      {dataAxis, tooltip, tooltipFormatter, xAxisFormatter, yAxisFormatter},
      data,
      theme
    );
    const series = __generateSerie(name, data, selectedBars, theme);
    return {...config, series};
  }, [
    data,
    dataAxis,
    name,
    theme,
    tooltip,
    tooltipFormatter,
    xAxisFormatter,
    yAxisFormatter,
    selectedBars,
  ]);


  const brushEvent = (params) => {
    if (onSelectedBarsChange) {
      onSelectedBarsChange(params);
    }
  };


  useEffect(() => {
    if (!query) {
      clearBars();
    }
  }, [query]);


  const onEvents = {
    click: brushEvent,
    brushSelected: brushEvent,
  };
  const clearBars = () => {
    if (chartInstance) {
      const chart = chartInstance.current;

      onSelectedBarsChange({bars: []});
      if (chart && typeof chart.clear === 'function') {
        chart.clear();
      }
    }
  };


  return (
    (data?.length > 0&&query) ? (
      <div>
        <MuiGridSelectBar
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{
            padding: "0px 0px 0px 0px",
            height: data?.length > 0 ? "fit-content" : "0px"
          }}
        >
          {(data && data.length > 0&&query) && (
            <Typography variant='caption' style={selectedHistogramTitleStyles}>
              {selectedBars && selectedBars.length ? `${answerCount} ${t('answers').toLowerCase()} ` : t('all')}
              {' '}
              {t('selected')}
            </Typography>
          )
          }
          {(selectedBars && selectedBars.length > 0 && data && data.length > 0) && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <MuiLink style={{fontSize: "12px"}}
                     onClick={() => !isLoading ? clearBars() : {}}>
              Clear
            </MuiLink>
          )}
        </MuiGridSelectBar>
        {(data && data.length > 0&&query) && (
          <HistogramChart
            ref={chartInstance}
            id={id}
            selectedBars={selectedBars}
            option={options}
            onEvents={onEvents}
            height={100}
            color={color}
            filterable={true}
            xFormat={`.0f`}
            style={{maxWidth: '100%', width: '100%'}}
          />

        )}
        {(data && data.length > 0&&query) && (
          <>
            <Grid container direction={'row'} alignItems={'center'}
                  justifyContent={'center'}>
              <Typography variant="caption" style={{fontSize: "12px",fontFamily:'Montserrat'}}>
                {t('similarity_score')}
              </Typography>
            </Grid>
          </>
        )}
      </div>
    ):null
  );
}

SimilarityHistogramWidgetUI.defaultProps = {
  tooltip: true,
  dataAxis: [],
  name: null,
  onSelectedBarsChange: null,
};

SimilarityHistogramWidgetUI.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  tooltip: PropTypes.bool,
  tooltipFormatter: PropTypes.func,
  xAxisFormatter: PropTypes.func,
  yAxisFormatter: PropTypes.func,
  dataAxis: PropTypes.array,
  name: PropTypes.string,
  onSelectedBarsChange: PropTypes.func,
  selectedBars: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/require-default-props
  height: PropTypes.number,
  filterable: PropTypes.bool,

};

export default SimilarityHistogramWidgetUI;
