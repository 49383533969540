import {Grid, styled, Tab, Tabs, TextField} from '@mui/material';

export const MuiTabsInsights = styled(Tabs)(({theme}) => ({
  padding: theme.spacing(0),
  marginTop: theme.spacing(0),
  marginLeft: theme.spacing(1),
  borderBottom: '1px solid',
  scrollableX: true,
  borderColor: theme.palette.secondary.main,
  '& .Mui-selected': {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    color: theme.palette.primary.main,
    transition: 'font-size 10ms, font-weight 10ms',
  },
  '& .MuiTabs-indicator': {
    height: 3,
    width: '0',
    borderRadius: '10px 10px 0 0',
    transition: 'width 0.3s',
    marginBottom: theme.spacing(0)
  },
  '& .MuiTabs-indicator[data-indicator-color="secondary"]': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const MuiTab = styled(Tab)(({theme}) => ({
  minWidth: 'auto',
  padding: theme.spacing(0),
  marginLeft: theme.spacing(3),
}));

export const MuiTextField = styled(TextField)(({theme}) => ({
  marginBottom: theme.spacing(0),
  fontSize: '14px',
  fontFamily: 'Montserrat',
  marginLeft: '1px',
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '22px',
    minHeight: '42px',
    height: '42px',
    padding: '10px 10px 10px 38px',
    boxSizing: 'border-box', // Ensures border and padding are included in the element's total height and width
  },
  '& .MuiInputBase-inputMultiline': {
    padding: "0px",
    fontSize: '16px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.005em',
    boxSizing: 'border-box', // Ensures border and padding are included in the element's total height and width
    color: '#143440',
    '::placeholder': {
      color: '#143440',
      opacity: 1,
      fontSize: '16px',
      letterSpacing: '0.005em',
      fontFamily: 'Montserrat',
    }
  },
  '& input': {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    padding: '0px',
    letterSpacing: '0.005em',
    boxSizing: 'border-box', // Ensures border and padding are included in the element's total height and width
    color: '#143440',
    '::placeholder': {
      color: '#143440',
      opacity: 1,
      fontSize: '16px',
      letterSpacing: '0.005em',
      fontFamily: 'Montserrat',
    }
  }
}));


export const MuiQAGrid = styled(Grid)(({theme, historyMaxHeight}) => ({
  width: '100%',
  minHeight: "200px",
  overflowY: 'auto'
}));

export const divTabStyle = {
  display: 'flex',
  alignItems: 'center'
}
export const titleTabStyle = {
  fontSize: '14px',
  fontFamily: 'Montserrat',
  fontWeight: 600
}

export const selectedHistogramTitleStyles = {
  color: '#424242',
  fontFamily: 'Montserrat',
  fontSize: '12px',
};

export const containerSimilarityStyles = {
  width: '100%',
  minHeight: '0px',
  height: 'fit-content',
};

export const dividerStyles = {
  width: '100%',
  marginTop: '16px',
  marginBottom: "16px"
};
