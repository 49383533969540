import {useMutation, useQueryClient} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from 'api/apiPaths';

const deleteDataset = async (data) => {
  const { name, user_id } = data;
  await axios
    .post(apiPaths.delete_scenario, { collections: [name], user_id: user_id }, status200)
    .then((resp) => resp.data);
};

const useDeleteDataset = () => {
  const queryClient = useQueryClient();
  return useMutation(async (values) => await deleteDataset(values), {
    onSuccess: () => {
      queryClient.resetQueries(['schemes']);
    },
    onError: (error) => {
      console.log(error);
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['schemes']);
    },
  });
};

export default useDeleteDataset;
