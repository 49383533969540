import {useEffect, useState} from "react";
import {MuiPaper} from "../styles/question";
import {
  Autocomplete,
  FormControl,
  Grid,
  MenuItem,
  TextField
} from "@mui/material";
import * as React from "react";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";

const QuestionInfo = ({question, variables, handleQuestionChange}) => {

  const [answerVars, setAnswerVars] = useState([]);
  const [audioVars, setAudioVars] = useState([]);
  const {t} = useTranslation();

  const initialize = () => {
    setAnswerVars(variables.filter(d => d.category === 'answer'));
    setAudioVars(variables.filter(d => d.category === 'audio'));
  }

  useEffect(() => {
    initialize();
  }, [variables, question]);


  return (
    <Grid item xs={12}>
      <MuiPaper>
        <FormControl fullWidth variant="outlined">
          <TextField
            label={"Question short name"}
            id="short_name"
            defaultValue={question.name || ''}
            placeholder={"Short name to identify the survey question"}
            variant="outlined"
            onChange={(e) => {
              question.name = e.target.value
              handleQuestionChange(question)
            }}
            inputProps={{maxLength: 80}}
          />
        </FormControl>
      </MuiPaper>
      <MuiPaper>
        <FormControl fullWidth variant="outlined">
          <TextField
            label={"Original question text"}
            placeholder={"Original question text"}
            defaultValue={question.longName || ''}
            id="long_name"
            variant="outlined"
            onChange={(e) => {
              question.longName = e.target.value
              handleQuestionChange(question)
            }}
            inputProps={{maxLength: 80}}
          />
        </FormControl>
      </MuiPaper>
      <MuiPaper>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <Autocomplete

                value={answerVars.find((av) => av.propName === question.answerVar) || {
                  'propName': '',
                  'label': ''
                }}
                disableClearable
                onChange={(e, newValue) => {
                  if (newValue) {
                    let localQuestion = {...question};
                    localQuestion.answerVar = newValue.propName;
                    localQuestion.classified = false;
                    localQuestion.topics = [];
                    handleQuestionChange(localQuestion);
                  }
                }}
                renderInput={(params) => <TextField {...params} label={question.answerVar?t('answer_variable_placeholder'):"Quotations variable"} />}
                options={answerVars}
                renderOption={(props, option) => {
                  return <MenuItem value={option.propName}
                                   key={option.propName} {...props}>{option.label}</MenuItem>
                }}
                getOptionLabel={(option) => option ? (option.label || option.propName) : ''}
                isOptionEqualToValue={(option, value) => option?.propName === value?.propName}
                variant="outlined"
                id="answer_variable_selection"
              />
            </FormControl>
          </Grid>
        </Grid>
      </MuiPaper>
      <MuiPaper>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <Autocomplete

                value={audioVars.find(av => av.propName === question.audioVar) || {
                  propName: '',
                  label: 'None'
                }}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  let localQuestion={...question};
                  localQuestion.audioVar = newValue?.propName || '';
                  handleQuestionChange(localQuestion);
                }}
                renderInput={(params) => <TextField {...params} label={(question.audioVar?t('audio_variable_placeholder'): 'Audio variable')} />}
                options={audioVars}
                renderOption={(props, option) => {
                  return <MenuItem value={option.propName}
                                   key={option.propName} {...props}>{option.label}</MenuItem>
                }}
                getOptionLabel={(option) => option ? (option.label || option.propName) : 'None'}
                isOptionEqualToValue={(option, value) => option?.propName === value?.propName}
                variant="outlined"
                id="answer_audio_variable_selection"/>
            </FormControl>
          </Grid>
        </Grid>
      </MuiPaper>
    </Grid>
  );
}

export default QuestionInfo;
