import axios from "../../../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../../api/status.utils";
import {useLoading} from "../../../../../../../providers/LoadingProvider";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import {useSnackbar} from "notistack";
import {Button, Grid, Popover, Slider, Typography} from "@mui/material";
import {useState} from "react";

export const SubtopicExtraction = ({
                                     parentTopic,
                                     parentTopicIndex,
                                     handleTopicChange,
                                     answerVariable,
                                     dataset,
                                     weightVariable,
                                     dataRegion,
                                     open,
                                     closeModal
                                   }) => {

  const {enqueueSnackbar} = useSnackbar();
  const [amountSubtopics, setAmountSubtopics] = useState(3)
  const {
    setIsLoading,
    setTitle
  } = useLoading();

  const {lng} = useTranslation();

  const handleInferSubTopics = async () => {
    if (!dataset || !answerVariable || !parentTopic){
      console.log("Not submitting","dataset",dataset,"answerVariable",answerVariable,"parentTopic",parentTopic)
      return null;
    }
    setIsLoading(true);
    setTitle(`Extracting subtopics for "${parentTopic.topic}"...`)
    const payload = {
      collection_name: dataset,
      answers_column: answerVariable,
      max_topics: amountSubtopics,
      weight: weightVariable,
      target_lang: lng,
      parent_topic: parentTopic.topic,
      requestId: null,
      region: dataRegion || "US"
    }
    try {
      let response = await axios.post(apiPaths.infer_subtopics, payload, status200);
      setIsLoading(false);
      let data = response.data;
      let subtopics = null
      if (data && data.topics) {
        subtopics = data.topics.map(subtopic => {
          let st = {...subtopic};
          st.isParent = false;
          st.parent = parentTopic;
          return st;
        });
        successToast("Subtopics extracted...");
      } else {
        errorToast("Error extracting subtopics")
      }
      if (subtopics?.length > 0) {
        let localParent = {...parentTopic};
        localParent.subtopics = subtopics;
        handleTopicChange(localParent, parentTopicIndex)
        closeModal()
      }

      return subtopics;
    } catch (error) {
      console.log(error)
      errorToast(`Error extracting subtopics for topic: ${parentTopic}`);
    }
    // await handleSaveScheme();
    setIsLoading(false);
    return null;
  }

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const successToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'success'})
  }

  return (
    <Popover
      anchorOrigin={
        {
          'vertical':'center',
          'horizontal':'center'
        }
      }
      open={open}
      onClose={closeModal}
    >
      <div style={{position: 'relative', height: '100px', padding: '20px'}}>
        <Grid container direction={'column'} alignItems='center'>
          <Grid container item direction="row" spacing={2}>
            <Grid item>
              <Typography>Amount of subtopics:</Typography>
            </Grid>
            <Grid item>
              <Slider
                value={amountSubtopics}
                min={3}
                max={10}
                step={1}
                marks
                onChange={(evt, value) => setAmountSubtopics(value)}
                sx={{width: '100px'}}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button onClick={() => {
              handleInferSubTopics();

            }}>Submit</Button>
          </Grid>
        </Grid>
      </div>
    </Popover>
  )
}
