import { styled, TextField, Typography, Slider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const MuiSearchIcon = styled(SearchIcon)(({ theme }) => ({
  borderRadius: '50%',
  color: 'white',
  background:theme.palette.primary.main,
}));

export const MuiTypography= styled(Typography)(({ theme }) => ({
  color:'#212121',
  fontSize: '11px',
  fontFamily:'Montserrat',
}));

export const MuiTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(-0.5),
  marginBottom: theme.spacing(5),
  marginLeft:'15px',
  '& .MuiInputBase-root': {
    height: '45px',
    paddingRight: '5px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius:'5px'
  },
  '& input::placeholder': {
    fontSize: '11px',
    fontFamily:'Montserrat',
  },
}));

export const MuiSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: '12px',
  '& .MuiSlider-valueLabel': {
    width: 32,
    height: 23,
    borderRadius: '20% 20% 20% 20%',
    backgroundColor: '#143440',
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(0%, -85%) scale(1)',
    },
  },
}));

export const popoverStyles = {
  borderRadius: '10px',
  paddingLeft: '15px',
  paddingRight: '20px',
  overflowY: 'hidden',
};

export const iconButtonStyles = {
  marginLeft:'15px'
};

export const boxStyles = {
  width: 150,
  marginTop:'10px'
};

export const gridContainerStyles = {
  marginTop:'18px',
  marginLeft:'15px'
};

export const iconCloseStyles = {
  marginBottom:'5px',
  marginLeft:'10px'
};
