import React, { createContext, useContext, useState } from 'react';

const AudioControlContext = createContext();

export function useAudioControl() {
  return useContext(AudioControlContext);
}

export const AudioControlProvider = ({ children }) => {
  const [playingAudioId, setPlayingAudioId] = useState(null);

  const playAudio = (id) => {
    if (playingAudioId !== id) {
      setPlayingAudioId(id);
    }
  };

  const value = {
    playingAudioId,
    playAudio,
  };

  return (
    <AudioControlContext.Provider value={value}>
      {children}
    </AudioControlContext.Provider>
  );
};
