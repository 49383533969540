import {status200} from './status.utils';
import axios from './axios/axiosInstance';
import apiPaths from './apiPaths';

const resetPassword = (data, onError, onSuccess) =>
  axios
    .post(
      `${apiPaths.reset_password}${data.token}`,
      { password: data.password, token: data.token },
      status200
    )
    .then((resp) => onSuccess(resp.data))
    .catch((e) => onError(e.error));

export default resetPassword;
