import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

import {usePermissions} from 'components/hooks/usePermissions';
import {
  divActionStyle,
  gridNameStyle,
  MuiCardContent,
  MuiCardGrid,
  MuiCardMedia,
  MuiCardRoot,
  MuiIconButtonDelete,
  MuiIconButtonEdit,
  MuiLink,
  MuiTypography
} from './styles/card';
import {CardMedia, Grid} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';


const CardOrganization = ({ organization }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();

  return (
    <>
      <MuiCardRoot elevation={0}>
        <CardMedia>
          <MuiLink
            to={`/orgs/${organization._id}/scenarios`}
            data-cy={organization.name}
          >
            <MuiCardMedia
              image={organization.attrs.profile_img_url}
              title={organization.name}
            />
          </MuiLink>
        </CardMedia>
        <MuiCardContent>
          <Grid container item direction={'row'} justifyContent='space-between'>
            <Grid item xs={9} style={gridNameStyle}>
              <MuiTypography variant='subtitle1' component='h2'>
                {organization.name}
              </MuiTypography>
            </Grid>
            <MuiCardGrid item xs={3}
                         hasDeletePermission={hasPermissionFor('delete_org')}
                         >
              <div style={divActionStyle}>
                {hasPermissionFor('update_org') && (
                  <MuiIconButtonEdit
                    onClick={() =>
                      navigate(`${location.pathname}?edit=${organization._id}`, {
                        replace: true,
                        state: organization
                      })
                    }
                    color='primary'
                    data-cy={`edit_button_org_${organization.name}`}
                  >
                    <EditIcon fontSize='small' />
                  </MuiIconButtonEdit>
                )}
                {hasPermissionFor('delete_org') && (
                  <MuiIconButtonDelete
                    onClick={() =>
                      navigate(`${location.pathname}?delete=${organization._id}`, {
                        replace: true,
                        state: organization
                      })
                    }
                    style={{ color: '#ff1744' }}
                    data-cy={`delete_card_${organization._id}`}
                  >
                    <DeleteOutlinedIcon fontSize='small' />
                  </MuiIconButtonDelete>
                )}
              </div>
            </MuiCardGrid>
          </Grid>
        </MuiCardContent>
      </MuiCardRoot>
    </>
  );
};

export default CardOrganization;
