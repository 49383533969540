import {Autocomplete, styled} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import * as React from "react";

export const MuiAutocomplete = styled(
  (props) => (
    <Autocomplete
      {...props}
      multiple
      disableCloseOnSelect
      getOptionLabel={(option) => option.label || option.propName}
      isOptionEqualToValue={(option, value) => option.propName === value.propName}
      filterSelectedOptions
      ChipProps={{
        deleteIcon: (<CancelIcon style={{color: '#fd7676'}}/>)
      }}
      renderInput={props.renderInput}
    />
  )
)(({theme}) => ({
  '& .MuiInputBase-root': {
    height: 'auto',
    width: '100%',
    minHeight: 'auto',
    display: 'flex',
    flexWrap: 'wrap'
  },
}));
