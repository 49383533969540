import {useQuery} from 'react-query';

import {status200} from '../../api/status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchOrganizations = () => axios.get(apiPaths.orgs, status200).then((resp) => resp.data);

const useOrganizations = () => useQuery('organizations', fetchOrganizations);

export default useOrganizations;
