import * as React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Alert,
  AppBar,
  Box,
  Button,
  Button as MuiButton,
  Card,
  CardActionArea,
  CircularProgress,
  Grid, MenuItem,
  Paper,
  Radio,
  Tab,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField as MuiTextField,
  ThemeProvider,
  Typography
} from '@mui/material';

import Modal from 'components/common/Modal';
import {
  MuiContainer,
  MuiDescriptionIcon,
  MuiGridInputText,
  MuiLinkIcon
} from '../styles/formModal';
import themeCrowdView from 'theme/crowdView';
import {SelectField, TextField} from 'components/common/ReactHooksFormFields';
import {useTranslation} from 'components/providers/TranslationProvider';
import Toast from 'components/common/Toast';
import PropTypes from 'prop-types';
import {
  getFilters,
  getImportJob,
  uploadCollectionS3,
} from '../../../../api/uploadCollection';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../providers/AuthProvider';
import useCreateScheme from '../../../../api/hooks/useCreateScheme';
import axios from '../../../../api/axios/axiosInstance';

import {useSnackbar} from 'notistack';
import useOrganization from "../../../../api/hooks/useOrganization";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const allowedURLPatterns = [
  /https?:\/\/drive\.google\.com\/.+/i,  // URL de Drive
  /^s3:\/\/.*$/, // URL de S3 de Amazon
  /https?:\/\/(?:www\.)?.+/i,            // URL pública
];

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{padding: 0}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const FormModal = ({ open, onClose}) => {
  const REGIONS=['US','SA'];
  const [value, setValue] = React.useState(0);
  const [uploadOption, setUploadOption] = React.useState('image_file');
  const [showContent, setShowContent] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const [url, setUrl] = React.useState('');
  const [variables, setVariables] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [uploadMessages, setUploadMessages] = React.useState([]);
  const [uploadErrors, setUploadErrors] = React.useState([]);
  const [urlCollectionName, setUrlCollectionName] = React.useState('');
  // const [sectionMap, setSectionMap] = React.useState([]);
  const location = useLocation();
  const {user} = useAuth();
  const userId =
    location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const {data:organization}=useOrganization(location.pathname.split('/')[2] === 'workspace' ?user.org_id:userId);
  const [region,setRegion]=React.useState(organization?.defaultRegion||'US')
  // const [field, setField] = React.useState(null);
  const {mutateAsync: createScheme} = useCreateScheme();
  const {t} = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  // const [openMore, setOpenMore] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar()
  let varsConfig = null

  const schema = yup.object().shape({
    file: yup.mixed(),
    url: yup.string().when([], {
      is: () => uploadOption === 'url',
      then: yup.string().required('url_is_required'),
      otherwise: yup.string().notRequired(),
    }),
    datasetName: yup.string().required('dataset_name_required'),
    region: yup.string().oneOf(REGIONS).required('region_required'),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: {errors},
    reset,
    getValues,
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  let handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let handleOnClickUploadOption = (option) => {
    setVariables(null);
    reset({
      datasetName: '',
      region: organization?.defaultRegion||'US',
      file: '',
      url: ''
    });
    setUploadOption(option);
    setShowContent(true);
  };

  const handleUrlChange = (event) => {
    const inputUrlValue = event.target.value;
    // Validar la URL
    let isValid = false;
    for (const pattern of allowedURLPatterns) {
      if (pattern.test(inputUrlValue)) {
        isValid = true;
        break;
      }
    }
    // Establecer el error si la URL no es válida
    if (!isValid) {
      enqueueSnackbar('La URL no es válida', {variant: 'error'})
    } else {
      setUrl(inputUrlValue);
      methods.setValue('url', inputUrlValue)
    }
  };


  const handleFilesChange = (event) => {
    const files = event.target.files;
    console.log(files);
    let notValid = false;
    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name
      if (!fileName.endsWith('.json') && !fileName.endsWith('.csv') && !fileName.endsWith('.parquet')) {
        notValid = true;
        break;
      }
    }
    if (notValid) {
      enqueueSnackbar('Solo se aceptan archivos JSON, CSV o PARQUET', {variant: 'error'})
    } else {
      setUploadedFiles([...files]);
    }

  };

  const getDatasetVariables = async (collectionName, importJobId,region) => {
    try {
      let axiosResp = await axios.get(
        `/collection/get-dataset-variables?datasetName=${collectionName}&idImportedJob=${importJobId}&region=${region}`
      );

      if (axiosResp && axiosResp.status === 200) {
        let response = axiosResp.data;
        let currentVariables = null;

        if (response.status === 'ok') {
          currentVariables = Object.entries(response.data).map((v) => {
            let category = 'ignore'
            if (v[1].label?.toLowerCase().includes('weight') || v[1].propName?.toLowerCase().includes('weight')) {
              category = 'weight'
            }
            return {
              label: v[1].label,
              propName: v[1].propName,
              category: category,
              type: v[1].type
            };
          });
          setVariables(currentVariables);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileUploadFromS3 = async (formData) => {
    setUploading(true);
    let importJobId;

    if (getValues('datasetName')) {
      const data = {
        user_id: userId,
        datasetName: [userId, getValues('datasetName').trim()].join('_'),
        s3Info: {
          url: formData.url.trim(),
        },
        region: getValues('region'),
      };

      if (varsConfig) {
        data.variables = varsConfig
        data.datasetName = getValues('datasetName')
      }

      uploadCollectionS3(data).then((res) => {
        if (res && res.status === 200) {
          if (!varsConfig) {
            setUploadMessages([t('starting_file_process')]);
            importJobId = res.data.data;
            let isFirstTimeQueryingJobStatus = true;

            // To control failed requests.
            let intervalMaxFailedRequest = 10;
            let intervalFailedRequestCount = 0;

            // Periodically query import job status.
            let intervalHandler = setInterval(() => {
              if (intervalFailedRequestCount === intervalMaxFailedRequest) {
                setUploading(false);
                clearInterval(intervalHandler);
              }

              getImportJob(importJobId)
                .then(async (res) => {
                  if (res && res.status === 200) {
                    if (isFirstTimeQueryingJobStatus) {
                      setUploadMessages([]);
                      isFirstTimeQueryingJobStatus = false;
                    }

                    let importJob = res.data.data;

                    if (importJob.status === 'in_progress') {
                      setUploadMessages([t('importing')]);
                    }

                    if (importJob.status === 'error') {
                      setUploadMessages([]);
                      setUploadErrors(importJob.jobErrors);
                      setUploading(false);
                      clearInterval(intervalHandler);
                    }

                    if (importJob.status === 'completed' || importJob.status === 'success' || importJob.status === 'warning') {
                      setUploading(false);
                      setUploadMessages([t('import_complete')]);
                      clearInterval(intervalHandler);

                      // Get the dataset variables from the backend.
                      await getDatasetVariables(importJob.collectionName, importJobId,importJob.region);

                      setUrlCollectionName(importJob.collectionName);
                      setRegion(importJob.region)
                      setValue(1);
                    }
                  } else {
                    intervalFailedRequestCount++;
                  }
                })
                .catch((error) => {
                  console.error(error);
                  intervalFailedRequestCount++;
                });
            }, 2000);
          } else {
            if (res.data.status === 'error') {
              enqueueSnackbar(res.data.msg, {variant: 'error'})
            } else {
              enqueueSnackbar('Import of dataset with configuration completed', {variant: 'success'})
              setUploading(false);
              onClose();
            }
          }
        }
      });
    }
  };

  const handleUpload = handleSubmit(async (data) => {
    setUploading(true);
    const datasetName = getValues('datasetName');
    if (datasetName) {
      if (uploadOption === 'image_file') {
        let file = uploadedFiles[0];
        let formData = new FormData();
        formData.append('image_file', file);
        let axiosResp = await axios.post(`${process.env.REACT_APP_API_URL}/collection/upload`, formData).then((resp) => resp.data);
        if (axiosResp) {
          let dataUrl = {
            url: axiosResp.file
          };
          await handleFileUploadFromS3(dataUrl);
        }
      }
      if (uploadOption === 'url') {
        await handleFileUploadFromS3(data);
      }
    }
  });

  const changeVariableLabel = (data, index, value) => {
    let newVariables = variables.map((v) => {
      if (v.propName === data.propName) {
        v.label = value;
      }
      return v;
    });

    setVariables(newVariables);
  };

  const changeVariables = (data, index, value) => {
    let newVariables = variables.map((v) => {
      if (v.propName === data.propName) {
        v.category = value;
      }

      return v;
    });
    // let new_categorical = [];
    // let definedVars = new Set();
    // for (let i = 0; i < newVariables.length; i++) {
    //   definedVars.add(newVariables[i].propName);
    // if (newVariables[i].category === 'answer') {
    //   let v = JSON.parse(JSON.stringify(newVariables[i]));
    //   v.propName = v.propName + "_label";
    //   // v.label = v.label
    //   v.category = "categorical";
    //   new_categorical.push(v)
    // }
    // }

    // for (let i = 0; i < new_categorical.length; i++) {
    //   if (!definedVars.has(new_categorical[i].propName)) {
    //     newVariables.push(new_categorical[i]);
    //   }
    // }
    setVariables(newVariables);
  };

  const getAllFilters = (filterVars) => {
    return Promise.all(
      filterVars.map((v) => {
        return getFilters({
          filter: v,
          collection: urlCollectionName,
          region: region,
        });
      })
    ).then((res) => {
      let filters = [];

      res
        .map((d) => d.data)
        .forEach((r, i) => {
          let v = filterVars[i];

          if (v.category === 'categorical') {
            let groups = r && r.length > 0 ? r.map((d) => d._id) : [];
            filters.push({
              type: 'categorical',
              text: v.label || v.propName,
              value: v.propName,
              values: groups,
            });
          }
        });

      return filters;
    });
  };

  const onSave = async () => {
    let filterVars = variables.filter((d) => d.category !== 'ignore');

    if (filterVars.length === 0) {
      setError(t('no_variables_warning'));
      setOpenToast(true);
      return;
    }

    let filters = await getAllFilters(
      filterVars.filter((d) => d.category === 'categorical')
    );

    var payload = {
      collection: {
        name: getValues('datasetName'),
        collection: urlCollectionName,
        variables: filterVars,
        filters: filters,
        region: region,
      },
      region: region,
    };
    if (userId) {
      payload.user_id = userId;
    }

    setUploading(true);
    await createScheme(payload);
    setUploading(false);
    onClose();
  };

  const handleConfigFileChange = (ev, isFromCategorization = true) => {
    const fr = new FileReader();
    fr.onload = e => {
      const configuration = JSON.parse(e.target.result)

      if (Object.keys(configuration).length > 0) {
        let invalidConfigPositions = []
        for (const [key, config] of Object.entries(configuration)) {
          if (!Object.keys(config).includes('category')
          ) {
            invalidConfigPositions.push(key)
          }
        }

        if (invalidConfigPositions.length === 0) {
          if (isFromCategorization) {
            let vars = [...variables]

            for (const [key, conf] of Object.entries(configuration)) {
              const variableIdx = vars.findIndex(v => v.propName === key)
              if (variableIdx >= 0) {
                const variable = {...vars[variableIdx]}
                variable.category = conf.category
                variable.label = conf.label || key
                vars[variableIdx] = variable
              }
              // vars.push({
              //   propName: key,
              //   category: conf.category,
              //   label: conf.label ? conf.label : key,
              // })
            }

            setVariables(vars)
          } else {
            varsConfig = configuration
            handleUpload()
          }
        } else {
          enqueueSnackbar(
            'Invalid configuration for this variables: ' + invalidConfigPositions.join(', '),
            {variant: 'error'}
          )
        }
      } else {
        enqueueSnackbar('Invalid configuration file', {variant: 'error'})
      }

      ev.target.value = null;
    }

    if (ev.target.files.length > 0 && ev.target.files[0]) {
      fr.readAsText(ev.target.files[0]);
    }
  }

  const mapForm = (
    <ThemeProvider theme={themeCrowdView}>
      <AppBar position='static'>
        <Tabs
          indicatorColor='secondary'
          value={value}
          onChange={(e, newValue) => handleChange(e, newValue)}
        >
          <Tab label={t('dataset_upload_options')} {...a11yProps(0)} />
          <Tab
            label={t('dataset_variable_configuration')}
            {...a11yProps(1)}
            disabled={variables === null || uploading}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MuiContainer display='flex'>
          <ThemeProvider theme={themeCrowdView}>
            {uploadErrors.map((error, index) => (
              <Alert severity='error' key={index} data-cy='importing-errors'>
                {error}
              </Alert>
            ))}
            {uploadMessages.map((message, index) => (
              <Alert severity='success' key={index}
                     data-cy='importing-messages'>
                {message}
              </Alert>
            ))}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card>
                  <CardActionArea
                    data-cy='card_option_file'
                    value='image_file'
                    onClick={() => handleOnClickUploadOption('image_file')}
                  >
                    <Grid
                      container
                      direction='column'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <MuiDescriptionIcon/>

                      <Typography variant='caption' color='textSecondary'>
                        Tipos de archivos admitidos:
                        .csv,.json,.geojson,.parquet
                      </Typography>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardActionArea
                    data-cy='card_option_url'
                    value='url'
                    onClick={() => handleOnClickUploadOption('url')}
                  >
                    <Grid
                      container
                      direction='column'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <MuiLinkIcon/>

                      <Typography variant='caption' color='textSecondary'>
                        Tipos de links admitidos: s3, drive, public link
                      </Typography>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
            {showContent ? (<>
              <Grid container spacing={2}>
                {uploadOption === 'image_file' ? (
                  <Grid item xs={12}>
                    <MuiGridInputText item xs={12}>
                      <MuiTextField
                        name='image_file'
                        margin='dense'
                        value={uploadedFiles ? [0]?.name : ''}
                        label={t('file_label')}
                        type='file'
                        variant='outlined'
                        data-cy='image_file'
                        inputProps={{
                          style: {height: 35},
                          accept: '.csv,.json,.parquet'
                        }}
                        style={{paddingTop: 5, paddingBottom: error ? 0 : 23}}
                        onChange={(e) => handleFilesChange(e)}
                        fullWidth
                        error={t(errors.file?.message)}
                      />
                    </MuiGridInputText>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <MuiGridInputText item xs={12}>
                        <TextField
                          disabled={uploadOption === 'image_file'}
                          name='url'
                          autoFocus
                          margin='dense'
                          type='text'
                          label={t('url_label')}
                          data-cy='url'
                          variant='outlined'
                          fullWidth
                          value={url}
                          error={t(errors.url?.message)}
                          onChange={handleUrlChange}
                        />
                      </MuiGridInputText>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <MuiGridInputText item xs={12}>
                <TextField
                  name='datasetName'
                  margin='dense'
                  type='text'
                  label={t('name_the_dataset')}
                  data-cy='dataset_name'
                  variant='outlined'
                  fullWidth
                  error={t(errors.datasetName?.message)}
                />
              </MuiGridInputText>
              <MuiGridInputText item xs={12}>
                <SelectField
                 name='region'
                 label="Region"
                 variant='outlined'
                 fullWidth
                 id={'region-autocomplete'}
                >
                <MenuItem value="US">US</MenuItem>
                <MenuItem value="SA">SA</MenuItem>
                </SelectField>
              </MuiGridInputText>
              <Grid item xs={12} container justifyContent='flex-end'>
                <Button
                  style={{marginLeft: 10}}
                  onClick={handleUpload}
                  variant='contained'
                  color='primary'
                  data-cy='upload_dataset_options'
                  startIcon={uploading &&
                    <CircularProgress size={20} color='secondary'/>}>
                  Upload
                </Button>
              </Grid>
            </>) : (<Grid item xs={12}></Grid>)}
          </ThemeProvider>
        </MuiContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {variables !== null && !uploading && (
          <Grid container>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={{maxHeight: 444}}>
                <MuiTable stickyHeader size="small" aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align='right'>{t('survey_answer')}</TableCell>
                      <TableCell
                        align='right'>{t('audio') || 'Audio'}</TableCell>
                      <TableCell align='right'>{t('categorical')}</TableCell>
                      <TableCell
                        align='right'>{t('statistic_column')}</TableCell>
                      <TableCell align='right'>{t('weight_column')}</TableCell>
                      <TableCell align='right'>{t('ignore')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {variables?.map((d, index) => (
                      <TableRow key={'d-' + index} data-cy='var_row'>
                        <TableCell component='th' scope='row'>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <MuiTextField
                              style={{marginRight: 10}}
                              label="Label"
                              id="outlined-size-small"
                              defaultValue={d.label ? d.label : d.propName}
                              variant="outlined"
                              onChange={(e) => changeVariableLabel(d, index, e.target.value)}
                              size="small"/>
                            {d.propName}
                          </div>
                        </TableCell>
                        <TableCell align='right'
                                   data-cy={'td_numeric_' + d.propName}>
                          <Radio
                            checked={d.category === 'answer'}
                            value='answer'
                            data-cy={'radio_numeric_' + d.propName}
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right'
                                   data-cy={'td_audio_' + d.propName}>
                          <Radio
                            checked={d.category === 'audio'}
                            value='audio'
                            data-cy={'radio_audio_' + d.propName}
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right'
                                   data-cy={'td_categorical_' + d.propName}>
                          <Radio
                            checked={d.category === 'categorical'}
                            value='categorical'
                            data-cy={'radio_categorical_' + d.propName}
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right'
                                   data-cy={'td_statistic_' + d.propName}>
                          <Radio
                            checked={d.category === 'statistic'}
                            value='statistic'
                            data-cy={'radio_statistic_' + d.propName}
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right'
                                   data-cy={'td_weight_' + d.propName}>
                          <Radio
                            checked={d.category === 'weight'}
                            value='weight'
                            data-cy={'radio_weight_' + d.propName}
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <Radio
                            checked={d.category === 'ignore'}
                            value='ignore'
                            name={d.propName}
                            onChange={(e) => changeVariables(d, index, e.target.value)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Grid>
            <Grid item xs={12} style={{marginTop: 10}} container
                  justifyContent='flex-end'>
              <input
                accept=".json"
                style={{display: 'none'}}
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => handleConfigFileChange(e)}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained"
                        color="primary"
                        data-cy='config_variable_file'
                        component="span">
                  Import Configuration
                </Button>
              </label>
              {/*<Button*/}
              {/*  style={{ marginLeft: 10 }}*/}
              {/*  data-cy='config_variable_export'*/}
              {/*  variant="contained"*/}
              {/*  color="primary"*/}
              {/*  onClick={() => handleConfigExport()}>*/}
              {/*  Export Configuration*/}
              {/*</Button>*/}
            </Grid>
          </Grid>
        )}
      </TabPanel>
    </ThemeProvider>
  );

  const actions = (
    <>
      <MuiButton
        disabled={uploading || !variables}
        onClick={() => onSave()}
        color='primary'
        variant='text'
        data-cy='save_upload_dataset'
        startIcon={uploading && <CircularProgress size={20} color='secondary'/>}
      >
        {t('save_generic_btn')}
      </MuiButton>
      <MuiButton
        onClick={onClose}
        color='primary'
        variant='text'
        disabled={uploading}
        data-cy='close_upload_dataset'
      >
        {t('cancel_btn')}
      </MuiButton>
    </>
  );

  return (
    <FormProvider {...methods}>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        horizontal='center'
        vertical='top'
        severity='error'
        open={openToast}
      />
      <form>
        <Modal
          open={open}
          onClose={onClose}
          title={t('upload_new_dataset')}
          maxWidth={'lg'}
          actions={actions}
        >
          {mapForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default FormModal;
