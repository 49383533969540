import React, {useEffect, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl, FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select, Switch,
  TextField,
  Typography
} from '@mui/material';
import axiosEngineInstance
  from "../../../../../../../../api/axios/axiosEngineInstance";
import {generateID} from "../../../../../../../../utils/utils";
import axios from "../../../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../../api/status.utils";
import {useLoading} from "../../../../../../../providers/LoadingProvider";
import {enqueueSnackbar} from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const styles = ["Photorealistic", "Realistic", "Surreal", "Minimalist", "Abstract", "Cartoon", "Vintage", "Futuristic", "Impressionistic", "Expressionistic", "Black and White"];
const dramaticLevels = ["Tranquil", "Neutral", "Engaging", "Moderate", "Dramatic"];
const complexities = ["Simple", "Moderate", "Complex"];
const valences = ["Neutral", "Positive", "Very Positive", "Negative", "Very Negative"];

const SubtopicManager = ({
                           parentIndex,
                           subIndex,
                           subtopic,
                           updateSubtopic,
                           generalParams,
                           dataset,
                           answerVar
                         }) => {
  const {
    setIsLoading,
    setIsTopicInferenceLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();

  const handleGenerateImage = async () => {
    setTitle("Turning your insights into art...");
    setProgress(0)
    setProgressMessage("This will only take a few minutes...")
    setShowProgressBar(false);
    setIsTopicInferenceLoading(true);
    setIsLoading(true);
    let localSubtopic = {...subtopic, image_url: null};
    const url = `${process.env.REACT_APP_ENGINE_URL}/generate-topic-images`;
    const requestId = generateID();
    await axiosEngineInstance.post(url, {
      surveyId: dataset,
      questionId: answerVar,
      topics: [localSubtopic],
      ...generalParams,
      requestId: requestId
    }).then((res) => {
      const updatedTopics = res.data?.updatedTopics.updatedTopics;
      console.log("Updated topics", updatedTopics);
      if (updatedTopics) {
        const imageUrl = updatedTopics[0].image_url;
        if (imageUrl) {
          updateSubtopic(updatedTopics[0], parentIndex, subIndex, true);
        }
      }
      return updatedTopics;
    }).catch((err) => {
      console.log("Error generating images", err);
      return null;
    });
    setIsLoading(false);
    setIsTopicInferenceLoading(false);
    setShowProgressBar(false);
    setProgress(0);
    setTitle("");
  }

  return (
    <Card key={`${parentIndex}-${subIndex}`} sx={{mb: 2, ml: 2}}>
      {(subtopic.image_url) && (
        <CardMedia
          component="img"
          height="140"
          image={subtopic.image_url}
          alt={subtopic.topic}
        />
      )}
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '15px',
            fontWeight: 600
          }}
        >
          {subtopic.parent.topic} / {subtopic.topic}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 400
          }}
        >
          {subtopic.description}
        </Typography>
        <TextField
          fullWidth
          label="Image Description"
          value={subtopic.image_description}
          onChange={(e) => {
            const localSubtopic = {
              ...subtopic,
              image_description: e.target.value,
              image_url: null
            };
            updateSubtopic(localSubtopic, parentIndex, subIndex, false);
          }}
          variant="filled"
          multiline
          rows={4}
          InputLabelProps={{
            shrink: true,
            style: {
              fontFamily: 'Montserrat',
              fontSize: '14px',
              fontWeight: 600
            }
          }}
          sx={{
            '& .MuiFilledInput-root': {
              fontFamily: 'Montserrat',
              fontSize: '12px'
            }
          }}
        />
        <Button variant="contained" color="primary" sx={{mt: 1}}
                onClick={() => handleGenerateImage()}>
          Update Image
        </Button>
      </CardContent>
    </Card>
  )
}


const TopicImageManager = ({policy, dataset, updatePolicy}) => {
  const [topics, setTopics] = useState([...policy.topics]);
  const [generalParams, setGeneralParams] = useState({
    style: policy.imageSettings?.style || "Photorealistic",
    dramaticLevel: policy.imageSettings?.dramaticLevel || "Neutral",
    imageComplexity: policy.imageSettings?.imageComplexity || "Complex",
    emotionalValence: policy.imageSettings?.emotionalValence || "Neutral",
    region: policy.imageSettings?.region || "Not specified",
    specialInstructions: policy.imageSettings?.specialInstructions || "",
    model: policy.imageSettings?.model || "stable-diffusion",
  });
  const [overrideTopicImages, setOverrideTopicImages] = useState(false);
  const [overrideSubtopicImages, setOverrideSubtopicImages] = useState(false);

  const {
    setIsLoading,
    setIsTopicInferenceLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();

  useEffect(() => {
    setTopics(policy.topics);
    if (policy.imageSettings) {
      let localGeneralParams = {
        style: policy.imageSettings?.style || "Photorealistic",
        dramaticLevel: policy.imageSettings?.dramaticLevel || "Neutral",
        imageComplexity: policy.imageSettings?.imageComplexity || "Complex",
        emotionalValence: policy.imageSettings?.emotionalValence || "Neutral",
        region: policy.imageSettings?.region || "Not specified",
        specialInstructions: policy.imageSettings?.specialInstructions || "",
        model: policy.imageSettings?.model || "stable-diffusion"
      };
      for (const [key, value] of Object.entries(policy.imageSettings)) {
        if (value) {
          localGeneralParams[key] = value;
        }
      }
      setGeneralParams(localGeneralParams);
    }
  }, [policy]);

  const handleParamChange = (e) => {
    const updatedSettings = {...generalParams, [e.target.name]: e.target.value};
    setGeneralParams(updatedSettings);
  };

  const handleGenerateTopicImage = async (index) => {
    setTitle("Turning your insights into art...");
    setProgress(0)
    setProgressMessage("This will only take a few minutes...")
    setShowProgressBar(false);
    setIsTopicInferenceLoading(true);
    setIsLoading(true);
    const topic = topics[index];
    topic.image_url = null
    const url = `${process.env.REACT_APP_ENGINE_URL}/generate-topic-images`;
    const requestId = generateID();
    await axiosEngineInstance.post(url, {
      surveyId: dataset,
      questionId: policy.answerVar,
      topics: [topic],
      ...generalParams,
      requestId: requestId
    }).then((res) => {
      const updatedTopics = res.data?.updatedTopics.updatedTopics;
      console.log("Updated topics", updatedTopics);
      if (updatedTopics) {
        let localPolicy = {...policy};
        const imageUrl = updatedTopics[0].image_url;
        if (imageUrl) {
          const newTopics = [...topics];
          newTopics[index] = updatedTopics[0];
          console.log("New topics", newTopics);
          localPolicy.topics = newTopics;
          updatePolicy(localPolicy);
          setTopics(newTopics);
        }
      }
      return updatedTopics;
    }).catch((err) => {
      console.log("Error generating images", err);
      errorToast("Error generating images");
      return null;
    });
    setIsLoading(false);
    setIsTopicInferenceLoading(false);
    setShowProgressBar(false);
    setProgress(0);
    setTitle("");
  };

  const generateSubtopicImages = async (index) => {
    const topic = topics[index];
    setTitle(`Turning your insights about "${topic.topic}" into art...`);
    setProgress(0)
    setProgressMessage("This will only take a few minutes...")
    setShowProgressBar(true);
    setIsTopicInferenceLoading(true);
    setIsLoading(true);
    const subtopics = topic.subtopics.map(st => ({...st, image_url: null}));
    const requestId = submit_images_generation(subtopics);
    const jobData = await pollTaskProgress(requestId);
    if (jobData?.updatedTopics?.length > 0) {
      let localPolicy = {...policy};
      const newTopics = [...topics];
      const updatedSubtopics = jobData.updatedTopics;
      const currentSubtopics = newTopics[index].subtopics;
      updatedSubtopics.forEach((subtopic, index) => {
        if (!subtopic.image_url) {
          if (currentSubtopics[index].topic === subtopic.topic) {
            subtopic.image_url = currentSubtopics[index].image_url
          } else {
            const current = currentSubtopics.find(t => t.topic === subtopic.topic);
            if (current) {
              subtopic.image_url = current.image_url
            }
          }
        }
      })
      newTopics[index].subtopics = updatedSubtopics;
      localPolicy.topics = newTopics;
      localPolicy.imageSettings = generalParams;
      updatePolicy(localPolicy);
      setTopics(newTopics);
    } else {
      errorToast("Error generating images");
    }
    setIsLoading(false);
    setIsTopicInferenceLoading(false);
    setShowProgressBar(false);
    setProgressMessage('');
    setProgress(0);
    setTitle("");
  }

  const handleDescriptionChange = (index, description) => {
    const newTopics = [...topics];
    newTopics[index].image_description = description;
    newTopics[index].image_url = null;
    setTopics(newTopics);
  };

  function submit_images_generation(topics_submit) {
    const url = `${process.env.REACT_APP_ENGINE_URL}/generate-topic-images`;
    const requestId = generateID();
    axiosEngineInstance.post(url, {
      surveyId: dataset,
      questionId: policy.answerVar,
      topics: topics_submit,
      ...generalParams,
      requestId: requestId
    }).then((res) => {
      return res.data;
    }).catch((err) => {
      console.log("Error generating images", err);
      return null;
    })
    return requestId;
  }

  const pollTaskProgress = async (requestId) => {
    let countFails = 0
    while (countFails < 10) {
      try {
        const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
        const response_body = progress_response.data;

        if (!response_body || !response_body.data) {
          await new Promise((resolve) => setTimeout(resolve, 1200));
          continue;
        }

        const {message, status, progress, additionalData} = response_body.data;

        if (status === 'failed' || status === 'error') {
          return null;

        } else if (status === 'success' && additionalData) {
          return additionalData;
        }

        setProgressMessage(`${message}`);
        let norm_progress = Math.min(progress, 100)
        setProgress(norm_progress);

      } catch (e) {
        console.error(e);
        countFails++;
      }
      await new Promise((resolve) => setTimeout(resolve, 1200));
    }
    return null;
  };

  const handleGenerateImages = async () => {
    setTitle("Turning your insights into art...");
    setProgress(0)
    setProgressMessage("This will only take a few minutes...")
    setShowProgressBar(true);
    setIsTopicInferenceLoading(true);
    setIsLoading(true);
    const preprocessed = topics.map((topic) => {
      return {
        ...topic,
        image_url: overrideTopicImages ? null : topic.image_url,
        subtopics: topic.subtopics?.map((subtopic) => {
          return {
            ...subtopic,
            image_url: overrideSubtopicImages ? null : subtopic.image_url
          }
        }) || []
      }
    })
    const requestId = submit_images_generation(preprocessed);
    const jobData = await pollTaskProgress(requestId);
    if (jobData?.updatedTopics?.length > 0) {
      const localPolicy = {
        ...policy,
        topics: jobData.updatedTopics,
        imageSettings: generalParams
      };

      updatePolicy(localPolicy);
    } else {
      errorToast("Failed to generate images");
    }
    setIsLoading(false);
    setIsTopicInferenceLoading(false);
    setShowProgressBar(false);
    setProgress(0);
    setTitle("");
    setProgressMessage('');
  };

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const renderSubtopics = (subtopics, parentIndex) => {
    return subtopics?.map((subtopic, subIndex) => (
      <SubtopicManager
        key={`subtopic-${parentIndex}-${subIndex}`}
        parentIndex={parentIndex}
        subIndex={subIndex}
        subtopic={subtopic}
        updateSubtopic={(updatedSubtopic, parentIndex, subIndex, triggerPolicyUpdate = true) => {
          const newTopics = [...topics];
          newTopics[parentIndex].subtopics[subIndex] = updatedSubtopic;
          if (triggerPolicyUpdate) {
            let localPolicy = {...policy};
            localPolicy.topics = newTopics;
            localPolicy.imageSettings = generalParams;
            updatePolicy(localPolicy);
          }
          setTopics(newTopics);
        }}
        generalParams={generalParams}
        dataset={dataset}
        answerVar={policy.answerVar}
      />
    ));
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Style</InputLabel>
            <Select name="style" value={generalParams.style} label="Style"
                    onChange={handleParamChange}>
              {styles.map((style) => (
                <MenuItem key={style} value={style}>{style}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Dramatic Level</InputLabel>
            <Select name="dramaticLevel" value={generalParams.dramaticLevel}
                    label="Dramatic Level"
                    onChange={handleParamChange}>
              {dramaticLevels.map((level) => (
                <MenuItem key={level} value={level}>{level}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Image Complexity</InputLabel>
            <Select name="imageComplexity"
                    value={generalParams.imageComplexity}
                    onChange={handleParamChange} label="Image Complexity">
              {complexities.map((complexity) => (
                <MenuItem key={complexity}
                          value={complexity}>{complexity}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Emotional Valence</InputLabel>
            <Select name="emotionalValence"
                    value={generalParams.emotionalValence}
                    onChange={handleParamChange} label="Emotional Valence">
              {valences.map((valence) => (
                <MenuItem key={valence} value={valence}>{valence}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Region"
            name="region"
            value={generalParams.region}
            onChange={handleParamChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Special Instructions"
            name="specialInstructions"
            value={generalParams.specialInstructions}
            onChange={handleParamChange}
            margin="normal"
            variant="outlined"
          />
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Model</InputLabel>
            <Select name="model"
                    value={generalParams.model}
                    onChange={handleParamChange} label="Model">
              <MenuItem key={'stable-diffusion'} value={'stable-diffusion'}>Stable
                Diffusion</MenuItem>
              <MenuItem key={'dalle'} value={'dalle'}>DALL-E</MenuItem>
            </Select>
          </FormControl>
          <FormControl component="fieldset" fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={overrideTopicImages}
                  onChange={() => setOverrideTopicImages((prevState) => !prevState)}
                  name="overrideTopicImages"
                />
              }
              label="Override main topic's image"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={overrideSubtopicImages}
                  onChange={() => setOverrideSubtopicImages((prevState) => !prevState)}
                  name="overrideSubtopicImages"
                />
              }
              label="Override subtopic's image"
            />
          </FormControl>
          <FormControl fullWidth>
            <Button variant="contained" color="primary"
                    onClick={handleGenerateImages}>
              Generate Images
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {topics.map((topic, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    fontWeight: 500
                  }}
                >
                  {topic.topic}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {topic.image_url && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={topic.image_url}
                    alt={topic.topic}
                  />
                )}
                <CardContent>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 400
                    }}
                  >
                    {topic.description}
                  </Typography>
                  <TextField
                    fullWidth
                    label="Image Description"
                    value={topic.image_description}
                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                    margin="normal"
                    variant="filled"
                    multiline
                    rows={4}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 600
                      }
                    }}
                    sx={{
                      '& .MuiFilledInput-root': {
                        fontFamily: 'Montserrat',
                        fontSize: '12px'
                      }
                    }}
                  />
                  <Box sx={{display: 'flex', flexDirection: 'row', gap: 2}}>
                    <Button variant="contained" color="primary" sx={{mt: 1}}
                            onClick={() => handleGenerateTopicImage(index)}>
                      Update Image
                    </Button>
                    {(topic.subtopics?.length > 0) && (
                      <Button variant="contained" color="primary" sx={{mt: 1}}
                              onClick={() => generateSubtopicImages(index)}>
                        Update subtopic images
                      </Button>
                    )}
                  </Box>
                  <Box sx={{marginTop: '20px'}}>
                    {topic.subtopics && renderSubtopics(topic.subtopics.map(st=>({...st,parent:topic,parentTopic:topic.topic})), index)}
                  </Box>
                </CardContent>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default TopicImageManager;
