import React, { createContext, useContext, useState } from 'react';

// Create the context
const CacheContext = createContext();

const MAX_CACHE_SIZE = 1000; // Maximum number of items in the cache

// Provider component
export const CacheProvider = ({ children }) => {
  // const [cache, setCache] = useState(new Map());
  const [cache, setCache] = useState({});

  const setCacheData = (key, data) => {
    setCache((prevCache) => {
      const newCache = { ...prevCache,...{[key]:data}};
      if (Object.keys(newCache)>MAX_CACHE_SIZE) {
        const firstKey = Object.keys(newCache)[0];
        delete newCache[firstKey];
      }
      return newCache;
    });
  };

  return (
    <CacheContext.Provider value={{ cache, setCacheData }}>
      {children}
    </CacheContext.Provider>
  );
};

// Custom hook to use the cache
export const useCache = () => useContext(CacheContext);
