import * as _ from "lodash";
import {Button, Grid} from "@mui/material";
import {Topic} from "./Topic";
import AddIcon from "@mui/icons-material/Add";

export const Topics = ({
                         question,
                         handleQuestionChange,
                         rootIsConditioned,
                         collection,
                         weightVariable,
                         dataRegion
                       }) => {
 
  const handleTopicChange = (topic, index) => {
    let localQuestion = _.clone(question);
    localQuestion.topics[index] = topic;
    handleQuestionChange(localQuestion);
  }

  const handleDeleteTopic = (topic, index) => {
    let localQuestion = _.clone(question);
    localQuestion.topics.splice(index, 1);
    handleQuestionChange(localQuestion);
  }

  const handleAddTopic = () => {
    let localQuestion = _.clone(question);
    let topicIndex = (localQuestion.topics?.length || 0) + 1;
    localQuestion.topics = localQuestion.topics || [];
    localQuestion.topics.push({
      topic: `Topic ${topicIndex}`,
      description: `Description of topic ${topicIndex}`,
      isParent: true
    });
    handleQuestionChange(localQuestion);
  }

  return (
    <Grid container direction='column'>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <h3>Topics</h3>
        </Grid>
        {!rootIsConditioned && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleAddTopic}
                    startIcon={<AddIcon/>}>
              Add Topic
            </Button>
          </Grid>
        )}
      </Grid>
      {question.topics?.map((topic, index) => (
        <Topic
          topic={topic}
          index={index}
          key={`topic-${index}`}
          isRootTopic={true}
          rootIsConditioned={rootIsConditioned}
          handleTopicChange={handleTopicChange}
          handleDelete={handleDeleteTopic}
          collection={collection}
          weightVariable={weightVariable}
          dataRegion={dataRegion}
          answerVariable={question.answerVar}
        />
      ))}
    </Grid>
  )
}
