import {Grid, IconButton, Typography} from '@mui/material';
import {useEffect, useRef, useState} from "react";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import {useAudioControl} from "../providers/VoiceProvider";
import WaveSurfer from "wavesurfer.js";

const VoicePlayer = ({
                       audio,
                       audioKey,
                       footer = null,
                       theme = 'dark',
                       showLogs = false
                     }) => {

  const containerRef = useRef();
  const mounted = useRef(false);
  const abortController = useRef(new AbortController());
  const [playbackRate, setPlaybackRate] = useState(1.0); // Default playback rate
  const [playerWidth, setPlayerWidth] = useState(240);
  const wavesurferInstance = useRef({});
  const [isPlaying, setIsPlaying] = useState(false);
  const {playingAudioId, playAudio} = useAudioControl();
  const cursorStyles = ['8px', '6px', '70%']
  const [disabled, setDisabled] = useState(true);
  const themeStyles = {
    dark: {
      backgroundColor: 'transparent',
      playbackRateBackground: '#616161',
      playbackRateTextColor: '#FFFFFF', // Dark Gray
      captionTextColor: '#424242', // Dark Blue Gray
      waveColor: '#6161614D', // Light Gray
      progressColor: '#424242', // Darker Gray
      controlColor: '#616161',
      cursorColor: '#424242', // Darker Gray
    },
    light: {
      backgroundColor: 'transparent',
      playbackRateBackground: '#FFFFFF',
      playbackRateTextColor: '#424242',
      captionTextColor: '#ffffff',
      waveColor: '#FFFFFF66',
      progressColor: '#FFFFFF',
      controlColor: '#FFFFFF',
      cursorColor: '#FFFFFF',
    }
  };

  const updatePlayerWidth = () => {
    // Calculate and update player width based on container's width and viewport size
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const calculatedWidth = containerWidth - 120; // Adjust values based on your layout and the button's size
      if (calculatedWidth > 0) {
        setPlayerWidth(calculatedWidth);
      }
    }
  };

  useEffect(() => {
    mounted.current = true;
    window.addEventListener('resize', updatePlayerWidth);
    updatePlayerWidth(); // Initial update

    return () => {
      mounted.current = false;
      window.removeEventListener('resize', updatePlayerWidth);

      if (wavesurferInstance.current[audioKey]) {
        try {
          wavesurferInstance.current[audioKey].destroy();
        } catch (e) {
          console.log('Error destroying WaveSurfer instance:', e);
        }
      }

      abortController.current.abort(); // Trigger abort on unmount
    };
  }, [audioKey]);


  useEffect(() => {
    if (!wavesurferInstance?.current || !wavesurferInstance?.current[audioKey]) {
      const [customHeight, customTop, customBorderRadius] = cursorStyles
      const waveSurfer = WaveSurfer.create({
        container: `#waveform-${audioKey}`,
        waveColor: themeStyles[theme].waveColor,
        progressColor: themeStyles[theme].progressColor,
        cursorColor: themeStyles[theme].cursorColor,
        cursorWidth: 8,
        normalize: true,
        height: 20,
        barWidth: 2,
        barGap: 1,
        barRadius: 1,
        barHeight: 20,
        hideScrollbar: true,
        minPxPerSec: 80,
        pixelRatio: 1,
        // backend:"WebAudio",
        scriptPath: 'https://cdnjs.cloudflare.com/ajax/libs/wavesurfer.js/2.0.4/wavesurfer.min.js'
      });
      waveSurfer.on('finish', () => {
        if (!mounted.current) return;
        playAudio(null);
        setPlaybackRate(1.0);
        if (wavesurferInstance.current[audioKey]) {
          wavesurferInstance.current[audioKey].stop();
          setIsPlaying(false);
        }
      });
      if (showLogs) {
        console.log("Audio", audio);
      }
      waveSurfer.load(`data:audio/ogg;base64,${audio}`).then(() => {
        if (!mounted.current) return;
        setDisabled(false);
      }).catch((reason) => {
        if (showLogs) {
          console.log("Audio load error", audio)
          console.log("Error loading audio", reason)
        }

      });

      waveSurfer.renderer.cursor.style.height = customHeight
      waveSurfer.renderer.cursor.style.top = customTop
      waveSurfer.renderer.cursor.style.borderRadius = customBorderRadius

      wavesurferInstance.current[audioKey] = waveSurfer;
    }
  }, [audio]);


  useEffect(() => {
    if (wavesurferInstance.current && wavesurferInstance.current[audioKey]) {
      if (playingAudioId !== audioKey) {
        setIsPlaying(false);
        wavesurferInstance.current[audioKey].stop();
        setPlaybackRate(1.0);
      }
    }
  }, [playingAudioId, audioKey]);

  useEffect(() => {
    if (wavesurferInstance.current[audioKey]) {
      wavesurferInstance.current[audioKey].setPlaybackRate(playbackRate);
    }
  }, [playbackRate]);

  const increasePlaybackRate = () => {
    let newPlaybackRate = playbackRate + 0.25;
    if (newPlaybackRate > 2.0) {
      newPlaybackRate = 1.0;
    }
    setPlaybackRate(newPlaybackRate); // Increase by 0.1
  };
  return (
    <Grid container direction={'column'} alignItems={'center'} spacing={0}
          aria-disabled={disabled}>
      <Grid container item ref={containerRef} alignItems="center" spacing={0.5}
            style={{
              backgroundColor: themeStyles[theme].backgroundColor,
              height: '30px'
            }}>
        <Grid item>
          {(isPlaying) ? (
            <IconButton
              onClick={() => {
                setIsPlaying(false);
                wavesurferInstance.current[audioKey].pause();
              }
              }
              disabled={disabled}
            >
              <PauseCircleOutlineIcon fontSize={'small'}
                                      style={{color: themeStyles[theme].controlColor}}/>
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                playAudio(audioKey);
                setIsPlaying(true);
                wavesurferInstance.current[audioKey].play();
              }
              }
              disabled={disabled}
            >
              <RecordVoiceOverIcon fontSize='small'
                                   style={{color: themeStyles[theme].controlColor}}/>
            </IconButton>
          )}
        </Grid>
        <Grid item>
          <button
            onClick={increasePlaybackRate}
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 500,
              letterSpacing: '1.5px',
              fontSize: '10px',
              backgroundColor: themeStyles[theme].playbackRateBackground,
              padding: '2px 4px 2px 4px',
              height: '15px',
              width: '45px',
              borderRadius: '8px',
              borderColor: 'none',
              borderWidth: 0,
              boxShadow: 'none',
              cursor: 'pointer',
              color: themeStyles[theme].playbackRateTextColor,
              '&:hover': {
                backgroundColor: themeStyles[theme].playbackRateBackground,
              },
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            {playbackRate.toFixed(2)}X
          </button>
        </Grid>
        <Grid item>
          <div id={`waveform-${audioKey}`}
               key={`waveform-container-${audioKey}`}
               style={{height: '20px', width: playerWidth}}></div>
        </Grid>
      </Grid>
      {(footer) && (
        <Grid item>
          <Typography variant={'caption'}
                      style={{
                        fontFamily: 'Montserrat',
                        fontSize: '10px',
                        color: themeStyles[theme].captionTextColor
                      }}>
            {footer}
          </Typography>
        </Grid>
      )
      }
    </Grid>
  );
};

export default VoicePlayer;
