import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Grid, ThemeProvider} from '@mui/material'
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import themeCrowdView from 'theme/crowdView';
import {useTranslation} from 'components/providers/TranslationProvider';
import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';

// Style and asset imports
import {
  MuiButtonClose,
  MuiButtonDelete,
  MuiIconButtonDelete,
  MuiInputDelete,
  MuiTypographyAssociated,
  MuiTypographyContent,
  MuiTypographyName
} from './styles/modalDelete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const schema = yup.object().shape({
  name: yup.string().required(),
  nameConfirmation: yup.string().matches(/^(delete|eliminar|supprimer|excluir)$/).nullable(),
});

const DeleteModal = ({ onSubmit, name, title, content, deleteMutation, schemesAssociated }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const {
    formState: { errors, isValid },
    setValue,
  } = methods;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const deleteQuery = query.get('delete');
    setOpen(deleteQuery === null ? false : true);
    setValue('name', name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  React.useEffect(() => {
    if (deleteMutation.isSuccess) {
      handleClose();
    }
    if (deleteMutation.isError) {
      setError(t(deleteMutation.error.data?.error));
      setOpenToast(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMutation.isSuccess, deleteMutation.isError]);

  const handleClose = () => {
    query.delete('delete');
    setValue('nameConfirmation', '');
    navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const actions = (
    <>
      <MuiButtonClose
        onClick={handleClose}
        color='primary'
        variant='text'
        focusRipple
      >
        {t('cancel_btn')}
      </MuiButtonClose>
      <MuiButtonDelete
        variant='outlined'
        onClick={onSubmit}
        type='submit'
        loading={deleteMutation.isLoading}
        disabled={deleteMutation.isLoading || !isValid}
        data-cy='modal_btn_delete_dataset'
      >
        {t('delete')}
      </MuiButtonDelete>
    </>
  );

  const contentModal = (
    <FormProvider {...methods}>
      <Grid container display='flex' direction='column' alignItems='center' justifyContent='center'>
        <Grid item xs={3} >
          <MuiIconButtonDelete>
            <DeleteOutlinedIcon fontSize='medium' />
          </MuiIconButtonDelete>
        </Grid>
        <Grid item xs={9}>
          <MuiTypographyContent variant='h5'>{content}</MuiTypographyContent>
        </Grid>
      </Grid>
      <MuiTypographyName variant='body2' >"{name}"</MuiTypographyName>
      <ThemeProvider theme={themeCrowdView}>
        <MuiInputDelete
          autoFocus
          name='nameConfirmation'
          data-cy='modal_confirmation_dataset'
          margin='dense'
          type='text'
          placeholder={`${t('enter')} "${t('delete')}" ${t('to_confirm')}`}
          variant='outlined'
          fullWidth
          error={t(errors.nameConfirmation?.message)}
        />
      </ThemeProvider>
      {schemesAssociated?.length > 0 && (
        <>
          <MuiTypographyAssociated
            variant='h5'>{t('associated_scenarios')}</MuiTypographyAssociated>
          <ul>
            {schemesAssociated.map((i) => (
              <li key={i}>
                <MuiTypographyAssociated sx={{ textAlign: 'left', textTransform:'initial'}}>
                  {i.name}
                </MuiTypographyAssociated>
              </li>
            ))}
          </ul>
        </>
      )}
    </FormProvider>
  );

  return (
    <>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        open={openToast}
        vertical='top'
        horizontal='center'
      />
      <Modal open={open} isDelete actions={actions}>
        {contentModal}
      </Modal>
    </>
  );
};

export default DeleteModal;
