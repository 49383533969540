import {
  Container, Toolbar, Tabs, Tab,
  Grid, Typography, styled} from "@mui/material";

export const MuiContainerNavBar = styled(Container)(({ theme }) => ({
  boxShadow: 'none',
  overflow: 'hidden',
  marginTop:'30px',
  marginBottom: 10,
  maxWidth: '1400px !important',
}));

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: '38vh !important',
  backgroundColor: 'white',
}));

export const MuiGridBox = styled(Grid)(({ theme }) => ({
  marginTop: '5px !important',
}));

export const MuiTabs = styled(Tabs)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  '& .Mui-selected': {
    fontSize: 15,
    fontWeight: 700,
    color: theme.palette.primary.main,
    transition: 'font-size 10ms, font-weight 10ms',
  },
}));

export const MuiGridImgContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));

export const MuiGridPaper = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
}));

export const MuiImg  = styled("img")(({ theme }) => ({
  width: 240,
  height: 300,
  objectFit: 'scale-down',
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  width: '100vh',
  marginTop: theme.spacing(2),
  textAlign: 'center',
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  paddingTop: 20,
}));

export const MuiTab = styled(Tab)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.primary,
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight:700,
  marginRight:theme.spacing(5),
  marginBottom:theme.spacing(2),
  fontStyle:'normal',
  fontFamily:'Raleway',
  color:'#212121',
  textAlign:'right'
}));

export const MuiImage  = styled("img")(({ theme }) => ({
  width: 248,
  backgroundColor: 'white',
  height: 248,
  borderRadius:'19px',
  border:'3px solid',
  boxShadow: '1px 3px 1px rgba(0, 0, 0, 0.3)',
  borderColor: theme.palette.primary.main,
  objectFit: 'scale-down',
}));
