// External library imports
import {blueGrey} from '@mui/material/colors';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  lighten,
  Skeleton,
  styled,
  Typography
} from '@mui/material';
import {Link} from 'react-router-dom';

export const MuiCardRoot = styled(Card)(({ theme }) => ({
  position: 'static',
  width: '251px',
  height: '272px',
  padding: theme.spacing(1),
  backgroundColor:'#F5F5F5',
  borderRadius:'19px',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
  },
}));

export const MuiIconButtonEdit = styled(IconButton)(({ theme }) => ({
  padding: '7px',
  color:'#000000',
  backgroundColor: 'transparent',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.5),
  },
}));

export const MuiIconButtonDelete = styled(IconButton)(({ theme }) => ({
  padding: '7px',
  color: '#ff1744',
  '&:hover': {
    backgroundColor: lighten('#ff1744', 0.7),
  },
}));

export const MuiLink = styled(Link)(({ theme }) => ({
  color: blueGrey.A700,
  textDecoration: 'none',
}));

export const MuiCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 190,
  backgroundColor: 'white',
  margin: theme.spacing(1),
  borderRadius: '15px',
  backgroundSize: 'contain !important',
}));

export const MuiCardContent = styled(CardContent)(({ theme }) => ({
  height:'50px',
  display: 'flex',
  flexDirection:'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  paddingRight: theme.spacing(0),
  paddingLeft: theme.spacing(0),
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  width: '100%',
  marginLeft: theme.spacing(1.5),
  color: '#212121',
  fontWeight: 'bold !important',
  lineHeight: '1.2 !important',
  fontSize: '16px !important',
  letterSpacing: '0.007em',
  fontFamily:'Raleway',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const MuiCardGrid = styled(Grid,{
  shouldForwardProp: (prop) => prop !== 'hasDeletePermission'
})(({ hasDeletePermission }) => ({
  marginTop: '-8px',
  marginRight: hasDeletePermission? '12px' : '-12px'
}));

export const MuiSkeletonMedia = styled(Skeleton)(({ theme }) => ({
  height: 200,
  margin: theme.spacing(1),
  borderRadius: '15px',
  backgroundSize: 'contain !important',
}));

export const MuiSkeleton = styled(Skeleton)(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
  borderRadius: '5px',
  color: '#2c3032',
  fontWeight: 'bold !important',
  lineHeight: '1.2 !important',
  fontSize: '1.4rem !important',
  letterSpacing: '0.007em',
}));

export const gridNameStyle = {
  paddingRight: '10px',
  maxWidth: '150px'
}

export const divActionStyle = {
  display:'flex',
  flexDirection:'row'
}
