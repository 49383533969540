import axios from './axios/axiosInstance';
import apiPaths from './apiPaths';

const uploadCollection = (data, collection, onError, onSuccess) => {
  return axios.post(apiPaths.upload_collection_dataset, { data, collection });
};

export const uploadCollectionS3 = (data, onError, OnSuccess) => {
  return axios.post(apiPaths.upload_collection_s3, data);
};

export const getImportJob = (id) => {
  return axios.get(`${apiPaths.get_import_job}?id=${id}`);
};

export const getFilters = (data) => {
  return axios.post(apiPaths.get_filters, data);
};

export default uploadCollection;
