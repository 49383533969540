import {
  Box,
  Grid,
  IconButton,
  Popover,
} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from '../../providers/TranslationProvider';
import CloseIcon from '@mui/icons-material/Close';
import {
  boxStyles, iconButtonStyles,
  popoverStyles, MuiTypography, MuiSlider
} from '../styles/popover';

const PopoverUI = ({
                     initRangeBar = [],
                     handleInitRangeCommited = null,
                     minBarRange = 0,
                     maxBarRange = 100,
                     showBinsSlider,
                     setShowBinSlider,
                     amountBins,
                     handleBinSizeCommited,
                     position,
                     isSimilarityHistogram
                   }) => {

  const {t} = useTranslation();
  const [localInitRange, setLocalInitRange] = useState(initRangeBar?.map(x=>Math.round(x)));
  const [localAmountBins, setLocalAmountBins] = useState(amountBins);

  const handleClose = () => setShowBinSlider(false)

  const handleBinSizeChanged = (event, value) => setLocalAmountBins(value);

  const handleInitRangeChange = (event, value) => setLocalInitRange(value);

  const onInitRangeCommited = (event, value) => handleInitRangeCommited(value)

  const onBinSizeCommited = (event, value) => handleBinSizeCommited(value);

  console.log("InitRange", initRangeBar);
  console.log("Min-Max", minBarRange, maxBarRange);
  return (
    <Popover
      open={showBinsSlider}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position.y + 20,
        left: position.x
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: popoverStyles,
      }}
    >
      <Grid container direction={'row'} alignItems={'center'}
            justifyContent={'center'}>
        <Grid item xs={9}>
          <MuiTypography id="progress-bar-selector" gutterBottom>
            {t('amount_bins')}
          </MuiTypography>
        </Grid>
        <Grid item xs={3}>
          <IconButton aria-label="close" onClick={handleClose} style={iconButtonStyles}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <MuiSlider
        valueLabelDisplay="on"
        aria-label="custom thumb label"
        value={localAmountBins}
        onChangeCommitted={onBinSizeCommited}
        onChange={handleBinSizeChanged}
        aria-labelledby="progress-bar-selector"
        min={5}
        size="small"
        max={20}
      />
      {(isSimilarityHistogram) && (
        <div>
          <MuiTypography display="block" gutterBottom>
            Default selection range
          </MuiTypography>
          <Box sx={boxStyles}>
            <MuiSlider
              value={localInitRange}
              size="small"
              onChange={handleInitRangeChange}
              onChangeCommitted={onInitRangeCommited}
              min={Math.round(minBarRange)}
              max={Math.round(maxBarRange)}
              valueLabelDisplay="on"
            />
          </Box>
        </div>
      )}
    </Popover>
  )
}

export default PopoverUI
