import * as React from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';

import { secureRoutes, routes } from '../../routes';
import { useAuth } from 'components/providers/AuthProvider';
import { useLoading } from '../providers/LoadingProvider';

const Views = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user } = useAuth();
  const {setIsLoading} = useLoading();
  React.useEffect(() => {
    setIsLoading(false)
    if (isAuthenticated && (location.pathname === '/' || location.pathname === '/login'))
      navigate('/orgs');
    else navigate(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectedRoutes = useRoutes(
    isAuthenticated && user ? secureRoutes(user.type, user.org_id, user.orgs_user_level) : routes
  );
  return <div>{selectedRoutes}</div>;
};
export default Views;
