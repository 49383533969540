import { styled, Typography, Card, Chip } from '@mui/material';
import color from "color";

export const MuiTypographyLabel = styled(Typography)(({ theme }) => ({
  marginLeft: 1,
  fontWeight:700,
  fontStyle:'bold',
  fontSize: 14,
  fontFamily:'Raleway',
  color:'#424242',
  cursor: 'pointer',
}));

export const MuiCard = styled(Card)(({ theme }) => ({
  marginBottom: 2,
  marginRight: "5px",
  width: "100%",
  border:'1px solid #EEEEEE',
  backgroundColor: '#FAFAFA'
}));

export const MuiChip = styled(Chip)(({ theme }) => ({
  maxWidth: '100%',
  overflow: 'visible',
  fontFamily:'Montserrat',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '12px',
  letterSpacing: '0.015em',
  textTransform:'uppercase',
  fontSize: '10px',
  color: '#254957',
  borderColor:'transparent',
  opacity: 1.0,
  borderRadius:'8px',
  height:'29px'
}));

export const boxUserQueryStyles = {
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden'
};

export const boxAnswerStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: "3px"
};

export const gridContainerStyles = {
  width: '100%',
  marginTop: "3px"
};

export const gridFilterStyles = {
  width: "100%",
  flex: 1,
  marginBottom:"16px"
};

export const typographyAnswerStyles = {
  color:'#424242',
  textAlign: 'justify',
  fontFamily: 'Montserrat',
  fontSize:'14px',
  fontWeight: 400,
  lineHeight:'17px',
  letterSpacing:'0.0025em',
  fontStyle:'normal',
};

