import {
  interpolateBlues,
  interpolateBuGn,
  interpolateBuPu,
  interpolateGnBu,
  interpolateGreens,
  interpolateGreys,
  interpolateInferno,
  interpolateOranges,
  interpolateOrRd,
  interpolatePlasma,
  interpolatePurples,
  interpolateRainbow,
  interpolateRdBu,
  interpolateRdPu,
  interpolateReds,
  interpolateSpectral,
  interpolateTurbo,
  interpolateYlGn,
  interpolateYlGnBu,
  interpolateYlOrBr
} from "d3-scale-chromatic";
import * as d3 from "d3";

export function findClosestPaletteName(themeColors, colorSchemes) {
  let bestMatchName = null;
  let smallestDistance = Infinity;

  Object.keys(colorSchemes).forEach(paletteName => {
    const palette = colorSchemes[paletteName];
    let totalDistance = 0;

    palette.forEach(paletteColor => {
      const distanceToPrimary = calculateColorDistance(themeColors.primary, paletteColor);
      const distanceToSecondary = calculateColorDistance(themeColors.secondary, paletteColor);
      totalDistance += (distanceToPrimary + distanceToSecondary) / 2;
    });

    const averageDistance = totalDistance / palette.length;

    if (averageDistance < smallestDistance) {
      smallestDistance = averageDistance;
      bestMatchName = paletteName;
    }
  });

  return bestMatchName;
}

export const colorToHex = (color) => {
  // Check if the color is already in hexadecimal format
  if (color.startsWith('#')) {
    // If the color is in shorthand format (#RGB), convert it to full form (#RRGGBB)
    if (color.length === 4) {
      color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
    }
    return color.toUpperCase();
  }

  // If the color is in RGB format
  if (color.startsWith('rgb')) {
    const rgbValues = color.match(/\d+/g);
    if (rgbValues) {
      const r = parseInt(rgbValues[0]).toString(16).padStart(2, '0');
      const g = parseInt(rgbValues[1]).toString(16).padStart(2, '0');
      const b = parseInt(rgbValues[2]).toString(16).padStart(2, '0');
      return `#${r}${g}${b}`.toUpperCase();
    }
  }

  throw new Error(`Invalid color format. Please provide a color in hexadecimal or RGB format.${color}`);
}


export const calculateColorDistance = (color1, color2) => {
  // Convert hex colors to RGB
  const rgb1 = hexToRGB(color1);
  const rgb2 = hexToRGB(color2);

  // Calculate Euclidean distance
  return Math.sqrt(
    Math.pow(rgb1.r - rgb2.r, 2) +
    Math.pow(rgb1.g - rgb2.g, 2) +
    Math.pow(rgb1.b - rgb2.b, 2)
  );
};

export function hexToRGB(colorInput) {
  if (colorInput.startsWith('#')) {
    const hex = colorInput.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return {r, g, b};
  } else {
    const components = colorInput.match(/\d+/g); // Extract the integer values for r, g, and b
    let r = parseInt(components[0], 10);
    let g = parseInt(components[1], 10);
    let b = parseInt(components[2], 10);
    return {r, g, b};
  }
}

export function getSequentialColors(interpolator, steps) {
  return Array.from({length: steps}, (_, i) => interpolator((i / (steps - 1)) * 0.8));  // This reverses the color scheme.
}

export function expandColorScale(numColors, colorScheme) {
  let domain = []
  for (let i = 0; i < colorScheme.length; i++) {
    domain.push(i);
  }
  let colorScale = d3.scaleLinear()
    .domain(domain)
    .range(colorScheme)

  const colours = [];
  const step = (colorScheme.length / (numColors));  // Adjust the step size based on the number of colors you want
  for (let i = 0; i < numColors; i++) {
    const value = i * step;
    const color = colorScale(value);
    colours.push(color);
  }
  return colours;
}

export const colorSchemes = {
  material_colors: expandColorScale(5,
    ['#607D8B', '#9E9E9E', '#795548', '#FF5722',
      '#FF9800', '#FFC107', '#FFEB3B', '#CDDC39', '#8BC34A',
      '#4CAF50', '#009688', '#00BCD4', '#03A9F4', '#2196F3',
      '#3F51B5', '#673AB7', '#9C27B0', '#E91E63', '#F44336']),
  green_blue: getSequentialColors(interpolateGnBu, 5),
  yellow_green_blue: getSequentialColors(interpolateYlGnBu, 5),
  yellow_green: getSequentialColors(interpolateYlGn, 5),
  nivo: ['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560', '#f1e15b'],
  orange_red: getSequentialColors(interpolateOrRd, 5),
  purple_blue: getSequentialColors(interpolateBuPu, 5).reverse(),
  blues: getSequentialColors(interpolateBlues, 5),
  greens: getSequentialColors(interpolateGreens, 5),
  greys: getSequentialColors(interpolateGreys, 5),
  reds: getSequentialColors(interpolateReds, 5),
  oranges: getSequentialColors(interpolateOranges, 5),
  purples: getSequentialColors(interpolatePurples, 5),
  blue_green: getSequentialColors(interpolateBuGn, 5),
  blue_purple: getSequentialColors(interpolateBuPu, 5),
  red_blue: getSequentialColors(interpolateRdBu, 5),
  red_purple: getSequentialColors(interpolateRdPu, 5),
  inferno: getSequentialColors(interpolateInferno, 5),
  rainbow: getSequentialColors(interpolateRainbow, 5),
  turbo: getSequentialColors(interpolateTurbo, 5),
  spectral: getSequentialColors(interpolateSpectral, 5),
  plasma: getSequentialColors(interpolatePlasma, 5),
  yellow_orange_brown: getSequentialColors(interpolateYlOrBr, 5),
};

export function generateScaledPalette(scheme_name, numColors) {
  const schemes = {
    material_colors: expandColorScale(numColors, ['#607D8B', '#9E9E9E', '#795548', '#FF5722',
      '#FF9800', '#FFC107', '#FFEB3B', '#CDDC39', '#8BC34A',
      '#4CAF50', '#009688', '#00BCD4', '#03A9F4', '#2196F3',
      '#3F51B5', '#673AB7', '#9C27B0', '#E91E63', '#F44336']),
    green_blue: getSequentialColors(interpolateGnBu, numColors),
    yellow_green_blue: getSequentialColors(interpolateYlGnBu, numColors),
    yellow_green: getSequentialColors(interpolateYlGn, numColors),
    nivo: expandColorScale(numColors, colorSchemes["nivo"]),
    orange_red: getSequentialColors(interpolateOrRd, numColors),
    purple_blue: getSequentialColors(interpolateBuPu, numColors).reverse(),
    blues: getSequentialColors(interpolateBlues, numColors),
    greens: getSequentialColors(interpolateGreens, numColors),
    greys: getSequentialColors(interpolateGreys, numColors),
    reds: getSequentialColors(interpolateReds, numColors),
    oranges: getSequentialColors(interpolateOranges, numColors),
    purples: getSequentialColors(interpolatePurples, numColors),
    blue_green: getSequentialColors(interpolateBuGn, numColors),
    blue_purple: getSequentialColors(interpolateBuPu, numColors),
    red_blue: getSequentialColors(interpolateRdBu, numColors),
    red_purple: getSequentialColors(interpolateRdPu, numColors),
    inferno: getSequentialColors(interpolateInferno, numColors),
    rainbow: getSequentialColors(interpolateRainbow, numColors),
    turbo: getSequentialColors(interpolateTurbo, numColors),
    spectral: getSequentialColors(interpolateSpectral, numColors),
    plasma: getSequentialColors(interpolatePlasma, numColors),
    yellow_orange_brown: getSequentialColors(interpolateYlOrBr, numColors),
  };
  return schemes[scheme_name];
}

export function getContrastTonality(colorInput) {
  let r, g, b;

  // Detect if the color is RGB or HEX
  if (colorInput?.startsWith('rgb')) { // If color is in RGB format
    const components = colorInput.match(/\d+/g); // Extract the integer values for r, g, and b
    r = parseInt(components[0], 10);
    g = parseInt(components[1], 10);
    b = parseInt(components[2], 10);
  } else if (colorInput?.startsWith('#')) { // If color is in HEX format
    const __ret = hexToRGB(colorInput);
    r = __ret.r;
    g = __ret.g;
    b = __ret.b;
  } else {
    // If the color format is not recognized, default to black text
    return '#212121';
  }

  // Calculate YIQ ratio
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  // Return black for light colors, white for dark colors
  return (yiq >= 170) ? 'dark' : 'light';
}
