import {useQuery} from 'react-query';

import {status200} from '../../api/status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchOrganization = (id) =>
  axios.get(`${apiPaths.org}${id}`, status200).then((resp) => resp.data);

const useOrganization = (id) =>
  useQuery(['organization', id], () => fetchOrganization(id), {
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });

export default useOrganization;
