import axios from 'axios';

const baseUrl = process.env.REACT_APP_ENGINE_URL;

const axiosEngineInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

export default axiosEngineInstance;
