import {Button, Card, CardActions, Grid, styled} from "@mui/material";

export const MuiGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'start',
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color:theme.palette.primary.main,
  justifyContent: 'center',
  borderRadius: '20px',
  width:'106px',
  height:'38px',
  padding:'10px 24px 10px 24px'
}));

export const MuiCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  height:106,
  width:106
}));

export const MuiCardActions = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 208,
  height: 230,
  marginButton: 8,
}));

export const MuiImg= styled("img")(({ theme }) => ({
  objectFit: 'scale-down',
  width: 106,
  height: 106,
}));

export const MuiInput = styled("input")(({ theme }) => ({
  display: 'none',
}));
