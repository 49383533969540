import {useMutation, useQueryClient} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from 'api/apiPaths';

const deleteUser = async ({ _id }) =>
  axios.delete(`${apiPaths.delete_user}${_id}`, status200).then((resp) => resp.data);

const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => deleteUser(values), {
    onSuccess: () => {
      queryClient.resetQueries(['users']);
    },
    onError: (error) => {
      console.error(error);
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};

export default useDeleteUser;
