import * as _ from "lodash";
import {Badge, Button, Grid, IconButton, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {MuiBox} from "../common/styles/categoryCard";
import AddIcon from "@mui/icons-material/Add";
import SplitIcon from "@mui/icons-material/CallSplit";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble"
import * as React from "react";
import {useState} from "react";
import {SubtopicExtraction} from "./SubtopicExtraction";

const TopicIcon = ({base64}) => {
  const decodedSVG = atob(base64); // Decode base64 to get the SVG content
  return (
    <div
      dangerouslySetInnerHTML={{__html: decodedSVG}}
      style={{
        height: '11px',
        width: '11px',
        display: 'flex',
        alignItems: 'center'
      }}
    />
  );
}

export const Topic = ({
                        topic,
                        index,
                        handleTopicChange,
                        handleDelete,
                        isRootTopic,
                        rootIsConditioned,
                        answerVariable,
                        collection,
                        weightVariable,
                        dataRegion
                      }) => {

  const [modalOpen, setModalOpen] = useState(false);
  const handleAddSubTopic = () => {
    let localTopic = _.clone(topic);
    const stIndex = (localTopic.subtopics?.length || 0) + 1;
    localTopic.subtopics = localTopic.subtopics || [];
    localTopic.subtopics.push({
      topic: `Subtopic ${stIndex}`,
      description: `Description of subtopic ${stIndex}`,
      isParent: false,
      representativity: 0
    });
    handleTopicChange(localTopic, index);
  }


  return (
    <>
      <Grid container item direction='column'
            sx={{mt: isRootTopic ? '10px' : '5px'}}>
        <Grid item xs={12}>
          <MuiBox
            alignItems={'center'}  // Align items in the center vertically
            display={'flex'}
            flexDirection={'row'}  // Ensure items are arranged in a row
            justifyContent={'space-between'}
            sx={{width: '100%'}}  // Make sure the box takes full width
          >
            <Grid container alignItems="center" direction="row">
              <Grid item sx={{mr: '5px'}}>
                {topic.icon ? (
                  <TopicIcon base64={topic.icon}/>
                ) : null}
              </Grid>
              <Grid item sx={{mr: '5px'}}>
                <TextField
                  label="Topic"
                  type="text"
                  onChange={(evt) => {
                    let localTopic = _.clone(topic);
                    localTopic.topic = evt.target.value;
                    handleTopicChange(localTopic, index);
                  }}
                  sx={{width: '250px'}}
                  value={topic.topic}
                  variant="outlined"
                  disabled={(isRootTopic&&!rootIsConditioned)||(isRootTopic)}
                />
              </Grid>
              <Grid item sx={{mr: '5px'}}>
                <TextField
                  label="Description"
                  type="text"
                  onChange={(evt) => {
                    let localTopic = _.clone(topic);
                    localTopic.description = evt.target.value;
                    handleTopicChange(localTopic, index);
                  }}
                  value={topic.description}
                  sx={{width: '350px'}}
                  variant="outlined"
                  multiline
                  minRows={1}
                  maxRows={3}
                  disabled={(isRootTopic&&!rootIsConditioned)||(isRootTopic)}
                />
              </Grid>
              <Grid item>
                <Badge
                  badgeContent={
                    <span style={{
                      color: '#FFFFFF',
                      fontSize: '0.75rem',
                      fontWeight: 'bold'
                    }}>
                    {(topic.representativity || 0).toFixed(0)}%
                  </span>
                  }
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    '.MuiBadge-badge': {
                      backgroundColor: 'transparent',  // Make the badge background transparent
                      width: 28,                       // Adjust the size of the icon
                      height: 28,
                      mt: '20px',
                      ml: '25px',
                      borderRadius: '50%',             // Make it a circular badge
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  }}
                >
                  <IconButton>
                    <ChatBubbleIcon
                      style={{fontSize: '32px'}}/> {/* Adjust size of the chat bubble icon */}
                  </IconButton>
                </Badge>
              </Grid>

              <Grid item>
                {isRootTopic && (
                  <Badge badgeContent={topic.subtopics?.length || 0}
                         color="primary">
                    <IconButton onClick={() => setModalOpen(true)}>
                      <SplitIcon style={{rotate: '180deg'}}/>
                    </IconButton>
                  </Badge>
                )}
              </Grid>
              {((isRootTopic && !rootIsConditioned)||(!isRootTopic)) && (
                <Grid item>
                  <IconButton onClick={() => handleDelete(index)}>
                    <DeleteIcon/>
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </MuiBox>
        </Grid>
        {(isRootTopic) && (
          <Grid item xs={12} sx={{ml: '10px'}}>
            <>
              {topic.subtopics?.map((subtopic, stIndex) => (
                <Topic
                  key={`topic-${index}-${stIndex}`}
                  topic={subtopic}
                  index={stIndex}
                  isRootTopic={false}
                  rootIsConditioned={false}
                  handleTopicChange={(st, sIndex) => {
                    let localTopic = _.clone(topic);
                    localTopic.subtopics[sIndex] = st;
                    handleTopicChange(localTopic, index);
                  }}
                  handleDelete={(sIndex) => {
                    let localTopic = _.clone(topic);
                    localTopic.subtopics.splice(sIndex, 1);
                    handleTopicChange(localTopic, index);
                  }}
                />
              ))}
              <Grid item xs={12}>
                <Button
                  startIcon={<AddIcon/>}
                  sx={{width: '100%'}}
                  onClick={handleAddSubTopic}
                >
                  Add Subtopic
                </Button>
              </Grid>
            </>
          </Grid>
        )}
        <SubtopicExtraction parentTopic={topic} parentTopicIndex={index}
                            handleTopicChange={handleTopicChange}
                            answerVariable={answerVariable}
                            dataset={collection}
                            weightVariable={weightVariable}
                            dataRegion={dataRegion} open={modalOpen}
                            closeModal={() => setModalOpen(false)}/>
      </Grid>

    </>
  )
}
