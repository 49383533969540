import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {status200} from '../../../../api/status.utils';
import {
  divAnswerStyle,
  MuiDivRoot,
  MuiTypography
} from '../../styles/listWidget';
import {Divider, Grid, TablePagination} from '@mui/material';
import VoicePlayer from "../../VoicePlayer";
import enginePaths from "../../../../api/enginePaths";
import axiosEngineInstance from "../../../../api/axios/axiosEngineInstance";
import {MuiLinearProgress} from "../../styles/similarityHistogramWidgetUI";

function ListWidget({
                      dataSource,
                      dataRegion,
                      column,
                      audioColumn = null,
                      policyId,
                      activePolicyTab,
                      policyTab
                    }) {
  const [isLoading, setIsLoading] = useState(false);
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSegmentationFilters = useSelector(state => state.app.segmentationFilter);
  const similarityQuery = useSelector(state => state.app.similarityQuery);
  const similarityFilter = useSelector(state => state.app.similarityFilter);
  const activeInsightsFilters = useSelector(state => state.app.insightsFilter);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const [answers, setAnswers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [updateData, setUpdateData] = useState(false);
  const foldedState = useSelector(state => state.app.widgetFoldedState);
  const [divMaxHeight, setDivMaxHeight] = useState('500px'); // Default height, adjust as needed
  const [playingAudioIndex, setPlayingAudioIndex] = useState(null);

  function updateSize() {
    const similarityFoldedState = foldedState[`${column}_${policyId}_similarity`];
    // Capture the window height
    const windowHeight = window.innerHeight;
    if (!similarityFoldedState) {
      console.log("Substract similarityFoldedState", similarityFoldedState)
    }

    let knownHeights = 290;
    if (!similarityFoldedState && similarityQuery[policyId]) {
      console.log("Entered folded state and similarity query")
      knownHeights += 230;
    } else if (similarityQuery[policyId]) {
      console.log("Entered only similarity query")
      knownHeights += 70;
    }
    // Adjust these values based on your layout specifics
    const calculatedHeight = windowHeight - knownHeights;
    if (calculatedHeight > 0) {
      setDivMaxHeight(`${calculatedHeight}px`);
    }
  }

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', updateSize);
  }, [foldedState, similarityQuery]); // Empty dependency array means this effect runs once on mount


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setUpdateData(true);
  };

  const fetchData = async (data) => {
    try {
      const response = await axiosEngineInstance.post(enginePaths.aggregate_list, data, status200);
      return response.data;
    } catch (error) {
      console.log("Error fetching List widget data", error);
      return {data: []};
    }
  };

  async function loadListData() {
    setIsLoading(true);

    let localStatisticFilter = {...activeStatisticFilters};
    let localSegmentationFilter = {...activeSegmentationFilters, ...(activeInsightsFilters[policyId] || {})};
    let localSimilarityFilter = similarityFilter[policyId] || {};
    let localSimilarityQuery = similarityQuery[policyId] || {};
    if (treeMapFilter) {
      Object.keys(treeMapFilter).forEach(key => {
        Object.assign(localSegmentationFilter, treeMapFilter[key]);
      });
    }

    const aggResponse = await fetchData({
      surveyId: dataSource,
      questionId: column,
      statistics: localStatisticFilter,
      segmentation: localSegmentationFilter,
      similarity_query: localSimilarityQuery,
      similarity: localSimilarityFilter,
      audioVariable: audioColumn,
      statVars: null,
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      region: dataRegion
    });
    let localAnswers = []
    const localData = aggResponse?.rows;
    if (localData && localData.length > 0) {
      let total = aggResponse.total;
      if (total !== totalItems) {
        setTotalItems(total);
      }
      localAnswers = localData.map((item, index) => {
        return {
          id: index,
          content: `"${item[column]}"`,
          audio: item.audio,
        };
      });
    }
    setIsLoading(false);
    return localAnswers
  }


  useEffect(() => {
    setTimeout(() => {
      if (answers) {
        setCurrentPage(0);
        setUpdateData(activePolicyTab === policyTab);
      }
    }, 200)
  }, [dataSource, column, activeSegmentationFilters, activeStatisticFilters, similarityFilter, similarityQuery, treeMapFilter, activeInsightsFilters]);

  useEffect(() => {
    if (activePolicyTab === policyTab && (!answers || answers.length === 0)) {
      setUpdateData(true);
    }
  }, [activePolicyTab])

  useEffect(async () => {
    if (updateData) {
      const localAnswers = await loadListData();
      setAnswers(localAnswers);
      setUpdateData(false);
    }
  }, [currentPage, updateData]);

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <>
      <Grid item xs={12}>
        {(isLoading) ? <MuiLinearProgress/> : null}
        <MuiDivRoot sx={{pr: 1.5, pt: '5px'}}
                    style={{overflowY: "auto", maxHeight: divMaxHeight}}>
          {answers?.map((answer, index) => {
            const rid = generateRandomString(10);
            const audioKey = `audio-${index}-page-${currentPage}-${rid}`;
            return (<div style={divAnswerStyle}
                         key={`answer-${index}-page-${currentPage}`}>
              <MuiTypography variant="body2" display="block"
                             gutterBottom>{answer.content}</MuiTypography>
              {(answer.audio) && (
                <VoicePlayer
                  audio={answer.audio}
                  key={audioKey}
                  currentPage={currentPage}
                  audioKey={audioKey}
                  audioIndex={index}
                  playingAudioIndex={playingAudioIndex}
                  setPlayingAudioIndex={setPlayingAudioIndex}
                />
              )}
              <Divider style={{width: '100%'}}/>
            </div>)
          })}
        </MuiDivRoot>
        <Grid item xs={12} container justifyContent="flex-start">
          <TablePagination
            component="div"
            count={totalItems}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={itemsPerPage}
            onRowsPerPageChange={(event) => {
              setItemsPerPage(parseInt(event.target.value, 10))
              setCurrentPage(0);
            }}
            labelDisplayedRows={({
                                   from,
                                   to,
                                   count
                                 }) => `Showing ${from}-${to} of ${count} citations`}
            // labelRowsPerPage={'Citations'}
            rowsPerPageOptions={[]}
            showFirstButton
            showLastButton
            sx={{
              '.MuiTablePagination-toolbar': {
                flexWrap: 'wrap',
              },
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                marginRight: '2px',
                marginLeft: '0px',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                letterSpacing: '0.004em',
                color: '#475569',

              },
            }} // Use sx prop for direct styling, adjust values as needed
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ListWidget;

