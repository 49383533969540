import {useEffect, useState} from "react";
import {useLoading} from "./LoadingProvider";

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const {isSearchLoading} = useLoading();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!isSearchLoading) {
        setDebouncedValue(value);
      }
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, isSearchLoading]);

  return debouncedValue;
}
