import * as React from 'react';
import {
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

// Style and asset imports
import {
  demoContainerStyle,
  gridStyle,
  MuiAlarmOffIcon,
  MuiButtonSkeleton,
  MuiDatePicker,
  MuiDeleteIcon,
  MuiEditIcon,
  MuiForwardIcon,
  MuiGridContainerButtons,
  MuiSettingIcon,
  MuiTableBase,
  MuiTextField,
  tableCellActionsStyle
} from './styles/table';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useTranslation} from '../../../providers/TranslationProvider';

const TableSkeleton = ({ columns }) => {

  const rowSkeleton = Array.from(Array(4).keys());

  return (
    <MuiTableBase aria-label='simple table'>
      <TableHead>
        <TableRow>
          {columns.map((c) => (
            <TableCell key={c} component='th' scope='row'>
              {c}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowSkeleton.map((r) => (
          <TableRow key={r}>
            {columns.map((c) => (
              <TableCell key={c} component='th' scope='row'>
                <MuiButtonSkeleton variant='text' animation='wave' />
              </TableCell>
            ))}
            <TableCell align='right'>
              <MuiGridContainerButtons container>
                <MuiButtonSkeleton variant='text' animation='wave'/>
              </MuiGridContainerButtons>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTableBase>
  );
};

const Table = ({
  columns,
  row,
  data,
  onDelete,
  showDelete,
  onEdit,
  showEdit,
  onForward,
  showSettings,
  onSettings,
  isLoading,
  handleSearch,
  searchTerm,
  setDateRange,
}) => {
  const [disableFinalDate, setDisableFinalDate] = React.useState(true)
  const [initDateSelected, setInitDateSelected] = React.useState(null);
  const [endDateSelected, setEndDateSelected] = React.useState(null);
  const [clearDate, setClearDate] = React.useState(false);
  const [toggleClearDate, setToogleClearDate] = React.useState(false);
  const refInitDate = React.useRef()
  const refEndDate = React.useRef()

  const { t } = useTranslation();

  const handleStartDate = (date) => {
    setInitDateSelected(date);
    const firstDate = new Date(date);
    if (!isNaN(firstDate)) {
      localStorage.setItem('initDate', firstDate.toLocaleDateString('es-ES'))
      setDisableFinalDate(false)
    }
  };

  const handleEndDate = (date) => {
    setEndDateSelected(date)
    setToogleClearDate(true)
    const endDate = new Date(date);
    let range;
    if (!isNaN(endDate)) {
      const endDateFormated = endDate.toLocaleDateString('es-ES');
      range = {
        initDate: localStorage.getItem('initDate'),
        endDate: endDateFormated,
      }
      setDateRange(range);
    }
  };

  React.useEffect(() => {
    if (clearDate) {
      setInitDateSelected(null);
      setToogleClearDate(false);
      setClearDate(false);
      setDisableFinalDate(true);
      setDateRange('');
    }
  }, [clearDate]);

  React.useEffect(() => {
    if(refInitDate && refInitDate.current){
      let objLabel = refInitDate.current.querySelector('.MuiInputLabel-root')
      if (objLabel) {
        objLabel.style.top = initDateSelected !== null ? '0px' : '-9px';
      }
    }
    if(refEndDate && refEndDate.current){
      let objLabel = refEndDate.current.querySelector('.MuiInputLabel-root')
      if (objLabel) {
        objLabel.style.top = endDateSelected !== null ? '0px' : '-9px';
      }
    }
  }, [refEndDate.current]);

  const DateRangePicker = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']} sx={demoContainerStyle}>
          <div style={{ display: 'flex' }}>
            <MuiDatePicker
              ref={refInitDate}
              value={initDateSelected}
              label={t('initial_date')}
              onChange={handleStartDate}
              TextFieldComponent={({ initDateSelected, ...textFieldProps }) => (
                <TextField
                  {...textFieldProps}
                  value={initDateSelected || ''}
                />
              )}
            />
            <MuiDatePicker
              ref={refEndDate}
              readOnly={disableFinalDate}
              value={endDateSelected}
              onChange={handleEndDate}
              label={t('final_date')}
              TextFieldComponent={({ endDateSelected, ...textFieldProps }) => (
                <TextField
                  {...textFieldProps}
                  value={endDateSelected || ''}
                />
              )}
              slotProps={{
                field: { clearable: true, onClear: () => setClearDate(true) },
              }}
              sx={{width: !toggleClearDate ? 130 : 155,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor:'#BDBDBD',
                  borderRadius: '0px 4px 4px 0px',
                  borderLeft:'none',
                  borderRight:'1px solid #BDBDBD',
                },}}
            />
          </div>
        </DemoContainer>
      </LocalizationProvider>
    );
  }
  return isLoading ? (
    <TableSkeleton columns={columns} />
  ) : (
    <MuiTableBase aria-label='simple table'>
      <TableHead>
        <TableRow>
          {columns.map((c) => (
            <TableCell key={c} component='th' scope='row' sx={{lineHeight:'0.5rem'}}>
              <Typography variant='caption' fontFamily='Montserrat' sx={{lineHeight:'15px'}}>{c}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableHead>
        <TableRow>
          {columns.map((c, index) => {
            if (index !== columns.length-1) {
              return (
                <TableCell key={index} component='th' scope='row' style={{height:'42px'}}>
                  {c === 'Created' ? (<DateRangePicker/>) :
                    (<MuiTextField key={`start-date-${index}`} placeholder={c}
                                   value={searchTerm[index]||""}
                                   onChange={(e) => handleSearch(e, index)}
                                   type='search'
                                   variant='outlined' />)}
                </TableCell>
              );
            }else{
              return (
                <TableCell key={index} component='th' scope='row'>
                </TableCell>
              );
            }
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((r) => (
          <TableRow key={r._id || r.id || r.collection} data-cy='table_common_row'>
            {row.map((j) => (
              <TableCell key={j}>{!r[j] ? <MuiAlarmOffIcon/> :
                <Typography variant='caption' fontFamily='Montserrat'>{r[j]}</Typography>}</TableCell>
            ))}
            <TableCell sx={tableCellActionsStyle}>
              <MuiGridContainerButtons container>
                {onForward && (
                  <>
                    <Grid item style={gridStyle}>
                      <MuiForwardIcon
                        onClick={() => onForward(r.id, 'old', r._id)}
                        title='Old Tree View'
                      />
                    </Grid>
                    <Grid item>
                      <MuiForwardIcon
                        onClick={() => onForward(r.id, 'new', r._id)}
                        title='Go to Tree View'
                      />
                    </Grid>
                  </>
                )}
                {onEdit && (
                  <Grid item>
                    {showEdit && (
                      <MuiEditIcon
                        onClick={(event) => onEdit(r.id || r._id || r.collection, r, event)}
                      />
                    )}
                  </Grid>
                )}
                {onSettings && (
                  <Grid item sx={{marginLeft:'10px'}}>
                    {showSettings && (
                      <MuiSettingIcon
                        onClick={(event) => onSettings(r.id || r._id, r,event)}
                      />
                    )}
                  </Grid>
                )}
                {onDelete && (
                  <Grid item sx={{marginLeft:'10px'}}>
                    {showDelete && (
                      <MuiDeleteIcon
                        onClick={() => onDelete(r.id || r.name || r._id, r)}
                      />
                    )}
                  </Grid>
                )}
              </MuiGridContainerButtons>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTableBase>
  );
};

export default Table;
