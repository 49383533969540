import {useMutation, useQueryClient} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from 'api/apiPaths';

const deleteOrganization = (org) => {
  axios.delete(apiPaths.orgs, { data: org.data }, status200).then((resp) => resp.data);
};

const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(async (values) => await deleteOrganization(values), {
    onSuccess: () => {
      queryClient.resetQueries(['organizations']);
    },
    onError: (error) => {
      console.error(error);
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries(['organizations']);
    },
  });
};

export default useDeleteOrganization;
