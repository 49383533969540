import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalCategoryEdit from "../common/ModalCategoryEdit";
import * as React from "react";
import {
  divCustomCategoriesStyle,
  divTooltipCategoriesStyle
} from "./styles/customSegmentation";
import Tooltip from "@mui/material/Tooltip";
import {useTranslation} from "../../../../../../../providers/TranslationProvider";

const CustomAISegment = ({
                              segment,
                              segmentIndex,
                              handleDeleteSegment,
                              handleUpdateSegment,
                              handleClassification,
                            }) => {
  const [open, setOpen] = React.useState(false);
  const {t} = useTranslation();

  const CustomChipContent = ({label, startIcon, endIcon}) => {
    return (
      <Box display="flex" alignItems="center">
        {startIcon}
        <Typography variant="body2" style={{margin: '0 8px', color: 'white'}}>
          {label}
        </Typography>
        {endIcon}
      </Box>
    );
  }

  const CategoryTooltip = ({category, index}) => {
    return (
      <Typography variant="inherit" key={`category-${category.topic}-${index}`}
                  style={{color: 'inherit'}}>
        {category.topic}
      </Typography>
    )
  }

  const CategoryChip = ({category, index, amountCategories}) => {
    const calculateChipColor = (index, length, saturation = 50, lightness = 50) => {
      const hue = (index * 360 / length) % 360;
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };
    const categoryChipColor = calculateChipColor(index, amountCategories);

    return (
      <Chip
        label={<CustomChipContent
          label={`${category.topic} ${category.representativity ? '(' + category.representativity.toFixed(0) + '%)' : ''}`}
        />}
        variant="outlined"
        style={{
          maxWidth: '100%',
          overflow: 'visible',
          backgroundColor: categoryChipColor
        }}
      />
    )
  }

  const CustomCategories = ({categories}) => {
    if (!categories || categories.length === 0) {
      return <Typography variant="body2" style={{color: 'black'}}>No
        categories</Typography>;
    }

    return (
      <div style={divCustomCategoriesStyle}>
        {categories.map((category, index) => (
          <div key={`topic-${index}`} style={divTooltipCategoriesStyle}>
            <Tooltip
              title={<CategoryTooltip category={category} index={index}/>}>
              <div key={`tt-wrapper-${index}`}>
                <CategoryChip category={category} index={index}
                              amountCategories={categories.length}/>
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    );

  }

  return (
    <Paper key={`custom-segmentation-${segmentIndex}`}
           sx={{p: 2, m: 1, position: 'relative'}}>
      <IconButton
        color="error"
        sx={{position: 'absolute', right: 8, top: 8}}
        onClick={() => handleDeleteSegment(segmentIndex)}
      >
        <DeleteIcon/>
      </IconButton>
      <Grid
        container
        item
        spacing={2}
        direction={'column'}
        sx={{mt: 2}}
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Custom category label"
            value={segment.label || ""}
            onChange={(e) => {
              const newLabel = e.target.value;
              if (newLabel) {
                let localSegment = {...segment};
                localSegment.label = newLabel;
                handleUpdateSegment(localSegment, segmentIndex);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} id="container-topic-category-section"
              style={{width: "100%", flex: 1}}>
          <Box display="flex" flexWrap="wrap" gap={1}
               flexDirection="column"
               alignItems="flex-start">
            <CustomCategories categories={segment.categories}/>
          </Box>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={6}>
            <FormControl variant="outlined">
              <Button
                color='secondary'
                variant="contained"
                id="infer-category-btn"
                onClick={() => setOpen(true)}>
                Manage Categories
              </Button>
              {(open) && (<ModalCategoryEdit
                topics={segment.categories}
                isCategoriesCustom={true}
                label={segment.label}
                onCategoriesChange={async (categories) => {
                  let localSegment = {...segment};
                  localSegment.categories = categories;
                  handleUpdateSegment(localSegment, segmentIndex);
                }}
                handleClose={() => setOpen(false)}
              />)}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Button
                onClick={async () => handleClassification(segment.classificationVariable)}
                variant="contained"
                style={{width: '100%'}}>{t('segment_answers')}</Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CustomAISegment;
