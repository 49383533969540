import {
  Fab,
  styled,
  ToggleButtonGroup,
  toggleButtonGroupClasses
} from '@mui/material';

export const MuiDivChatWindows = styled("div")(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(10),
  right: theme.spacing(0.5),
  width: '26.5%',
  height: '70%',
  backgroundColor: 'white',
  border: '1px solid #ccc',
  borderRadius: '4px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  zIndex:999
}));

export const MuiFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: 0,
}));

export const MUIToggleGroup=styled(ToggleButtonGroup)(({ theme }) => ({
  height:'32px',
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: 16,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));
