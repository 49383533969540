import {Button, Grid, Link, Typography} from '@mui/material';
import {NavLink} from 'react-router-dom';
import {
  MuiContainerWrapper,
  MuiGridAction,
  MuiGridWrapper
} from './styles/error';


function NotFound() {

  return (
    <MuiContainerWrapper>
      <MuiGridWrapper
        container
        direction='column'
        spacing={2}
        justifyContent='center'
        alignContent='space-between'
      >
        <Grid item>
          <Typography variant='h5'>Error</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h3'>
            Whoops!
            <br />
            Something's gone wrong.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            Not to worry
            <br />
            Use Location Intelligence to find your way home.
          </Typography>
        </Grid>
        <MuiGridAction item>
          <Link to='/' component={NavLink} underline='none'>
            <Button variant='contained' color='primary' size='large'>
              Take me home
            </Button>
          </Link>
        </MuiGridAction>
      </MuiGridWrapper>
    </MuiContainerWrapper>
  );
}

export default NotFound;
