import _ from 'lodash';

export const schemeChanged = function(newScheme, oldScheme) {
  // if first time save..
  if (oldScheme === null && newScheme) {
    return true;
  }

  if (oldScheme.name !== newScheme.name) {
    return true;
  }
  if (oldScheme.weight!==newScheme.weight) {
    return true;
  }

  // collection
  if (oldScheme.collection !== newScheme.collection) {
    return true;
  }
  if (oldScheme.dataset !== newScheme.dataset) {
    return true;
  }
  if (oldScheme.description!== newScheme.description) {
    return true;
  }

  if (!_.isEqual(oldScheme.schemeCollectionConfig, newScheme.schemeCollectionConfig)) {
    return true;
  }

  if (!_.isEqual(oldScheme.segmentation_variables, newScheme.segmentation_variables)) {
    return true;
  }
  if(!_.isEqual(oldScheme.statistic_variables, newScheme.statistic_variables)) {
    return true;
  }

  // policies
  if (newScheme.policies.length !== oldScheme.policies.length) {
    return true;
  } else if (newScheme.policies.length === oldScheme.policies.length) {

    const diff = oldScheme.policies.filter((d, i) => {
      return !_.isEqual(d, newScheme.policies[i]);
    }).map(d => d.id);
    return diff.length ? diff : false;
  }

  return false;
};


export function generateID() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
