// External library imports
import * as React from 'react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Grid,
  Hidden,
  Link as MaterialLink,
  Menu,
  MenuItem,
} from '@mui/material';

// Internal component and function imports
import {useTranslation} from 'components/providers/TranslationProvider';
import {useAuth} from 'components/providers/AuthProvider';

// Style and asset imports
import {
  MuiAvatar,
  MuiTypography,
  MuiTypographyLevelUser
} from './styles/userMenu';
import FaceIcon from '@mui/icons-material/Face';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation();
  const { logout, user } = useAuth();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const getUserType = () => {
    return user && user.type === 'super_admin' ? 'Super Admin' :
           user && user.type === 'admin' ? 'Administrator' :
           user && user.type === 'level_1' ? 'User' :
           user && user.type === 'level_2' ? 'User' :
           user && user.type === 'level_3'? 'User' : ''
  }

  const handleMenu = (event) => setAnchorEl(!anchorEl ? event.currentTarget : null);

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleTranslation = () => {
    navigate('/translations');
    handleClose();
  };

  return (
    <>
      <MaterialLink
        sx={{textDecoration:'none'}}
        edge='end'
        aria-label='account of current user'
        aria-controls='menu-login'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <Grid container alignItems='center'  wrap={'nowrap'} gap={1}>
          <Grid item>
            <MuiAvatar>
              <FaceIcon color='primary' fontSize='medium'/>
            </MuiAvatar>
          </Grid>
          <Grid container alignItems='left'  wrap={'nowrap'} direction="column">
            <Hidden smDown>
              <Grid xs={12} item>
                <MuiTypography variant='body1' color='white' noWrap>
                  {user?.name}
                </MuiTypography>
              </Grid>
              <Grid xs={12} item sx={{marginTop:'-9px'}}>
                <MuiTypographyLevelUser variant='caption' color='white' noWrap>
                  {getUserType()}
                </MuiTypographyLevelUser>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </MaterialLink>
      <Menu
        id='menu-login'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {user && user.type === 'super_admin' && (
          <MenuItem onClick={handleTranslation}>
            <MuiTypography variant="subtitle1"
                           color='inherit' component="span"> {t('translations')}
            </MuiTypography>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <MuiTypography variant="subtitle1"
                         color='inherit' component="span"> {t('log_out')}
          </MuiTypography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
