import {Button, Card, Paper, styled} from "@mui/material";

export const MuiPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export const MuiCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));
