import {useQuery} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

export const fetchCollection = ({ user_id, collection }) =>
  axios
    .post(apiPaths.get_collection, { user_id, collection }, status200)
    .then((resp) => resp.data);

export const useCollection = (data) =>
  useQuery(['collection', data.collection], () => fetchCollection(data), {
    enabled: data.enabled,
    refetchOnWindowFocus: false,
  });
