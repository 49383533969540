import * as React from 'react';
import useTranslations from 'api/hooks/useTranslations';
import { useDispatch } from 'react-redux';
import { setUserTranslations } from '../../store/appSlice';
const TranslationContext = React.createContext();

const DEFAULT_LANGUAGE = 'english';
const EXPIRATION_HOURS = 8;

const TranslationProvider = ({ children }) => {
  const [languages, setLanguages] = React.useState([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState(DEFAULT_LANGUAGE);
  const [translations, setTranslations] = React.useState();
  const [forceFetch, setForceFetch] = React.useState(false);
  const dispatch = useDispatch();

  const { data, isSuccess, refetch } = useTranslations();

  const setTranslationStates = (translationPages, lng = DEFAULT_LANGUAGE) => {
    setTranslations(translationPages);
    setLanguages(
      translationPages && Object.keys(translationPages[0]).filter((k) => k !== 'key' && k !== '_id')
    );
    setSelectedLanguage(lng);
  };

  const getTranslationFromLocalStorage = () => ({
    lng: localStorage.getItem('lng'),
    pageTranslations: JSON.parse(localStorage.getItem('translations')),
  });

  const loadTranslation = () => {
    const { lng, pageTranslations } = getTranslationFromLocalStorage();
    setTranslationStates(pageTranslations, lng);
  };

  const refresh = React.useCallback(async () => {
    await refetch();
    setForceFetch(true);
  }, [refetch]);

  const shouldLoadTranslation = () => {
    const createdTranslationDate = new Date(localStorage.getItem('createdTranslationDate'));

    if (createdTranslationDate) {
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - createdTranslationDate);
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      const isValidTranslation = diffHours < EXPIRATION_HOURS;

      const { lng, pageTranslations } = getTranslationFromLocalStorage();

      return isValidTranslation && lng && pageTranslations;
    }
    return true;
  };

  const translate = React.useCallback(
    (key) => {
      const trans =
        translations && translations.find((t) => t.key === key)
          ? translations.find((t) => t.key === key)[selectedLanguage]
          : undefined;
      return trans;
    },
    [translations, selectedLanguage]
  );

  const changeLanguage = React.useCallback((lng) => setSelectedLanguage(lng), []);

  React.useEffect(() => {
    const initializeTranslation = async () => {
      if (shouldLoadTranslation()) {
        loadTranslation();
      } else {
        setForceFetch(true);
      }
    };
    initializeTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isSuccess && forceFetch) {
      setTranslationStates(data);
      localStorage.setItem('createdTranslationDate', new Date());
      setForceFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, forceFetch]);

  React.useEffect(() => {
    localStorage.setItem('lng', selectedLanguage);
    const userTranslations = JSON.parse(localStorage.getItem('translations'));
    dispatch(setUserTranslations({ lng: selectedLanguage, allTranslations: userTranslations }));
  }, [selectedLanguage]);

  React.useEffect(() => {
    if (translations) {
      localStorage.setItem('translations', JSON.stringify(translations));
      setTranslations(translations);
      setLanguages(Object.keys(translations[0]).filter((k) => k !== 'key' && k !== '_id'));
    }
  }, [translations]);

  const value = React.useMemo(
    () => ({
      t: translate,
      languages: languages,
      lng: selectedLanguage,
      changeLanguage,
      setForceFetch: refresh,
    }),
    [translate, languages, selectedLanguage, changeLanguage, refresh]
  );

  return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>;
};

export const useTranslation = () => React.useContext(TranslationContext);
export default TranslationProvider;
