import {Container, styled} from '@mui/material';

export const MuiContainer = styled(Container)(({ theme }) => ({
  paddingTop: 20,
}));

export const buttonCancelStyle = {
  color:'#005F64',
  borderRadius: '20px'
}
