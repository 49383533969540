import * as React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  CircularProgress,
  Grid,
  lighten,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField as MuiTextField,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import Modal from 'components/common/Modal';
import {
  MuiContainer,
  MuiDescriptionIcon,
  MuiGridInputText,
  MuiLinkIcon
} from '../styles/formModal';
import themeCrowdView from 'theme/crowdView';
import {useTranslation} from 'components/providers/TranslationProvider';
// import Toast from 'components/common/Toast';
import PropTypes from 'prop-types';
import {uploadCollectionS3} from '../../../../api/uploadCollection';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../providers/AuthProvider';
// import useCreateScheme from '../../../../api/hooks/useCreateScheme';
import axios from '../../../../api/axios/axiosInstance';

import {useSnackbar} from 'notistack';
import {OPTION_UPDATE_VARIABLE_DATA} from '../../../../utils/constants';
import {useCollection} from '../../../../api/hooks/useCollection';
import apiPaths from '../../../../api/apiPaths';
import {status200} from '../../../../api/status.utils';
import {MuiButton} from './styles/updateModal';
import {useTheme} from '../../../providers/CustomThemeProvider';


const allowedURLPatterns = [
  /https?:\/\/drive\.google\.com\/.+/i,  // URL de Drive
  /^s3:\/\/.*$/, // URL de S3 de Amazon
  /https?:\/\/(?:www\.)?.+/i,            // URL pública
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const UpdateModal = ({ orgId, option, dataset, open, onClose }) => {
  const [value, setValue] = React.useState(0);
  const [uploadOption, setUploadOption] = React.useState('image_file');
  const [showContent, setShowContent] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [url, setUrl] = React.useState('');
  const [variables, setVariables] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const location = useLocation();
  const { user } = useAuth();
  const userId =
    location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const { data: collection } = useCollection({
    user_id: userId,
    collection: dataset.collection,
    enabled: option === OPTION_UPDATE_VARIABLE_DATA,
  })

  const { t } = useTranslation();
  const { theme } = useTheme();
  const { enqueueSnackbar } = useSnackbar()
  let varsConfig = null

  const schema = yup.object().shape({
    file: yup.mixed(),
    url: yup.string().when([], {
      is: () => uploadOption === 'url',
      then: yup.string().required('url_is_required'),
      otherwise: yup.string().notRequired(),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;

  let handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let handleOnClickUploadOption = (option) => {
    setVariables(null);
    reset({
      datasetName: '',
      file: '',
      url: ''
    });
    setUploadOption(option);
    setShowContent(true);
  };

  const handleUrlChange = (event) => {
    const inputUrlValue = event.target.value;
    // Validar la URL
    let isValid = false;
    for (const pattern of allowedURLPatterns) {
      if (pattern.test(inputUrlValue)) {
        isValid = true;
        break;
      }
    }
    // Establecer el error si la URL no es válida
    if (!isValid) {
      enqueueSnackbar('La URL no es válida', { variant: 'error' })
    } else {
      setUrl(inputUrlValue);
      methods.setValue('url', inputUrlValue)
    }
  };


  const handleFilesChange = (event) => {
    setUploadedFiles([...event.target.files]);
  };

  const handleFileUploadFromS3 = async (formData) => {
    setUploading(true);

    if (dataset.name) {
      const data = {
        user_id: userId,
        update: true,
        s3Info: {
          url: formData.url.trim(),
        },
      };

      if (varsConfig) {
        data.variables = varsConfig
        data.datasetName = dataset.name
      }

      uploadCollectionS3(data).then((res) => {
        if (res && res.status === 200) {
          if (res.data.status === 'error') {
            enqueueSnackbar(res.data.msg, { variant: 'error' })
          } else {
            enqueueSnackbar('Import of dataset with configuration completed', { variant: 'success' })
            setUploading(false);
            onClose();
          }
        }
      });
    }
  };

  const handleUpload = handleSubmit(async (data) => {
    setUploading(true);
    if (dataset.name) {
      if (uploadOption === 'image_file') {
        let file = uploadedFiles[0];
        let formData = new FormData();

        formData.append('image_file', file);
        let axiosResp = await axios.post(`https://api-criteria-sandbox.prosperia.ai/api/users/upload-file`, formData).then((resp) => resp.data);
        if (axiosResp) {
          let dataUrl = {
            url: axiosResp.file
          };
          await handleFileUploadFromS3(dataUrl);
        }
      }
      if (uploadOption === 'url') {
        await handleFileUploadFromS3(data);
      }
    }
  });

  const changeVariableLabel = (data, index, value) => {
    let newVariables = variables.map((v) => {
      if (v.propName === data.propName) {
        v.label = value;
      }
      return v;
    });

    setVariables(newVariables);
  };

  const onSave = async () => {
    setUploading(true);
    axios
      .post(
        apiPaths.set_dataset_label,
        { user_id: orgId, datasetName: dataset.name, variables },
        status200
      )
      .then((resp) => {
        if (resp.status === 200) {
          const data = resp.data

          if (data.status === 'ok') {
            onClose();
          } else {
            enqueueSnackbar(data.msg, { variant: 'error' })
          }
        }
      }).catch(() => {
        enqueueSnackbar('Something was wrong', { variant: 'error' })
      }).finally(() => {
        setUploading(false);
      });
  };

  const handleConfigFileChange = (ev) => {
    const fr = new FileReader();
    fr.onload = e => {
      const configuration = JSON.parse(e.target.result)

      if (Object.keys(configuration).length > 0) {
        let invalidConfigPositions = []
        for (const [key, config] of Object.entries(configuration)) {
          if (!Object.keys(config).includes('category')
          ) {
            invalidConfigPositions.push(key)
          }
        }

        if (invalidConfigPositions.length === 0) {
          varsConfig = configuration
          handleUpload()
        } else {
          enqueueSnackbar(
            'Invalid configuration for this variables: ' + invalidConfigPositions.join(', '),
            { variant: 'error' }
          )
        }
      } else {
        enqueueSnackbar('Invalid configuration file', { variant: 'error' })
      }

      ev.target.value = null;
    }

    if (ev.target.files.length > 0 && ev.target.files[0]) {
      fr.readAsText(ev.target.files[0]);
    }
  }

  React.useEffect(() => {
    if (option === OPTION_UPDATE_VARIABLE_DATA) {
      setValue(1)
    }
  }, [option])

  React.useEffect(() => {
    if (collection && collection.variables) {
      setVariables(collection.variables)
    }
  }, [collection])

  const mapForm = (
    <ThemeProvider theme={themeCrowdView}>
      {/*<AppBar position='static'>*/}
      {/*  <Tabs*/}
      {/*    indicatorColor='secondary'*/}
      {/*    value={value}*/}
      {/*    onChange={(e, newValue) => handleChange(e, newValue)}>*/}
      {/*    <Tab label={t('dataset_upload_options')}*/}
      {/*      style={{ display: option === OPTION_UPDATE_VARIABLE_DATA ? 'none' : '' }}*/}
      {/*      {...a11yProps(0)} />*/}
      {/*    <Tab*/}
      {/*      label={t('dataset_variable_configuration')}*/}
      {/*      style={{ display: option === OPTION_UPDATE_ALL_DATA ? 'none' : '' }}*/}
      {/*      {...a11yProps(1)} />*/}
      {/*  </Tabs>*/}
      {/*</AppBar>*/}
      <TabPanel value={value} index={0}>
        <MuiContainer display='flex'>
          <ThemeProvider theme={themeCrowdView}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card>
                  <CardActionArea
                    data-cy='card_option_file'
                    value='image_file'
                    onClick={() => handleOnClickUploadOption('image_file')}>
                    <Grid
                      container
                      direction='column'
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <MuiDescriptionIcon />

                      <Typography variant='caption' color='textSecondary'>
                        Tipos de archivos admitidos: .csv,.json,.geojson,.parket
                      </Typography>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardActionArea
                    data-cy='card_option_url'
                    value='url'
                    onClick={() => handleOnClickUploadOption('url')}>
                    <Grid
                      container
                      direction='column'
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <MuiLinkIcon />

                      <Typography variant='caption' color='textSecondary'>
                        Tipos de links admitidos: s3, drive, public link
                      </Typography>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
            {showContent ? (<>
              <Grid container spacing={2}>
                {uploadOption === 'image_file' ? (
                  <Grid item xs={12}>
                    <MuiGridInputText item xs={12}>
                      <MuiTextField
                        name='image_file'
                        margin='dense'
                        type='file'
                        variant='outlined'
                        data-cy='image_file'
                        inputProps={{ style: { height: 35 }, accept: '.csv,.json,.geojson,.parket' }}
                        style={{ paddingTop: 5, paddingBottom: 0 }}
                        onChange={(e) => handleFilesChange(e)}
                        fullWidth
                        error={t(errors.file?.message)}
                      />
                    </MuiGridInputText>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <MuiGridInputText item xs={12}>
                        <TextField
                          disabled={uploadOption === 'image_file'}
                          name='url'
                          autoFocus
                          margin='dense'
                          type='text'
                          label={t('url_label')}
                          data-cy='url'
                          variant='outlined'
                          fullWidth
                          value={url}
                          error={t(errors.url?.message)}
                          onChange={handleUrlChange}
                        />
                      </MuiGridInputText>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} container justifyContent='flex-end'>
                <input
                  accept=".json"
                  style={{ display: 'none' }}
                  id="contained-button-file-dataset"
                  multiple
                  type="file"
                  onChange={(e) => handleConfigFileChange(e)}
                />
                <label htmlFor="contained-button-file-dataset">
                  <Button
                    variant='contained'
                    color='primary'
                    component="span"
                    data-cy='upload_dataset_with_config_options'
                    startIcon={uploading && <CircularProgress size={20} color='secondary' />}>
                    Upload with configuration
                  </Button>
                </label>
              </Grid>
            </>) : (<Grid item xs={12}></Grid>)}
          </ThemeProvider>
        </MuiContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {variables !== null && (
          <Grid container>
            <Grid item xs={12}>
              <TableContainer component={Paper} style={{ maxHeight: 444 }}>
                <MuiTable stickyHeader size="small" aria-label='simple table'>
                  <TableBody>
                    {variables?.map((d, index) => (
                      <TableRow key={'d-' + index} data-cy='var_row'>
                        <TableCell component='th' scope='row'>
                          <TextField
                            label="Label"
                            id="outlined-size-small"
                            defaultValue={d.label ? d.label : d.propName}
                            variant="outlined"
                            onChange={(e) => changeVariableLabel(d, index, e.target.value)}
                            size="small" />
                          <Chip avatar={<Avatar style={{ color:'white',
                            backgroundColor: theme.palette.secondary.main}} >V</Avatar>}
                            style={{
                              marginTop: 4,
                              marginLeft: 10,
                              backgroundColor: `${lighten(theme.palette.secondary.main, 0.7)}`}}
                            label={d.propName} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </TabPanel>
    </ThemeProvider>
  );

  const actions = (
    <>
      <MuiButton
        onClick={onClose}
        color='primary'
        variant='text'
        disabled={uploading}
        data-cy='close_upload_dataset'>
        {t('cancel_btn')}
      </MuiButton>
      <MuiButton
        disabled={uploading || !variables}
        onClick={() => onSave()}
        color='primary'
        variant='outlined'
        data-cy='save_upload_dataset'
        startIcon={uploading && <CircularProgress size={20} color='secondary' />}>
        {t('save_generic_btn')}
      </MuiButton>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={onClose}
          maxWidth={'sm'}
          title={'Update Dataset ' + dataset.name}
          actions={actions}>
          {mapForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default UpdateModal;
