import * as React from 'react';
import UploadIcon from '@mui/icons-material/Upload';

import { useTranslation } from '../../providers/TranslationProvider';

import {
  MuiButton, MuiCard, MuiCardActions,
  MuiGrid,
  MuiImg,
  MuiInput
} from './styles/uploadImage';

const UploadImage = ({ handleChange, image, name }) => {
  const { t } = useTranslation();
  return (
    <MuiGrid container>
      <MuiCard sx={{
        borderRadius:'12px'
      }}>
        <MuiCardActions style={{width:'100%', height:'100%'}}>
          <MuiImg
            src={image.type ? URL.createObjectURL(image) : image}
            alt='img'
          />
        </MuiCardActions>
      </MuiCard>
      <MuiGrid container>
        <MuiInput
          name={name}
          accept='image/*'
          id={`contained-button-file-logo-${name}`}
          type='file'
          onChange={handleChange}
          data-cy={`input-file-${name}`}
        />
        <label htmlFor={`contained-button-file-logo-${name}`}>
          <MuiButton
            startIcon={<UploadIcon />}
            variant='outlined'
            component='span'
            data-cy = {`upload-${name}`}>
            {t('upload')}
          </MuiButton>
        </label>
      </MuiGrid>
    </MuiGrid>
  );
};

export default UploadImage;
