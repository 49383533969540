const apiPaths = {
  changePassword: '/users/change-password',
  create_scenario: 'schemes/add-scheme-or-col',
  create_user: '/users/',
  delete_dataset: 'schemes/remove-dataset',
  delete_scenario: 'schemes/remove-scheme-or-col',
  delete_user: '/users/',
  forgotPassword: '/users/forgot',
  login: '/users/login',
  logout: '/users/logout',
  orgs: '/orgs',
  org: '/orgs/find-one?org_id=',
  refreshToken: '/users/refresh',
  reset_password: '/users/reset/:',
  scenario: 'schemes/get-scheme',
  schemes: '/schemes/list-schemes',
  schemes_associated: '/schemes/list-associated',
  scheme: '/schemes/get-scheme',
  copy_scheme: '/schemes/copy-schemes',
  translations: '/translations',
  users_organization: '/users/list-users',
  update_user: '/users/',
  update_scheme: '/schemes/save-scheme',
  upload_dataset: 'schemes/add-dataset',
  upload_img: '/users/upload-image',
  loginSocial: '/users/login-social',
  upload_collection_dataset: '/collection/upload',
  upload_collection_s3: '/collection/upload-from-s3',
  get_import_job: '/import-job/get',
  get_filters: '/collection/get-filters',
  get_collection: '/schemes/get-collection',
  formula_validator: '/schemes/formula-validator',
  set_dataset_label: '/collection/set-dataset-labels',
  infer_topics:'/compute/infer-topics',
  infer_topics_async:'/compute/async-infer-topics',
  infer_subtopics:'/compute/infer-sub-topics',
  topic_icons:'/compute/topics-icons',
  compute_classification:'/compute/classify',
  compute_async_classification:'/compute/async-classify',
  initialize_partial_classification:'/compute/initialize-partial-classification',
  compute_partial_classification:'/compute/partially-classify',
  async_compute_partial_classification:'/compute/async-partially-classify',
  aggregate_answers:'/widgets/aggregate-answers',
  aggregate_topics_segments:'widgets/aggregate-label-segments',
  aggregate_category:'/widgets/aggregate-category',
  aggregate_statistic:'/widgets/aggregate-statistic',
  aggregate_list:'/widgets/aggregate-list',
  query_answers:'/compute/query-answers',
  query_similarity:'/compute/query-similarity',
  progress_status:'/progress/get',
  init_progress:'/progress/create',
  qa_history: '/widgets/get-qa-history',
  update_qa_history: '/widgets/update-qa-history',
  delete_qa_history: '/widgets/delete-qa-history',
  engine_token:'/users/engine-token'
};

export default apiPaths;
