import {Grid} from "@mui/material";
import CollapsibleCard from "./CollapsibleCard";


const QAHistory = ({
                     history,
                     widgetId,
                     onDeleteHistory,
                     onSelectHistory,
                     selectedHistory,
                     updateHistoryItem
                   }) => {

  const handleCardSelected = (item) => {
    const newSelection = selectedHistory?.userQuery !== item?.userQuery ? item : null;
    if (onSelectHistory) {
      onSelectHistory(newSelection);
    }
  }

  return (
    <>
      {(history && history.length > 0) && (
        <Grid container
              sx={{width: '100%', overflowY: 'auto', maxHeight: '100%',paddingLeft:'8px'}} rowGap={'16px'}>
          {history.map((item, index) => {
            return (<CollapsibleCard
              key={`qa-history-${index}-${item.policyId}`}
              index={index}
              item={item}
              expand={selectedHistory?.userQuery === item.userQuery}
              widgetId={widgetId}
              onDelete={onDeleteHistory || null}
              onCardSelected={handleCardSelected}
              updateHistoryItem={updateHistoryItem || null}
            />)
          })}
        </Grid>
      )}
    </>
  )
}

export default QAHistory;
