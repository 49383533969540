import * as React from 'react';

const LoadingContext = React.createContext();

const LoadingProvider = ({children}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSearchLoading, setIsSearchLoading] = React.useState(false);
  const [isTopicInferenceLoading, setIsTopicInferenceLoading] = React.useState(false);
  const [isClassificationLoading, setIsClassificationLoading] = React.useState(false);
  const [treeMapLoading, setTreeMapLoading] = React.useState({});
  const [title, setTitle] = React.useState('');
  const [progressMessage, setProgressMessage] = React.useState('');
  const [progress, setProgress] = React.useState(0);
  const [showProgressBar, setShowProgressBar] = React.useState(false);

  const value = {
    isLoading,
    isSearchLoading,
    treeMapLoading,
    setIsSearchLoading,
    setIsLoading,
    setTreeMapLoading,
    isTopicInferenceLoading,
    setIsTopicInferenceLoading,
    isClassificationLoading,
    setIsClassificationLoading,
    title,
    setTitle,
    progress,
    setProgress,
    progressMessage,
    setProgressMessage,
    showProgressBar,
    setShowProgressBar
  }
  return (<LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );

};

const useLoading = () => React.useContext(LoadingContext);
export {LoadingContext, useLoading};
export default LoadingProvider
