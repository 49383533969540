import {Button, Grid, styled, Typography} from '@mui/material';

export const MuiButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(3),
  justifySelf: 'end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: '#616161',
  borderColor: '#BDBDBD',
  height:'32px',
  padding:'10px 24px 10px 16px'
}));

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  paddingLeft:'0px !important',
  paddingTop:'0px !important',
  marginLeft:theme.spacing(3),

}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  display:'flex',
  flexDirection:'row',
  justifyContent:'space-between'

}));

export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize:21,
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:500
}));
