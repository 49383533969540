import * as React from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Slider,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import ModalCategoryEdit from './common/ModalCategoryEdit';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {useTranslation} from '../../../../../../providers/TranslationProvider';
import TopicImageManager from "./art/ImageGeneration";
import QuestionInfo from "./general/QuestionInfo";
import {TopicExtraction} from "./topics/TopicExtraction";
import {AISegments} from "./aiSegments/AISegments";


export default function Question({
                                   question,
                                   dataRegion,
                                   parentCandidates,
                                   variables,
                                   handleQuestionChange,
                                   collection,
                                   weight
                                 }) {
  const {t} = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };


  const handleFinish = async () => {
    handleNext();
  }


// Stepper Form Children
  const steps = [
    {
      label: `${t('survey_question')}:`,
      visible: true,
      description: (
        <Grid container spacing={2}>
          <QuestionInfo question={question} variables={variables}
                        handleQuestionChange={handleQuestionChange}/>
        </Grid>
      ),
      dataCy: 'step-policy-name',
    },
    {
      label: t('discussed_topics'),
      dataCy: 'step-topic-inference',
      visible: true,
      description: (
        <TopicExtraction question={question} variables={variables}
                         handleQuestionChange={handleQuestionChange}
                         dataRegion={dataRegion} collection={collection}
                         parentCandidates={parentCandidates} weight={weight}/>
      )
    },
    {
      label: 'Topic images',
      dataCy: 'step-image-section',
      visible: true,
      description: (
        <TopicImageManager policy={question} dataset={collection}
                           answerVar={question.answerVar}
                           updatePolicy={(updatedPolicy) => {
                             const localPolicy = _.cloneDeep(question);
                             console.log("Updated policy", updatedPolicy);
                             localPolicy.topics = updatedPolicy.topics;
                             localPolicy.imageSettings = updatedPolicy.imageSettings;
                             handleQuestionChange(localPolicy);
                           }}/>
      )
    },
    {
      label: t('custom_segmentation'),
      dataCy: 'step-category-section',
      visible: true,
      description: (
        <AISegments question={question} collection={collection}
                    dataRegion={dataRegion}
                    handleQuestionChange={handleQuestionChange}/>
      )
    },

  ];

  return (
    <>
      <Box sx={{flexGrow: 1}} style={{height: 'auto'}}>
        <Stepper activeStep={activeStep} nonLinear orientation="vertical">
          {steps.filter(step => step.visible).map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                data-cy={step.dataCy}
                onClick={handleStep(index)}
                style={{cursor: 'pointer'}}
                optional={
                  index === 3
                    ? (<Typography variant="caption">Last step</Typography>)
                    : null
                }>
                {step.label}
              </StepLabel>
              <StepContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box>{step.description}</Box>
                  </Grid>
                  <Grid container item xs={12} justifyContent="flex-start">
                    <Button
                      color='secondary'
                      variant="contained"
                      onClick={index === steps.length - 1 ? handleFinish : handleNext}>
                      {index === steps.length - 1 ? t('finish') : t('continue')}
                    </Button>
                    <Button
                      style={{marginLeft: 6}}
                      variant="contained"
                      disabled={index === 0}
                      onClick={handleBack}>
                      {t('go_back')}
                    </Button>
                  </Grid>
                </Grid>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{p: 3}}>
            <Typography>All steps completed</Typography>
          </Paper>
        )}
      </Box>
    </>
  );
}
