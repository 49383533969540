import React, {useState} from 'react';
import Popover from '@mui/material/Popover';
import { infoIcon, MuiIconClose } from './styles/navigationMenu';
import {InfoOutlined} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid } from '@mui/material';
import {MuiTypography} from "./styles/navigationMenu";
import {useTranslation} from "../providers/TranslationProvider";

const InfoButtonWithPopover = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {t} = useTranslation();
  const {scheme, styles: sx} = props

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <InfoOutlined sx={infoIcon} onClick={handleClick}/>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{minWidth: '360px'}}
      >
        <Grid container direction={'row'} alignItems={'flex-start'} spacing={3}
              justifyContent={'center'}>
          <Grid item xs={10}>
            <Box display="flex" flexDirection="column" sx={{width: '280px', margin:'24px 56px 0px 24px'}}>
              <MuiTypography variant='h6' >{scheme.name}</MuiTypography>
              <MuiTypography variant='caption'>{t('created_date')}</MuiTypography>
              <MuiTypography variant='caption'>{scheme.createdAt ? scheme.createdAt : t('not_provided')}</MuiTypography>
            </Box>
            <Box sx={{width: '312px', margin:'0px 24px 24px 24px'}}>
              <MuiTypography variant='body1'>{scheme.description ? scheme.description: t('not_provided')}</MuiTypography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <MuiIconClose aria-label="close" onClick={handleClose}>
              <CloseIcon fontSize='small'/>
            </MuiIconClose>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default InfoButtonWithPopover;
