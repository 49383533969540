import * as React from 'react';
import {Grid,} from '@mui/material';
import {Outlet, useLocation} from 'react-router-dom';
import {useAuth} from 'components/providers/AuthProvider';
import logo from '../../../../assets/img/logo.0c7a85ba.png';
import {
  MuiContainerNavBar,
  MuiGridContainer,
  MuiGridImgContainer,
  MuiImage,
  MuiTitle
} from './styles/workspace';


const Workspace = () => {
  const location = useLocation();
  const { user, isLoading } = useAuth();
  const isSpecificationView = location.pathname.split('/').includes('specification')

  return (
    <MuiContainerNavBar>
      <Grid container>
        {!isSpecificationView && (
          <MuiGridImgContainer item xs={3}>
            <MuiTitle>{user?.org.name} / {user?.name}</MuiTitle>
            {isLoading ? (
              <>
                <MuiImage src={logo} alt={'logo'}/>
              </>
            ) : (
              <>
                <MuiImage src={user?.org.attrs.profile_img_url} alt={'logo'}/>
              </>
            )}
          </MuiGridImgContainer>
        )}
        <MuiGridContainer item xs={isSpecificationView ? 12 : 9}>
          <Outlet />
        </MuiGridContainer>
      </Grid>
    </MuiContainerNavBar>
  );
};
export default Workspace;
