import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from '../../../../api/axios/axiosInstance';
import apiPaths from '../../../../api/apiPaths';
import {status200} from '../../../../api/status.utils';
import LoadingProvider, {useLoading} from "../../../providers/LoadingProvider";
import {useTranslation} from "../../../providers/TranslationProvider";
import {Box, Divider, Grid, IconButton, InputAdornment,} from "@mui/material";
import QAHistory from "./QAHistory";
import {
  dividerStyles,
  MuiLinearProgress,
  MuiQAGrid,
  MuiTextField, QAButton, QAIcon
} from "./styles/QAWidget";
import {MuiSearchIcon} from '../../styles/popover';
import enginePaths from "../../../../api/enginePaths";
import axiosEngineInstance from "../../../../api/axios/axiosEngineInstance";
import {theme} from "antd";

/**
 * Renders a <HistogramWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 */
function QASimilarityWidget({
                              id,
                              dataSource,
                              dataRegion,
                              column,
                              weight,
                              policyId,
                              statVars,
                              segVars
                            }) {

  const [queryText, setQueryText] = useState("");
  const [tempQueryText, setTempQueryText] = useState("");
  const activeSimilarityFilters = useSelector(state => state.app.similarityFilter);
  const activeSimilarityQuery = useSelector(state => state.app.similarityQuery);
  const activeInsightsFilters = useSelector(state => state.app.insightsFilter)
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSegmentationFilters = useSelector(state => state.app.segmentationFilter);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const [messageHistory, setMessageHistory] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const {setIsSearchLoading, isSearchLoading} = useLoading();
  const [isLoading, setIsLoading] = useState(false);
  const activePolicyTab = useSelector(state => state.app.selectedScenarioTab);
  const {t} = useTranslation();

  const [historyMaxHeight, setHistoryMaxHeight] = useState('580px'); // Default height, adjust as needed

  useEffect(() => {
    function updateSize() {

      // Capture the window height
      const windowHeight = window.innerHeight;
      // Adjust these values based on your layout specifics
      const knownHeights = 285; // Sum of heights of other components (loading bar, pagination, etc.)
      const calculatedHeight = windowHeight - knownHeights;
      if (calculatedHeight > 0) {
        setHistoryMaxHeight(`${calculatedHeight}px`);
      }
    }

    updateSize();
    window.addEventListener('resize', updateSize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', updateSize);
  }, [queryText, selectedHistory]); // Empty dependency array means this effect runs once on mount

  const fetchData = async (data) => {
    return await axiosEngineInstance.post(enginePaths.qa, data, status200).then((resp) => {
      return resp.data
    });
  }

  const fetchMessageHistory = async () => {
    return await axios.get(`${apiPaths.qa_history}?widget_id=${id}`, status200).then((resp) => {
      return resp.data
    })
  }

  const deleteMessageHistory = async (userQuery) => {
    return await axios.delete(`${apiPaths.delete_qa_history}?user_query=${userQuery}&widget_id=${id}`, status200).then((resp) => resp.data)
  }
  const updateMessageHistory = async (data) => {
    return await axios.post(apiPaths.update_qa_history, data, status200).then((resp) => {
      return resp.data
    })
  }

  function currentHistoryFilters(similarity = (activeSimilarityFilters[policyId] || {}), statistics = activeStatisticFilters, segmentation = activeSegmentationFilters, treeMap = treeMapFilter) {
    const findMinDecimalPlaces = (low, high) => {
      let decimalPlaces = 0;
      while (parseFloat(low.toFixed(decimalPlaces)) === parseFloat(high.toFixed(decimalPlaces))) {
        decimalPlaces++;
      }
      return decimalPlaces;
    };

    const createFilterObject = (type, prettyText, value, key) => ({
      type,
      prettyText,
      value: {[key]: value},
      policyId
    });

    const processRangeFilter = (filter, type, varList) => {
      return Object.keys(filter).reduce((acc, key) => {
        if (filter[key]) {
          const {low, high} = filter[key];
          let minDecimals = findMinDecimalPlaces(low, high);
          let varLabel = type === 'statistic' ? varList.find(varItem => varItem.name === key)?.label : "Similarity";
          let prettyText = `${low.toFixed(minDecimals)} < ${varLabel} < ${high.toFixed(minDecimals)}`;
          const filterObj = createFilterObject(type, prettyText, filter[key], key);
          if (type === 'similarity') {
            filterObj.similarityQuery = activeSimilarityQuery[policyId] || ''
          }
          acc.push(filterObj);
        }
        return acc;
      }, []);
    };

    const processSegmentationFilters = (filter, type, segmentationVariables) => {
      return Object.keys(filter).reduce((acc, key) => {
        if (filter[key]) {
          let values = filter[key].map(val => String(val)).join(" | ");
          let varLabel = segmentationVariables.find(varItem => varItem.name === key)?.label;
          let prettyText = type === 'treemap' ? `Topic: ${values}` : `${varLabel}: ${values}`;
          acc.push(createFilterObject(type, prettyText, filter[key], key));
        }
        return acc;
      }, []);
    };
    let localTreeMapFilter = {};
    if (treeMap) {
      Object.keys(treeMap).forEach(key => {
        Object.assign(localTreeMapFilter, treeMap[key])
      });
    }
    let filters = [];
    filters.push(...processRangeFilter(similarity, "similarity", statVars));
    filters.push(...processRangeFilter({...(statistics)}, "statistic", statVars));
    filters.push(...processSegmentationFilters({...(segmentation)}, "segmentation", segVars));
    filters.push(...processSegmentationFilters(localTreeMapFilter, "treemap", segVars));//treeMapFilter[policyId] || {}
    filters.push(...processSegmentationFilters((activeInsightsFilters[policyId] || {}), "insights", segVars));

    return filters;
  }


  const generate_query_response = async () => {
    if (!queryText || queryText === "") {
      setSelectedHistory(null);
      return;
    }
    let localSegmentationFilters = {...activeSegmentationFilters, ...(activeInsightsFilters[policyId] || {})};
    Object.keys(treeMapFilter).forEach(key => {
      Object.assign(localSegmentationFilters, treeMapFilter[key]);
    });
    let localFilters = {
      statistic: {...activeStatisticFilters},
      segmentation: localSegmentationFilters,
      similarity: activeSimilarityFilters[policyId] || {}
    };

    try {
      let localData = await fetchData({
        surveyId: dataSource,
        questionId: column,
        weight: weight,
        query: queryText || " ",
        statistics: localFilters.statistic,
        segmentation: localFilters.segmentation,
        similarity: localFilters.similarity,
        similarityQuery: activeSimilarityQuery[policyId] || '',
        region:dataRegion||"US"
      });

      const {query_response} = localData;
      if (queryText && query_response) {
        let filters = currentHistoryFilters();
        const historyItem = createHistoryItem(query_response, filters);
        console.log("History item", historyItem);
        setSelectedHistory(historyItem);
        const updatedHistory = await updateMessageHistory(historyItem);
        if (updatedHistory && updatedHistory.history) {
          setMessageHistory(updatedHistory.history);
          setSelectedHistory(historyItem);
        }
      }

    } catch (err) {
      console.error("Error fetching similarity data", err);
    }
  };


  const createHistoryItem = (localQueryResponse, filters) => ({
    widgetId: id,
    userQuery: queryText,
    aiAnswer: localQueryResponse,
    filters: filters,
    policyId: policyId
  });

  useEffect(async () => {
    if (activePolicyTab === policyId) {
      if (!selectedHistory && queryText) {
        setIsLoading(true);
        setIsSearchLoading(true);
        await generate_query_response();
        setIsLoading(false);
        setIsSearchLoading(false);
      }
    }
  }, [queryText]);


  useEffect(async () => {
    if (!messageHistory || messageHistory.length === 0) {
      setIsLoading(true);
      const localHistory = await fetchMessageHistory();
      if (localHistory && localHistory.history) {
        setMessageHistory(localHistory.history);
      }
      setIsLoading(false);

    }
  }, [id]);


  const handleDeleteHistory = async (queryText) => {
    setIsLoading(true);
    if (selectedHistory?.userQuery === queryText) {
      await handleSelectFromHistory(null);
    }
    const updatedHistory = await deleteMessageHistory(queryText)
    if (updatedHistory && updatedHistory.history) {
      setMessageHistory(updatedHistory.history);
    }
    setIsLoading(false);
  }
  const handleSelectFromHistory = (historyItem) => {
    if (historyItem) {
      setQueryText(historyItem.userQuery);
    } else {
      setQueryText('');
    }
    setSelectedHistory(historyItem);
  }


  const handleSearchChange = (event) => {
    const inputText = event.target.value;
    setTempQueryText(inputText);
    if (inputText === '' && inputText !== queryText) {
      setQueryText(inputText);
    }
  };

  const submitQuery = async () => {
    if (queryText !== tempQueryText) {
      setQueryText(tempQueryText);
      setTempQueryText('');
    } else if (queryText && selectedHistory) {
      setIsLoading(true);
      setIsSearchLoading(true);
      setSelectedHistory(null);
      await generate_query_response();
      setIsLoading(false);
      setIsSearchLoading(false);
    }
  }

  const refreshQuery = async () => {
    setIsLoading(true);
    await generate_query_response();
    setIsLoading(false);
  }


  return (
    <Box
      sx={{width: '100%', height: '100%', padding: '0px 5px 0px 5px'}}>
      <LoadingProvider>
        {(isLoading) ? <MuiLinearProgress/> : null}
        <Grid container item direction='row' xs={12}
              alignItems='center' justifyContent={'flex-start'}
              sx={{width: '100%', marginTop: '18px'}}>
          <Grid item xs={12} alignItems={'flex-start'}>
            <MuiTextField
              variant="standard"
              value={tempQueryText}
              onChange={(!isLoading && !isSearchLoading) ? handleSearchChange : () => {
              }}
              placeholder={t('search_field')}
              disabled={isLoading || isSearchLoading}
              multiline={true}
              maxRows={3}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                  >
                    <QAButton
                      onClick={() => submitQuery()}
                      disabled={isLoading || isSearchLoading}>
                      <QAIcon/>
                    </QAButton>
                  </InputAdornment>
                ),
                style: {alignItems: 'center', minHeight: '30px'},
                disableUnderline: true,
              }}
              style={{flexGrow: 1, width: '100%'}}
            />
          </Grid>
        </Grid>
        <Divider
          style={dividerStyles}/>
        <MuiQAGrid item xs={12} aria-disabled={isLoading}
                   maxHeight={historyMaxHeight}>
          {(messageHistory && messageHistory.length > 0) && (
            <QAHistory
              history={messageHistory}
              widgetId={id}
              selectedHistory={selectedHistory}
              onSelectHistory={handleSelectFromHistory}
              onDeleteHistory={handleDeleteHistory}
              updateHistoryItem={refreshQuery}
            />
          )}
        </MuiQAGrid>
      </LoadingProvider>
    </Box>
  );
}


export default QASimilarityWidget;
