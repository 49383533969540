import {useQuery} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchMaps = (id) =>
  axios.post(apiPaths.schemes, { user_id: id }, status200).then((resp) => resp.data);

const useMaps = (id) =>
  useQuery(['maps', id], () => fetchMaps(id), {
    enabled: Boolean(id),
  });

export default useMaps;
