import * as React from 'react';
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Toast = ({
  message,
  handleClose,
  severity,
  open = false,
  vertical = 'top',
  horizontal = 'right',
  showTime = 6000,
}) => (
  <Snackbar
    open={open}
    autoHideDuration={showTime}
    onClose={handleClose}
    anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
  >
    <MuiAlert onClose={handleClose} severity={severity}>
      {message}
    </MuiAlert>
  </Snackbar>
);

export default Toast;
