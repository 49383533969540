import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import FormModal from './FormModal';

const UpdateTranslation = () => {
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const createQuery = query.get('edit');
    setOpen(createQuery === null ? false : true);
  }, [query]);

  const handleClose = () => {
    query.delete('edit');
    navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
  };

  return (
    <>
      {open && (
        <FormModal action='update' open={open} onClose={handleClose} translation={location.state} />
      )}
    </>
  );
};
export default UpdateTranslation;
