import * as React from 'react';
import {usePermissions} from 'components/hooks/usePermissions';

import NewUser from './NewUser';
import {MuiGrid} from '../styles/user';
import {Grid} from '@mui/material';
import {MuiGridTitle, MuiTypographyTitle} from '../styles/actionBar';
import {useTranslation} from '../../../providers/TranslationProvider';

const ActionBar = () => {
  const { t } = useTranslation();
  const { hasPermissionFor } = usePermissions();

  return (
    <MuiGrid container >
      <MuiGridTitle item xs={10}>
        <MuiTypographyTitle variant='subtitle1'>
          {t('user_list')}
        </MuiTypographyTitle>
      </MuiGridTitle>
      <Grid item xs={2} sx={{ paddingTop:'0px !important'}} >
        {hasPermissionFor('create_users') && <NewUser />}
      </Grid>
    </MuiGrid>
  );
};

export default ActionBar;
