import {useMutation, useQueryClient} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const updateOrganization = (data) =>
  axios.put(apiPaths.orgs, data, status200).then((resp) => resp.data);

const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation((values) => updateOrganization(values), {
    onSuccess: () => {
      queryClient.resetQueries('organizations');
    },
    onError: (error) => {
      console.log(error);
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries('organizations');
    },
  });
};

export default useUpdateOrganization;
