import * as React from 'react';
import {Container, Grid, TableContainer,} from '@mui/material';
import useTranslations from 'api/hooks/useTranslations';
import {useLocation, useNavigate} from 'react-router-dom';

import internationalization from 'assets/img/internationalization.jpg';
import {useTranslation} from 'components/providers/TranslationProvider';
import Table from '../organizations/common/Table';
import NewTranslation from './NewTranslation';
import UpdateTranslation from './UpdateTranslation';
import DeleteTranslation from './DeleteTranslation';
import {
  containerStyle,
  MuiGridButtonsContainer,
  MuiGridContainer,
  MuiGridImgContainer,
  MuiGridPaper,
  MuiImg,
  MuiTitle
} from './styles/translation';
import TPagination from '../organizations/common/TPagination';

const Translations = () => {
  const { t, lng } = useTranslation();
  const { data, isLoading } = useTranslations();
  const location = useLocation();
  const navigate = useNavigate();

  const columns = ['Key', `${t('language')}: ${lng}`, t('actions')];
  const row = ['key', `${lng}`];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState(new Array(columns.length).fill(''));

  const handleDelete = (id, translation) =>
    navigate(`${location.pathname}?delete=${id}`, { replace: true, state: translation });

  const handleEdit = (id, translation) =>
    navigate(`${location.pathname}?edit=${id}`, { replace: true, state: translation });

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate the current page data based on the pagination settings
  const filteredData = data
    ? data?.filter((item) => {
      const lowerCaseSearchTerms = searchTerm.map((term) => term.toLowerCase());
      const itemString = `${item.key} ${item.english} ${item.spanish}`.toLowerCase();
      return lowerCaseSearchTerms.every((term) => itemString.includes(term));
    })
    : [];

  const offset = page * rowsPerPage;
  const currentPageData = filteredData.slice(offset, offset + rowsPerPage);

  const handlePreviousPage = () =>
    setPage((prevPage) => Math.max(0, prevPage - 1));


  const handleNextPage = () =>
    setPage((prevPage) => Math.min(Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1, prevPage + 1));


  const handleFirstPage = () => setPage(0);

  const handleLastPage = () => {
    const lastPage = Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1);
    setPage(lastPage);
  };

  const handleSearch = (event, index) => {
    const newSearchTerms = [...searchTerm];
    newSearchTerms[index] = event.target.value;
    setSearchTerm(newSearchTerms);
    setPage(0);
  };

  return (
    <Container sx={containerStyle}>
      <Grid container>
        <MuiGridImgContainer item xs={3} >
          <MuiTitle>{t('translations')}</MuiTitle>
          <MuiGridPaper container >
            <MuiImg src={internationalization} alt={'logo'}/>
          </MuiGridPaper>
        </MuiGridImgContainer>

        <MuiGridContainer item xs={9}>
          {process.env.NODE_ENV === 'development' && (
            <MuiGridButtonsContainer container spacing={2}>
              <NewTranslation />
            </MuiGridButtonsContainer>
          )}
          <UpdateTranslation />
          {process.env.NODE_ENV === 'development' && <DeleteTranslation />}

          <TableContainer sx={{background:'#FAFAFA'}}>
            <Table
              handleSearch={handleSearch}
              searchTerm={searchTerm}
              data={currentPageData}
              row={row}
              columns={columns}
              onDelete={handleDelete}
              showDelete={process.env.NODE_ENV === 'development'}
              onEdit={handleEdit}
              showEdit
              isLoading={isLoading}
            />
          </TableContainer>
          <TPagination
            data={filteredData}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleFirstPage={handleFirstPage}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handleLastPage={handleLastPage}
          />
        </MuiGridContainer>
      </Grid>
    </Container>
  );
};

export default Translations;
