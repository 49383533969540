import * as React from 'react';
import {
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  TableContainer,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import {useLocation, useNavigate} from 'react-router-dom';

import useSchemes from 'api/hooks/useSchemes';
import {usePermissions} from 'components/hooks/usePermissions';
import {useTranslation} from 'components/providers/TranslationProvider';
import Table from '../common/Table';
import {useAuth} from 'components/providers/AuthProvider';
import FormModal from './FormModal';
import DeleteDataset from './DeleteDataset';
import {SnackbarProvider} from 'notistack';
import axios from "../../../../api/axios/axiosInstance";
import {useQueryClient} from 'react-query';
import UpdateModal from './UpdateModal';
import {OPTION_UPDATE_VARIABLE_DATA} from '../../../../utils/constants';

// Style and asset imports
import {
  MuiButton,
  MuiContainer,
  MuiGridButtonsContainer,
  MuiGridTitle,
  MuiTypographyTitle
} from '../styles/dataset';
import TabsCustoms from '../common/TabsCustoms';
import TPagination from '../common/TPagination';
import EditIcon from "@mui/icons-material/Edit";

const columns = ['Name', 'Created', 'Actions'];

const row = ['name', 'createdAt'];

const NewMap = (props) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  // const newDataset = 'newDataset';

  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const createQuery = query.get('create');
    setOpen(createQuery === 'map');
  }, [query]);

  const handleClose = () => {
    query.delete('create');
    queryClient.invalidateQueries(['schemes', props.id])
    navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
  };

  return (
    <>
      <MuiButton
        variant='outlined'
        color='primary'
        startIcon={<AddIcon />}
        // onClick={() => (window.location.href = props.urlBase + '&open=' + newDataset)}
        data-cy='upload_dataset'
        onClick={() =>
          navigate(`${location.pathname}?create=map`, {
            replace: true,
          })
        }
      >
        {t('upload_new_dataset')}
      </MuiButton>
      {open && <FormModal action='create' open={open} onClose={handleClose}/>}
    </>
  );
};

const Datasets = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState(new Array(columns.length).fill(''));
  const [dateRange, setDateRange] = React.useState('')
  const [reversedData, setReversedData] = React.useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const Id =
    location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const { data, isLoading } = useSchemes(Id);
  const { hasPermissionFor } = usePermissions();

  const { REACT_APP_VUE_APP_URL } = process.env;
  const { getRefreshToken } = useAuth();
  const scenarioUrlOld = `${REACT_APP_VUE_APP_URL}/${Id}?token=${getRefreshToken()}`;

  const handleDelete = (id, dataset) => {
    navigate(`${location.pathname}?delete=${id}`, { replace: true, state: dataset });
  };
  const queryClient = useQueryClient();

  const handleDownloadJson = async (id, dataset) => {
    try {
      const datasetExportData = await axios.get(
        `/collection/get-dataset-json-config?datasetName=${dataset.name}&user_id=${Id}`
      );

      if (datasetExportData && datasetExportData.status === 200) {
        const response = datasetExportData.data.data;
        const json = JSON.stringify(response);
        const blob = new Blob([json], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = 'dataset configuration ' + dataset.name +'.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error  downloading file:', error);
    }
  }

  const [anchorEditMenu, setAnchorEditMenu] = React.useState(null);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
  const [updateOption, setUpdateOption] = React.useState(null)
  const [dataset, setDataset] = React.useState(null)
  const handleMenuEdit = (id, data, event) => {
    setAnchorEditMenu(event.currentTarget);
    setDataset(data)
  }

  const handleCloseMenu = () => {
    setAnchorEditMenu(null);
  };

  const handleUpdateOption = (option) => {
    setUpdateOption(option)
    setOpenUpdateModal(true)
    setAnchorEditMenu(null)
  }

  const handleUpdateModalClose = () => {
    queryClient.invalidateQueries(['schemes', Id])
    setOpenUpdateModal(false)
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate the current page data based on the pagination settings
  const filteredData = reversedData.filter((item) => {
      let matchesSearchTerm = true;
      let matchesDateRange = true;

      if (dateRange !== '' && item.createdAt !== '') {
        const [date] = item.createdAt.split(' ')
        const [itemDay, itemMonth, itemYear] = date.slice(0, -1).split('/').map(Number);
        const [initDay, initMonth, initYear] = dateRange.initDate.split('/').map(Number);
        const [endDay, endMonth, endYear] = dateRange.endDate.split('/').map(Number);

        const itemDate = new Date(itemYear, itemMonth - 1, itemDay);
        const startDate = new Date(initYear, initMonth - 1, initDay);
        const endDate = new Date(endYear, endMonth - 1, endDay);

        matchesDateRange = itemDate >= startDate && itemDate <= endDate;
      }else if (dateRange !== '' && item.createdAt === ''){
        return false;
      }

      const lowerCaseSearchTerm = searchTerm.map((term) => term.toLowerCase());
      const itemString = `${item.name} ${item.createdAt}`.toLowerCase();
      matchesSearchTerm = lowerCaseSearchTerm.every((term) => itemString.includes(term));
      return matchesSearchTerm && matchesDateRange;
    });

  const offset = page * rowsPerPage;
  const currentPageData = filteredData.slice(offset, offset + rowsPerPage);

  const handlePreviousPage = () =>
    setPage((prevPage) => Math.max(0, prevPage - 1));


  const handleNextPage = () =>
    setPage((prevPage) => Math.min(Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1, prevPage + 1));


  const handleFirstPage = () => setPage(0);

  const handleLastPage = () => {
    const lastPage = Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1);
    setPage(lastPage);
  };

  const handleSearch = (event, index) => {
    const newSearchTerms = [...searchTerm];
    newSearchTerms[index] = event.target.value;
    setSearchTerm(newSearchTerms);
    setPage(0);
  };

  React.useEffect(() => {
    if (data && data?.collections) {
      const reversed = [...data.collections].reverse();
      setReversedData(reversed);
    } else setReversedData([]);
  }, [data]);

  return (
    <SnackbarProvider maxSnack={10}>
      <MuiContainer>
        <TabsCustoms/>
        <MuiGridButtonsContainer >
          <MuiGridTitle item xs={8}>
            <MuiTypographyTitle variant='subtitle1'>
              {t('dataset_list')}
            </MuiTypographyTitle>
          </MuiGridTitle>
          <Grid item xs={4} sx={{ paddingTop:'0px !important'}}>
            {hasPermissionFor('create_datasets') && <NewMap urlBase={scenarioUrlOld} id={Id} />}
            {openUpdateModal &&
            <UpdateModal
              orgId={Id}
              option={updateOption}
              dataset={dataset}
              open={openUpdateModal}
              onClose={() => handleUpdateModalClose()}/>
            }
          </Grid>
        </MuiGridButtonsContainer>
        <TableContainer  sx={{background:'#FAFAFA'}}>
          <DeleteDataset />
          <Table
            handleSearch={handleSearch}
            setDateRange={setDateRange}
            searchTerm={searchTerm}
            data={currentPageData}
            row={row}
            columns={columns}
            onDelete={handleDelete}
            showDelete={hasPermissionFor('delete_datasets')}
            onSettings={(collection, dataset, event) => handleMenuEdit(collection, dataset, event)}
            showSettings={hasPermissionFor('delete_datasets')}//hasPermissionFor('delete_datasets')
            onEdit={(collection, dataset, event) => handleMenuEdit(collection, dataset, event)}
            showEdit={false} //{hasPermissionFor('delete_datasets')}
            isLoading={isLoading}
          />
        </TableContainer>
        <TPagination
          data={filteredData}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleFirstPage={handleFirstPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          handleLastPage={handleLastPage}
        />
        <Menu
          id="edit-button"
          anchorEl={anchorEditMenu}
          keepMounted
          open={Boolean(anchorEditMenu)}
          onClose={handleCloseMenu}>
          <MenuItem onClick={() => handleUpdateOption(OPTION_UPDATE_VARIABLE_DATA)}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <Typography variant="inherit" sx={{
              fontFamily:"Montserrat",
              fontSize:'12px'
            }}>Update Variables Label</Typography>
          </MenuItem>
          <MenuItem onClick={async ()=>handleDownloadJson(Id, dataset)}>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <Typography variant="inherit" sx={{
              fontFamily:"Montserrat",
              fontSize:'12px'
            }}>Dataset Config.</Typography>
          </MenuItem>
        </Menu>
      </MuiContainer>
    </SnackbarProvider>
  );
};

export default Datasets;
