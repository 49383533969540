import {Box, styled} from '@mui/material';

export const MuiSplitModal = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

export const MuiBox = styled(Box)(({ theme }) => ({
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white'
}));
