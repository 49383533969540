import React, {useRef} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  Alert,
  Grid,
  Hidden,
  Portal,
  Snackbar,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {setBottomSheetOpen, setError} from 'store/appSlice';
import Toast from '../common/Toast';
import {setToast} from '../../store/toastSlice';

// Style and asset imports
import {
  MuiBottomSheetContent,
  MuiDrawer,
  MuiExpandLessIcon,
  MuiFab,
  MuiNavDrawer
} from './styles/main';

const Main = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileContainer = useRef(null);
  const desktopContainer = useRef(null);
  const dispatch = useDispatch();
  const error = useSelector((state) => state.app.error);
  const bottomSheetOpen = useSelector((state) => state.app.bottomSheetOpen);
  const navigate = useNavigate();
  const toastComponent = useSelector((state) => state.toast.toast)
  // [hygen] Add useEffect

  React.useEffect(() => {
    navigate('/orgs');
  }, [navigate]);

  const handleClose = () => dispatch(setError(null));

  const handleWidgetsDrawerToggle = () => dispatch(setBottomSheetOpen(!bottomSheetOpen));
  const handleCloseToast = () => dispatch(setToast({
    message: '',
    severity: 'success',
    open: false,
  }));

  React.useEffect(() => {
    console.log(toastComponent)
  }, [toastComponent])

  return (
    <Grid container direction='row' alignItems='stretch' item xs>
      <MuiNavDrawer>
        <Portal container={isMobile ? mobileContainer.current : desktopContainer.current}>
          <Outlet />
        </Portal>
        <Hidden xsDown implementation='css'>
          <MuiDrawer
            variant='permanent'
            PaperProps={{
              elevation: 8,
            }}
            open
          >
            <Toolbar variant='dense' />
            <Grid container item xs ref={desktopContainer} />
          </MuiDrawer>
        </Hidden>
        <Hidden smUp implementation='css'>
          <SwipeableDrawer
            variant='persistent'
            anchor='bottom'
            open={bottomSheetOpen}
            onOpen={handleWidgetsDrawerToggle}
            onClose={handleWidgetsDrawerToggle}
          >
            <MuiBottomSheetContent ref={mobileContainer}/>
          </SwipeableDrawer>
          <MuiFab
            variant='extended'
            size='small'
            color='inherit'
            aria-label={bottomSheetOpen ? 'Hide' : 'Show'}
            onClick={handleWidgetsDrawerToggle}
          >
            <MuiExpandLessIcon/>
            {bottomSheetOpen ? 'Hide' : 'Show'}
          </MuiFab>
        </Hidden>
      </MuiNavDrawer>

      <Snackbar open={!!error} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity='error'>{error}</Alert>
      </Snackbar>
      <Toast
        message={toastComponent.message}
        handleClose={handleCloseToast}
        severity={toastComponent.severity}
        horizontal='left'
        vertical='bottom'
        open={toastComponent.open}
      />
    </Grid>
  );
};

export default Main;
