import {Button, colors, Grid, styled, Typography} from '@mui/material';

export const MuiGridButtonsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(2),
  justifyContent: 'flex-end',
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  color: colors.blue[50],
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
}));

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  paddingLeft:'0px !important',
  paddingTop:'0px !important'
}));

export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize:21,
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:500
}));
