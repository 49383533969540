import {useMutation, useQueryClient} from 'react-query';

import {status200} from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const newTranslation = async (data) =>
  axios.post(apiPaths.translations, data, status200).then((resp) => resp.data);

const useCreateTranslation = () => {
  const queryClient = useQueryClient();
  return useMutation((translation) => newTranslation(translation), {
    onSuccess: () => {
      queryClient.resetQueries('translations');
    },
    onError: (error) => {
      console.error(error);
      return error;
    },
    onSettled: () => {
      queryClient.invalidateQueries('translations');
    },
  });
};

export default useCreateTranslation;
